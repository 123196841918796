import {useState, useEffect } from 'react';

const useClearLocation = (interval = 6 * 60 * 60 * 1000) => {
	const [isLocationCleared, setIsLocationCleared] = useState(false);

	const isBrowser = typeof window !== `undefined`
	const clearLocalStorage = () => {
		if (isBrowser) {
			window.localStorage.removeItem('currentlocation')
			setIsLocationCleared(true);
			console.log('currentlocation cleared!');
		}
	}
	useEffect(() => {
		const now = new Date().getTime();
		const data = JSON.parse(localStorage.getItem('currentlocation'));

		if (data?.timestamp) {
			const timeSinceLastClear = now - parseInt(data?.timestamp, 10);
			if (timeSinceLastClear < interval) {
				// If last clear was less than 6 hours ago, do nothing
				return;
			}else{
				clearLocalStorage()
			}
		} 

	}, []);
	return isLocationCleared;
};


export default useClearLocation;

