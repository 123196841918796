import React, { useContext, useEffect, useRef } from "react"
import { Card } from "react-bootstrap"
import Styles from "./styles/guideList.module.scss"
import GuideMobDetail from "./GuideMobDetail";
import MapContext from "../../../widgets/state/context/MapContext";

const GuideCardMob = ({ handleCard, fishspecies, getGuide, item, index, customStyles = {}, showClose, handleClear, from }) => {
	const context = useContext(MapContext)
	const node = useRef()

	function callback(entries) {
		for (const entry of entries) {
			// console.log(context.tapCard, "kk")
			if (entry.isIntersecting && !context.tapCard) {
				context.setHoverCardId(entry.target.id)
				context.setMarkerOnHover(entry.target.id)
			}
		}
	}
	useEffect(() => {
		const myIntersectionObserver = new IntersectionObserver(callback, {
			root: document.querySelector('GuidesNearYou'),
			threshold: 0.5
		});
		if (node) {
			myIntersectionObserver.observe(node.current)
		}
	}, [context.tapCard])
	const id = item.guideguid || item.guid
	return (
		<div key={index + 1} className={customStyles.container}>
			<Card
				ref={node}
				id={id}
				key={index + 1}
				className={context.hoverCardId === id ? Styles.cardcontainerMob : context.markerOnHover === id ? Styles.cardcontainerMob : Styles.cardcontainer1Mob}
				onMouseOver={() => handleCard(id)}
				onFocus={() => { return null }}
			>
				<GuideMobDetail getGuide={getGuide} item={item} showClose={showClose} handleClear={handleClear} from={from} />
			</Card >
		</div >
	)
}

export default GuideCardMob

