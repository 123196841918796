import { useEffect, useContext } from 'react'
import Axios from "axios"
import MapContext from '../../../../widgets/state/context/MapContext';
import { getMyAddress, setMyAddress } from '../../../../widgets/common/storage';

const InitLocation = (props) => {
	const MapInfo = useContext(MapContext);

	const handleSetmyAddress = (city, state, address, lat, lng, country) => {
		setMyAddress({
			"lat": lat,
			"lng": lng,
			"city": city,
			"state": state,
			"address": address,
			"country": ["US", "CA"].includes(country) ? country : "US",
			"timestamp": new Date().getTime().toString(),
			"date": new Date().toString()
		})
	}

	useEffect(() => {
		MapInfo.setSearchedCity('')
		MapInfo.setToFitBoundCity("")
		MapInfo.setLocationDisable(true)
		// MapInfo.setLoadingMask(true)
		MapInfo.setGuidesAPICalled(true)
		props.setMapLoading(true)

		if (getMyAddress()?.address) {
			const { lat, lng, address, city, state } = getMyAddress()
			pickfromStorage(lat, lng, address, city, state)
		} else {
			Axios.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${MAPS_KEY}`)
				.then(response => {
					const currentPosition = {
						lat: response.data?.location.lat,
						lng: response.data?.location.lng,
					}
					GetLocationData(currentPosition)
				})
				.catch(err => {
					console.log(err)
					DefaultLocation()
				})
		}
	}, [])


	const pickfromStorage = async (lat, lng, address, city, state) => {
		// MapInfo.setLoadingMask(true)
		props.setMapLoading(true)
		const display = address;
		const currentPosition = {
			"lat": Number(lat),
			"lng": Number(lng),
		}
		const data = {
			"latitude": Number(lat),
			"longitude": Number(lng),
			"page": 1,
			"address": display
		}
		const s = { ...props.searchQuery, ...data }
		!window.location.pathname.includes('become-a-guide') && props?.setSearchQuery && await props?.setSearchQuery(s)
		MapInfo.setMapDrag(false)
		MapInfo.setLocationDisable(false)
		MapInfo.setSearchedCity(city)
		MapInfo.setSearchedState(state)
	 MapInfo.setLocation(currentPosition)
		MapInfo.setSearchedValue(display)
		// MapInfo.setLoadingMask(false)
		props.setMapLoading(false)
	}
	const DefaultLocation = async () => {
		const lat = 37.09024;
		const lng = -95.712891;
		const address = "United States";
		const city = null;
		const state = null;
		handleSetmyAddress(null, null, "United States", Number(lat), Number(lng), "US")
		await pickfromStorage(lat, lng, address, city, state)
	}
	const GetLocationData = (currentPosition) => {
		Axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${Number(currentPosition.lat)}&longitude=${Number(currentPosition.lng)}&localityLanguage=en`)
			.then(async (response) => {
				const lat = Number(currentPosition.lat);
				const lng = Number(currentPosition.lng);
				const city = response.data.city != "" ? `${response.data.city}` :
					`${response.data.locality}`;
				const country = response?.data?.countryCode
				const address = `${city}, ${response.data.principalSubdivision}`;
				const state = response.data.principalSubdivision;
				handleSetmyAddress(city, state, address, lat, lng, country)
				await pickfromStorage(lat, lng, address, city, state)
			})
	}
	return null
}

export default InitLocation
