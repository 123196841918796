import React from "react"
import { Card, Row, Col } from "react-bootstrap"
import Styles from "./styles/guideList.module.scss"

export const GuideCardShimmerNew = ({ index, customStyles = {} }) => {
	return (
		<div className={`${Styles.GuideCardNew} ${Styles.guidecard} ${Styles.GuideCardVertical}`} key={index}>
			<div className={Styles.GuideSubCard}>
				<div className={`${Styles.GuideImgs} loading`} style={{ padding: "0px", minWidth: "unset", width: "32%" }}>
					{/* <div className={`${Styles.GuideImgSizeDesktop} `} > */}
					{/* <div className={`guideImageSlider loading`} >

					</div> */}
					{/* </div> */}
				</div>
				<Row className={Styles.GuideRow} style={{ height: "auto", width: "68%", marginBottom: "10px", marginTop: "12px" }}>
					<Col xs={12} sm={12} lg={12} className={Styles.GuideCol1} style={{ display: "flex", padding: "0px 0px", height: "100%" }}>
						<div style={{ width: "100%" }} >
							<div style={{ display: 'flex', margin: "2px 0px" }}>
								{/* <div className={`${Styles.DreviewCont} loading`}>
								<p></p>
							</div> */}
								<button className={`loading  `} style={{ width: "38%", height: "28.85px", border: "none" }}> </button>
								<div className={`${Styles.loc} `} style={{ margin: "0px " }}>
									<div style={{ width: "130px" }} >
										<p className={`${Styles.location} loading`}>
										</p>
										<p className={`${Styles.location} loading`} style={{ marginTop: 5 }}>
										</p>
									</div>
								</div>
							</div>


							<Card.Title style={{ flexWrap: 'wrap', display: 'flex', }} className={`${Styles.name} loading`}>
							</Card.Title>

							<p className={`${Styles.recs} ${Styles.Boatlimit} loading`} style={{ margin: "2px 0px" }}>
							</p>
							<p className={`${Styles.recs} ${Styles.Boatlimit} loading`} style={{ margin: "10px 0px" }}>
							</p>
							<div style={{ position: "absolute", bottom: '0px', right: "0px", width: "50%" }} >
								<div className={`${Styles.priceCont}`} >
									<p></p>
									{/* <p className={`${Styles.Avial} loading`} style={{ width: "35%", margin: "6px 0px 0px" }}></p> */}
									<p className={`${Styles.Avial} loading`} style={{ fontWeight: 500, color: "#717171", margin: "6px 0px 0px", width: "50%" }}></p>
								</div>
								<div className={`${Styles.priceCont}`} >
									<p></p>
									{/* <p className={`${Styles.Avial} loading`} style={{ width: "35%", margin: "6px 0px 0px" }}></p> */}
									<p className={`${Styles.Avial} loading`} style={{ fontWeight: 500, color: "#717171", margin: "6px 0px 0px", width: "50%" }}></p>
								</div>
							</div>

						</div>
					</Col>

				</Row>
			</div>
		</div >
	)
}

export default GuideCardShimmerNew
