import React, { useState, useEffect, useRef } from 'react';
import Chart from "react-google-charts";
import { BarChart, GridlineSeries, Gridline, Gradient, GradientStop, LinearYAxis, BarSeries, Bar, LinearYAxisTickSeries,LinearYAxisTickLabel } from '@guidesly/reaviz-v2';
import { Typography } from '@mui/material';
import { get } from '../../widgets/utils/axiosHelper'
import moment from 'moment'
import { options, radiobtns, data, getGradiantChartColor } from './constants';
import Progressbar from './Progressbar';
import useStyles from './styles'
import './styles.scss'

const FishSpecies = (props) => {
	const classes = useStyles()
	const node = useRef();
	const node1 = useRef();
	const node2 = useRef();
	const [fishCalendarData, setFishCalendarData] = useState([]);
	const [month, setMonth] = useState(moment().format('MMMM'));
	const [signup_choice, setSignup_choice] = useState(1);
	const [fishDropdown, setFishDropdown] = useState(false);
	const [monthsDropdown, setMonthsDropdown] = useState(false);
	const [areaDropdown, setAreaDropdown] = useState(false);
	const [area, setArea] = useState('No calendar created yet')
	const [categoryData, setCategoryData] = useState()
	const [showGraph, setShowGraph] = useState(true);
	const [noGraph, setNoGraph] = useState(false);
	const [guideTown, setGuideTown] = useState([])
	const [selectedAreaList, setSelectedAreaList] = useState([])
	const [newBarDataList, setNewBarDataList] = useState([])
	const [fish, setFish] = useState();
	const [fishData, setFishData] = useState("Select Fish Species");
	const [fishImage, setFishImage] = useState();
	const [loadCount, setLoadCount]= useState(0)
	const isNarrow = /Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) || window.innerWidth <= 800 || ((screen || {}).width || 0) <= 800

	useEffect(() => {
		const url = `guide/fishspecies_calendar?id=${props.id}`;
		get(url)
			.then((res = {}) => {
				setFishCalendarData(res.data)
				if (res.data && res.data.length > 0) {
					var firstArea = [];
					var town = []
					firstArea.push(res.data[0])
					setSelectedAreaList(firstArea)
					town = [...firstArea, firstArea[0].area]
					setArea(firstArea[0].area)
					setGuideTown(town)
				}
			}).catch((e) => {
				console.log('API Error: reviews', e)
			});
	}, []);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleClickOutside = e => {
		const data = e.target

		if (node.current && node.current.contains(data)) {
			return;
		}
		setAreaDropdown(false);

		if (node1.current && node1.current.contains(data)) {
			return;
		}
		setMonthsDropdown(false);

		if (node2.current && node2.current.contains(data)) {
			return;
		}
		setFishDropdown(false);

	};

	const displayArea = () => {
		setAreaDropdown(!areaDropdown)
	}
	const showMonthDropdown = () => {
		setMonthsDropdown(!monthsDropdown)
	}
	const showDropdown = () => {
		setFishDropdown(!fishDropdown)
	}

	const setRadioButton = (id) => {
		setSignup_choice(id)
		if (id === 1) {
			setShowGraph(true);
		} else {
			if (fish) {
				setShowGraph(false);
			} else {
				setShowGraph(true);
			}
		}
	}

	const guideAreas = []
	fishCalendarData.length > 0 && fishCalendarData.map(item => {
		return guideAreas.push({
			title: item.area,
			checked: false
		})
	})

	const handleAreaCheck = (e, title) => {
		let prevGuideTown = guideTown;
		let prev = selectedAreaList;
		let barList = newBarDataList;
		let index;
		if (e.target.checked) {
			prevGuideTown = [...guideTown, title]
			index = fishCalendarData.findIndex((obj => obj.area === title));
			prev = [...selectedAreaList, fishCalendarData[index]]
		} else {
			prevGuideTown = guideTown.filter(e => e !== title)
			prev = selectedAreaList.filter(obj => obj.area !== title)
			barList = newBarDataList.filter(obj => obj.title !== title)
		}
		setGuideTown(() => prevGuideTown)
		setSelectedAreaList(() => prev)
		setNewBarDataList(() => barList)
	}

	const removeArea = (title) => {
		let filteredAry = guideTown.filter(e => e !== title)
		let newList = selectedAreaList.filter(obj => obj.area !== title)
		let barList = newBarDataList.filter(obj => obj.title !== title)
		setGuideTown(() => filteredAry)
		setSelectedAreaList(() => newList)
		setNewBarDataList(() => barList)
	}

	// handle on change of month
	const monthChangeHandler = (e) => {
		setMonth(e.target.innerText);
		setMonthsDropdown(false)
		setShowGraph(true);
	}

	// handle on change of fish species
	const generateOptions = () => {
		var fishList = selectedAreaList.map(items => items.species_calendar)
		var d = fishList.reduce((acc, curr) => [...acc, ...curr], []).map(data => data)
		const filteredObjects = [...new Map(d.map(item => [item.species_id, item])).values()]
		return filteredObjects
	};

	const getMonth = (id) => {
		var month, i;
		for (i = 0; i < options.length; i++) {
			if (options[i].id === id) {
				month = options[i].label.toLowerCase().substring(0, 3)
			}
		}
		return month;
	}

	// added an useEffect. so whenever species radio btn is selected, by default the 1st fish of species_calendar is selected
	useEffect(() => {
		if (selectedAreaList.length > 0 && signup_choice === 2) {
			//const { species_calendar } = selectedAreaList[0]
			const { species_calendar } = selectedAreaList.find(item => item.area === area)
			if (species_calendar.length > 0) {
				handleChangeFish(species_calendar[0].title) //passing fish title
			}
		}
	}, [signup_choice])

	const handleChangeFish = (Sfish, data) => {
		var newList = data ? data : selectedAreaList
		setShowGraph(false);
		options.map(function (obj) {
			obj.barGraphVal = 0;
		});

		const emptyArray = []
		newList.map(items => {
			return items.species_calendar.map(item => {
				if (item.species_id) {
					setFish(Sfish);
					setFishData(Sfish);
					if (Sfish === item.title) {
						if (item.availability_scale) {
							var newBarData = [{
								title: items.area,
								month: item.availability_scale
							}]
							emptyArray.push(newBarData)
						}
						setFishImage(item.url);
						setFishDropdown(false)
					}
				}
			})
		});

		var allList = emptyArray.reduce((acc, curr) => [...acc, ...curr], [])
		allList = allList.map(item => {
			const barData = [
				{ key: 'JAN', data: 0 , colorStart : '#fff', colorStop : '#fff'},
				{ key: 'FEB', data: 0 , colorStart : '#fff', colorStop : '#fff'},
				{ key: 'MAR', data: 0 , colorStart : '#fff', colorStop : '#fff'},
				{ key: 'APR', data: 0 , colorStart : '#fff', colorStop : '#fff'},
				{ key: 'MAY', data: 0 , colorStart : '#fff', colorStop : '#fff'},
				{ key: 'JUN', data: 0 , colorStart : '#fff', colorStop : '#fff'},
				{ key: 'JUL', data: 0 , colorStart : '#fff', colorStop : '#fff'},
				{ key: 'AUG', data: 0 , colorStart : '#fff', colorStop : '#fff'},
				{ key: 'SEP', data: 0 , colorStart : '#fff', colorStop : '#fff'},
				{ key: 'OCT', data: 0 , colorStart : '#fff', colorStop : '#fff'},
				{ key: 'NOV', data: 0 , colorStart : '#fff', colorStop : '#fff'},
				{ key: 'DEC', data: 0 , colorStart : '#fff', colorStop : '#fff'},
				{ key: '', data: 4 , colorStart : '#fff', colorStop : '#fff'}
			];

			return {
				data: barData.map((bar, i) => {
					const month = bar.key.toLowerCase();
					const found = item.month.find(
						m => getMonth(m.month) === month
					);
					if (found) {
						bar.data = found.availability;
						if(found.availability === 6) {
							bar.data = 4;
						} else if(found.availability == 5){
							bar.data = 3;
						}
						const color = getGradiantChartColor(
							found.availability
						);
						bar.colorStart = color[0];
						bar.colorStop = color[1];
					}
					// else {
					// 	bar.data = 0;
					// }
					return bar;
				}),
				title: item.title
			}
		})
		setCategoryData(allList[0].data)
		setNewBarDataList(allList)
	};

	const onAreaChange = (e) => {
		setArea(e.target.innerText)
		var fishData = fishCalendarData.find(item => item.area.trim().toLowerCase() === e.target.innerText.toLowerCase())
		var { species_calendar } = fishData
		var sortedData = species_calendar.sort((a, b) => a.title.localeCompare(b.title))
		setSelectedAreaList([fishData])
		if (!showGraph) {
			handleChangeFish(fishData.species_calendar[0].title, [fishData])
		}
		setAreaDropdown(false)
	}

	return (
		<div className={classes.root}>
			{/* select the area from the dropdown to display the calender*/}
			<div className={'fishing-gudideAreas'} >
				<div className={'fishingAreaTitle'}>
					<Typography>Fish Season Calendar:</Typography>
				</div>
				<div ref={node} className={'guide-fishingArea'}>
					<Typography>
						<button
							onClick={displayArea}
							className={`${fishCalendarData.length > 1 ? 'dropdownIcon' : 'hideDropDown'}`}
							>{area}</button>
					</Typography>
					{
						areaDropdown &&
						<Typography>
							<ul className={'area-dropdownList'}>
								{
									(guideAreas || []).length > 0 && guideAreas.map((area, i) => {
										return (
											<li key={i}>
												<Typography>
													<label className={'guidearea-customCheckbox'} onClick={e => onAreaChange(e)} >{area.title}</label>
												</Typography>
											</li>
										)
									})
								}
							</ul>
						</Typography>
					}
					{/* {
                        areaDropdown &&
                        <Typography>
                            <ul className={'area-dropdownList'}>
                            {
                                (guideAreas || []).length > 0 && guideAreas.map((area, i) => {
                                    return (
                                        <li key={i}>
                                            <Typography>
                                                <label className={'guidearea-customCheckbox'}>
                                                    <input type="checkbox" checked={guideTown.includes(area.title)} onChange={(e) => handleAreaCheck(e, area.title)} value={area.title} />{area.title}
                                                    <span className={'checkmark'}></span>
                                                </label>
                                            </Typography>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        </Typography>
                    } */}
					{/* <div>
                        {
                            (guideAreas || []).length > 0 && guideAreas.map(area => {
                                return guideTown.includes(area.title) && <button className={'areaBtns'}>{area.title}<span onClick={() => removeArea(area.title)} className={'areaclose'}></span></button>
                            })
                        }
                    </div> */}
				</div>
			</div>
			{/* select Month specific or fish species specific data*/}
			{ fishCalendarData.length > 0 &&
			<div className={'dropdowns'}>
				<Typography variant="caption">Select by:</Typography>
				{radiobtns.map(radio => {
					return (
						<span className={'selection-radioBtns'}>
							<label key={radio.id}>
								<input
									type="radio"
									name="mode"
									value={radio.id}
									checked={signup_choice === radio.id && true}
									onChange={() => setRadioButton(radio.id)}
								/>
								<Typography variant="caption">{radio.label}</Typography>
							</label>
						</span>
					);
				})}
				{signup_choice === 1 ? (
					<>
						<div ref={node1} className={'MonthDropdownHolder'}>
							<Typography>
								<input onClick={showMonthDropdown} className={`${'dropdownBtn monthDropDown'}`} value={month}
									style={{
										backgroundImage: `url(https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/dropdown.svg)`,
										backgroundRepeat: "no-repeat",
										backgroundSize: "9%",
										backgroundPositionX: "96%",
										backgroundPositionY: "17px",
										backgroundColor: 'white',
										color: 'black',
										border: '1px solid #ccc',
										borderRadius: 5
									}}
								/>
							</Typography>
							<ul className={monthsDropdown ? `${'CustomMonthDropdown animateMonth'}` : `${'CustomMonthDropdown'}`}>
								{
									options.map(option => {
										return <Typography><li value={option.id} onClick={e => monthChangeHandler(e)}>{option.label}</li></Typography>
									})
								}
							</ul>
						</div>
					</>
				) : (
					<div ref={node2} className={'CustomDropdownHolder'}>
						<input onClick={showDropdown} className={'dropdownBtn'} type="Button" value={fishData}
							style={{
								backgroundImage: `url(https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/dropdown.svg)`,
								backgroundRepeat: "no-repeat",
								backgroundSize: "5%",
								backgroundPositionX: "96%",
								backgroundPositionY: "17px",
								backgroundColor: 'white',
								color: 'black',
								border: '1px solid #ccc',
								borderRadius: 5
							}}
						/>
						<ul className={fishDropdown ? `${'CustomDropdown animate'}` : `${'CustomDropdown'}`} >
							{
								(generateOptions() || []).length ?
									generateOptions().map((option) => (
										option &&
										<Typography component="li" variant="body2" className={classes.dpStyles}
											style={{
												backgroundImage: `url('${option.url}')`,
												backgroundRepeat: "no-repeat",
												backgroundSize: "30%",
												backgroundPositionX: "90%",
												backgroundPositionY: "0px"
											}} value={option.title} onClick={e => handleChangeFish(e.target.innerText)} >{option.title}</Typography>
									)) : <Typography variant="caption">No Fish Species Available</Typography>
							}
						</ul>
					</div>
				)}
				{/* show progressbar or chart */}
				<div>
					{showGraph ?
						<>
							{
								selectedAreaList.length > 0 ? selectedAreaList.map((item = {}) => {
									return (
										<>
											<Typography className={classes.fishArea}>{item.area} - <i>Top Species in {month}</i></Typography>
											<div className={'horizontalChart'}>
												<Progressbar
													value={item.species_calendar}
													month={month}
												/>
											</div>
										</>
									)
								}) : <span></span>
							}
						</> :
						<>
							<div className={classes.chartTitle} >
								{
									newBarDataList.length > 0 &&
									<>
										<img className={classes.displayFishImage} src={`${fishImage}`}></img>
										<Typography className={classes.chartTitleContent}>{fish} Seasonality</Typography>
									</>
								}
								{
									noGraph &&
									<p className={'noFishSpecies'}> is not available</p>
								}
							</div>
							{
								newBarDataList.length > 0 && newBarDataList.map(item => (
									<>
											<Typography className={classes.ChartTitle}>{item.title}</Typography>
											<div className={'chartContainer'}>
												<ul className={'Barlabels'}>
													<li>Excellent</li>
													<li>Good</li>
													<li>Average</li>
													<li>Poor</li>
													<li>Out of Season</li>
												</ul>
												<div className={'Cover'}></div>
												<BarChart
													height={300}
													data={categoryData}
													gridlines={<GridlineSeries line={<Gridline direction="y" />} />}
													center={true}
													animated={true}
													yAxis={<LinearYAxis type="value"
														tickSeries={<LinearYAxisTickSeries
														tickSize='75'
														label={<LinearYAxisTickLabel fill={'#fff'}  padding={5} /> } />} />}
													series={
														<BarSeries
														bar={
														<Bar width={ isNarrow ? 20 : 50}
														/>}
														tooltip={null}
														/>
													}
													gradient={
														<Gradient
														stops={[
															<GradientStop
															offset="100%"
															stopOpacity={1}
															key="stop"
															color={categoryData.colorStop}
															/>,
															<GradientStop
															offset="0%"
															startOpacity={1}
															key="start"
															color={categoryData.colorStart}
															/>
														]}
														/>
													}
												/>
										</div>
									</>
								))
							}
						</>
					}
				</div>
			</div>
			}
		</div>
	);
}

export default FishSpecies;
