import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    guests: {
        paddingLeft: '10px',
        paddingTop: '40px',
        paddingBottom: '40px',
        '& img': {
            width: '35px',
            height: '35px',
        },
        '& span': {
            fontSize: '20px',
        },
        '& div': {
            fontSize: '20px !important',
        },
    },
    guests__container: {
        display: 'flex',
        width: '100%',
        paddingBottom: '20px',
        borderBottom: '1px solid #ccc',
        '&:last-child': {
            borderBottom: 'none',
            paddingTop: '20px',
        },
    },
    guests__text: {
        width: '60%',
        '& > div:first-child': {
            fontWeight: 'bold',
        },
        '& > div:last-child': {
            fontSize: '14px',
        },
    },
    guests__button: {
        display: 'flex',
        width: '40%',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    guests__button__icon: {
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
        color: 'grey',
        borderRadius: '50%',
        background: 'white',
        border: '2px solid #c7cdbe',
        height: '22px',
        width: '22px',
        '& > i': {
            height: '22px',
            width: '22px',
            display: 'grid !important',
            justifyContent: 'center',
            alignContent: 'center',
            '& > svg': {
                height: '12px',
                width: '12px',
            },
        },
        '&:disabled': {
            cursor: 'default',
            opacity: 0.5,
        },
    },
    heroCalendar1: {
        height: '485px',
        backgroundColor: '#fff',
        borderRadius: '20px',
        padding: '11px 24px',
        '& img': {
            cursor: 'pointer',
        },
    },
    slideIn: {
        animation: '$slideInRight 0.5s forwards',
    },
    '@keyframes slideInRight': {
        from: {
            transform: 'translateX(100%)',
            opacity: 1,
        },
        to: {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    slideOut: {
        animation: '$slideInLeft 0.5s forwards',
    },
    '@keyframes slideInLeft': {
        from: {
            transform: 'translateX(0)',
            opacity: 1,
        },
        to: {
            transform: 'translateX(120%)',
            opacity: 1,
        },
    },
    saveBtn: {
        height: '60px',
        boxSizing: 'border-box',
        color: '#ffffff',
        fontSize: '20px',
        marginTop: '15px',
        textAlign: 'center',
        lineHeight: 'normal',
        width: '100%',
        borderRadius: '5px',
    },
    margintop: {
        marginTop: '60px',
    },
    title:{
        width:"100%",
        color:"#fff",
        fontSize: '20px',
        fontWeight: 'bold',
        display:"block",
        borderBottom: "1px solid white",
    },
    heroCalendar: {
        height: '485px',
        backgroundColor: 'rgba(0, 0, 0, 0.66)',
        borderRadius: '20px',
        padding: '22px 32px 0px',
        '& input[type=text]': {
            '&::placeholder': {
                fontSize: '16px !important',
                fontFamily: '"Roboto", sans-serif !important',
                fontWeight: '400 !important',
                lineHeight: '29px !important',
                color: 'rgb(151,151,151) !important',
            },
        },
        '@media only screen and (min-width: 768px) and (max-width:960px)': {
            padding: '30px 32px',
        },
        '#react-autowhatever-1': {
            position: 'absolute !important',
            top: '50px !important',
            width: '100% !important',
            height: '300px !important',
            backgroundColor: '#fff !important',
            fontSize: '16px !important',
            zIndex: 2,
            maxHeight: '300px !important',
        },
        '& p': {
           
            color: '#ffffff',
            textAlign: 'left',
            lineHeight: 'normal',
            fontSize: '16px',
            fontWeight: '700 !important',
            marginBottom: '10px !important',
            marginTop: "25px",
        },
        '& button': {
            height: '60px',
            boxSizing: 'border-box',
            color: '#ffffff',
            fontSize: '20px',
            marginTop: '15px',
            textAlign: 'center',
            lineHeight: 'normal',
            width: '100%',
            borderRadius: '5px',
            cursor:"pointer",
        },
        // '@media only screen and (max-width: 768px)': {
        //     backgroundColor: 'transparent',
        //     padding: '0px !important',
        //     height: '400px !important',
        //     '& button': {
        //         backgroundColor: '#ff6600',
        //         border: '1px solid #ff6600',
        //     },
        // },
    },
    newDiv: {
        maxHeight: '50px',
        padding: '10px 15px !important',
        borderRadius: '5px',
        border: '1px solid #d4d4d4',
        backgroundColor: '#ffffff',
        marginBottom: '20px',
        fontSize: '16px',
        color: '#979797',
        position: 'relative',
        cursor: 'pointer',
        display: "flex",
        alignItems: "center",
        fontWeight:400,
        '& img': {
            marginRight: '8px',
            marginBottom: '4px',
        },
        '& b': {
            width: '26px !important',
            height: '26px !important',
            backgroundColor: '#979797',
            color: '#fff',
            borderRadius: '50%',
            right: '15px !important',
            position: 'absolute',
            bottom: '25%',
            cursor: 'pointer',
             display: "flex",
            alignItems: "center",
            justifyContent:"center",
        },
    },
}));
