import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    padding: "5 !important",
    marginBottom: 10,
  },
  subtitle1: {
    fontSize: 14,
    color: 'white',
    marginRight: 10,
    marginTop: 5,
    marginBottom: 5,
  },
  subtitle2: {
    fontSize: 16,
    color: 'white',
    marginRight: 10,
    marginTop: 5,
  },
  center: {
    alignItems: 'center',
    display: 'flex',
  },
  white: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    color: 'black !important',
    borderColor: '#497049',
    height: 35,
    border: '1px solid #497049 !important'
  },
  color: {
    color: `rgb(26, 4, 236) !important`,
  },
  datepicker: {
	display: 'inline-block',
    padding: '0px 10px !important',
    border: '1px solid rgb(73, 112, 73) !important',
    width: 160,
    height: '30px !important',
    marginRight: '2px',
    background: 'url("https://da9mvpu5fnhic.process.env.cloudfront.net/web/static/images/Calendarhomepage.png") no-repeat scroll 7px 8px',
    backgroundSize: '18px',
    backgroundOrigin: 'content-box',
    backgroundPosition: 'right center',
    fontSize: '12px !important',
    '&:hover': {
        borderColor: '#cacaca',
    },
    backgroundColor: 'white',
    borderRadius: 5
  },
  selectedGuest: {
    border: '1px solid #497049',
    backgroundColor: '#c7d9be!important',
    height: 30,
  },
  customGuest: {
    width: '80px !important',
    height: 30,
    outline: 'none',
    padding: '0 !important',
    '&:focus': {
      outline: 'none',
    },
    '&:-webkit-inner-spin-button': {
      opacity: 1,
    },
    '&:-webkit-outer-spin-button': {
      opacity: 1,
    },
    marginBottom: '0 !important',
    border: 'none !important',
  },
  selectedCustomGuest: {
    width: 50,
    height: 30,
    margin: 5,
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&:-webkit-inner-spin-button': {
      opacity: 1,
    },
    '&:-webkit-outer-spin-button': {
      opacity: 1,
    },
  },
  customText: {
    margin: 5,
    fontSize: 'inherit',        
  },
  guest: {
      height: 30
  },
  select: {
      minWidth: 150,
  },
  checkbox: {
      color: '#1a691a !important'
  },
  filter: {
    padding: 5,
  },
  selected: {
    minWidth: 170
  }
}));
