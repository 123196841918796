import React from "react"
import  styles from "../styles/Styles.module.scss"
import { mapPin } from 'react-icons-kit/feather/mapPin'
import Icon from "react-icons-kit"



const LocationSearch = ({ destination, suggestions, setDestination, setSelection , }) => {
	const locationInfo = [
		{
			"id": 1, "title": "West Coast", "params": {
				"latitude": 54.87326328888283, "longitude": -142.09747418509517,
				"ne_lat": 69.700852224471, "ne_lng": -110.58868512259518, "sw_lat": 31.53613733835241, "sw_lng": -173.60626324759517, "zoom": 4, "mapDrag": false
			}
		},
		{
			"id": 2, "title": "North East", "params": {
				"latitude": 42.73401890443189, "longitude": -75.85349901679635,
				"ne_lat": 52.1361158272195, "ne_lng": -65.39451464179635, "sw_lat": 31.657025596003002, "sw_lng": -86.31248339179635, "zoom": 5, "mapDrag": false
			}
		},
		{
			"id": 3, "title": "Mid West", "params": {
				"latitude": 40.86720785470801, "longitude": -93.54136981023797,
				"ne_lat": 50.57224596704827, "ne_lng": -83.08238543523797, "sw_lat": 29.50104818047118, "sw_lng": -104.00035418523797, "zoom": 5, "mapDrag": false
			}
		},
		{
			"id": 4, "title": "South East", "params": {
				"latitude": 33.87944320601195, "longitude": -83.20925775084376,
				"ne_lat": 44.27973638936988, "ne_lng": -73.52439745429635, "sw_lat": 21.088494548158167, "sw_lng": -94.44236620429635, "zoom": 5, "mapDrag": false
			}
		},
		{
			"id": 5, "title": "Rocky Mountains", "params": {
				"latitude": 41.40491154515985, "longitude": -110.58901975014179,
				"ne_lat": 50.03180782006566, "ne_lng": -97.22964475014179, "sw_lat": 31.460621762926408, "sw_lng": -123.94839475014179, "zoom": 5, "mapDrag": false
			}
		},
		{
			"id": 6, "title": "Gulf Coast", "params": {
				"latitude": 31.147914016240314, "longitude": -96.13157764999998,
				"ne_lat": 41.129820223039914, "ne_lng": -82.77220264999998, "sw_lat": 19.994995147714878, "sw_lng": -109.49095264999998, "zoom": 5, "mapDrag": false
			}
		},
	]
	// const getUrl = (item) => {
	// 	switch (item.title) {
	// 		case "West Coast":
	// 			return <img
	// 				alt={item.title}
	// 				src={"https://dlsmyzcs6vrg4.cloudfront.net/web/static/images/searchfilter/map/westcoast1.png"}
	// 				aspectRatio="16/9"
	// 			/>;
	// 		case "North East":
	// 			return <img
	// 				alt={item.title}
	// 				src={"https://dlsmyzcs6vrg4.cloudfront.net/web/static/images/searchfilter/map/northeast.webp"}
	// 				aspectRatio="16/9"
	// 			/>;
	// 		case "Mid West":
	// 			return <img
	// 				alt={item.title}
	// 				src={"https://dlsmyzcs6vrg4.cloudfront.net/web/static/images/searchfilter/map/midwest.webp"}
	// 				aspectRatio="16/9"
	// 			/>;
	// 		case "South East":
	// 			return <img
	// 				alt={item.title}
	// 				src={"https://dlsmyzcs6vrg4.cloudfront.net/web/static/images/searchfilter/map/southeast.webp"}
	// 				aspectRatio="16/9"
	// 			/>;
	// 		case "Rocky Mountains":
	// 			return <img
	// 				alt={item.title}
	// 				src={"https://dlsmyzcs6vrg4.cloudfront.net/web/static/images/searchfilter/map/rockymountain.png"}
	// 				aspectRatio="16/9"
	// 			/>;
	// 		case "Gulf Coast":
	// 			return <img
	// 				alt={item.title}
	// 				src={"https://dlsmyzcs6vrg4.cloudfront.net/web/static/images/searchfilter/map/gulfcoast.png"}
	// 				aspectRatio="16/9"
	// 			/>;
	// 		default:
	// 			return;
	// 	}
	// }
	return (
		<>
					<div className={`textColor ${styles.suggestionList}`}>
						{
							suggestions.map((item, i) => {
								return (
									<div key={i} >
										<p onClick={() => {
											setDestination({ ...destination, "address": item.citystate, "latitude": item?.latitude, "longitude": item?.longitude })
											setSelection("date")
										}}>{!item.citystate?.includes("went wrong") && <Icon icon={mapPin} className={`mapPinStyle ${styles.mapPinStyle}`} size={18} />} {item.citystate}</p>
									</div>
								)
							})
						}
					</div>

		</>
	)
}
export default LocationSearch
