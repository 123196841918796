import React, { useState, useRef } from "react"
import useStyes from './styles';
import SearchTemplate1 from "../template1";
import useLoadStyle from "../useLoadStyle";
import MapProvider from "../../state/providers/MapProvider";
import { useEffect } from "react";
import ReactHeap from 'reactjs-heap';
import { isBrowser } from "../../common/utils";
const SearchTemplate2 = ({xAppID}) => {
  useLoadStyle(xAppID);
  const style = useStyes();
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);
  useEffect(() => {
          ReactHeap.initialize('3225535260');
          setTimeout(() => {
            if (isBrowser && window.heap) {
              window.heap.identify(window.location.hostname);
            }
          }, 300)
  },[])

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <MapProvider>
      <div className={`${style.widgetModal} ${isOpen ? style.open : ''}`} ref={modalRef}>

        {!isOpen && <button onClick={toggleModal} className={`button ${style.opac__booknow__button} `}>
          BOOK NOW
        </button>}

        <div className={`${style.widgetWindow} ${isOpen ? style.open : ''}`}>
          <div className={style.widgetHeader}>
            <span className={style.closebtn} onClick={() => {
              setIsOpen(false)
            }}>
              &times;
            </span>
            <SearchTemplate1 toggleModal={toggleModal} src={"t2"} xAppID={xAppID}/>
          </div>
        </div>
      </div>
    </MapProvider>
  )
}
export default SearchTemplate2