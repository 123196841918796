import React, { useState } from "react";
import MapContext, { initialState } from "../context/MapContext";


const MapProvider = props => {
    const [step, setStep]= useState(initialState.step)
    const [searchQuery, setSearchQuery] = useState(initialState.searchQuery)
	const [zoom, setZoom] = useState("");
	const [mapRef, setMapRef] = useState("");
	// Guide list and Map context
	const [location, setLocation] = useState(initialState.location);
	const [searchMetaData, setSearchMetaData] = useState(null)
	const [guidePins, setGuidePins] = useState([]);
	const [mapBounds, setMapBounds] = useState(null);
	const [mapDrag, setMapDrag] = useState(false);
	const [searchedValue, setSearchedValue] = useState("");
	const [searchedCity, setSearchedCity] = useState("");
	const [searchedState, setSearchedState] = useState("");
	const [isLocationDisable, setLocationDisable] = useState("");
	const [guidesAPICalled, setGuidesAPICalled] = useState(true);
	const [toFitBoundCity, setToFitBoundCity] = useState("");
	const [hoverCardId, setHoverCardId] = useState(null);
	const [loadingMask, setLoadingMask] = useState(false);
	const [markerOnClick, setMarkerOnClick] = useState(0);
	const [markerOnHover, setMarkerOnHover] = useState(0);
	const [tapCard, setTapCard] = useState(null);
	const [guides, setGuides] = useState([]);
	const [guidesWithinRange, setGuidesWithinRange] = useState([]);
	const [allGuides, setAllGuides] = useState([]);
	const [nearByGuidesCount, setNearByGuidesCount] = useState(0);
	const [searchPagination, setSearchPagination] = useState({
		limit: 20,
		active: 1
	});
	const [guideImages, setGuideImages] = useState([]);
	const [guideRateCard, setGuideRateCard] = useState([]);
	const [count, setCount] = useState(1);
	const [endDate, setEndDate] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [datefromHome, setDateFromHome] = useState(null);
	const [clearSearch, setClearSearch] = useState(false);
	return (
		<MapContext.Provider
			value={{
				step,setStep,
                searchQuery, setSearchQuery,
				zoom,setZoom,
				mapRef,setMapRef,
				location, setLocation,
				searchMetaData, setSearchMetaData,
				guidePins, setGuidePins,
				mapBounds, setMapBounds,
				mapDrag, setMapDrag,
				searchedValue, setSearchedValue,
				searchedCity, setSearchedCity,
				searchedState, setSearchedState,
				isLocationDisable, setLocationDisable,
				guidesAPICalled, setGuidesAPICalled,
				toFitBoundCity, setToFitBoundCity,
				hoverCardId, setHoverCardId,
				loadingMask, setLoadingMask,
				markerOnClick, setMarkerOnClick,
				markerOnHover, setMarkerOnHover,
				tapCard, setTapCard,
				guides, setGuides,
				guidesWithinRange, setGuidesWithinRange,
				allGuides, setAllGuides,
				nearByGuidesCount, setNearByGuidesCount,
				searchPagination, setSearchPagination,
				guideImages, setGuideImages,
				guideRateCard, setGuideRateCard,
				count, setCount,
				endDate, setEndDate,
				startDate, setStartDate,
				datefromHome, setDateFromHome,
				clearSearch, setClearSearch
			}}
		>
			{props.children}
		</MapContext.Provider>
	);
};

export default MapProvider;
