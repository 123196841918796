

import { useEffect } from 'react';

const useLoadStyle = (xAppID) => {

  useEffect(() => {
    const loadStyle = async (path) => {
      try {
        await require(`${path}`);
        console.log(`${path} loaded successfully`);
      } catch (error) {
        console.error(`Failed to load style: ${path}`, error);
      }
    };

    let stylePath;

    switch (xAppID) {
      case '788e72ee-dcef-4600-adfa-f7e5a6b0a880':
        stylePath = './styles/outdoornews.scss';
        break;
      case 'ae939ed4-9abf-4d60-8e96-d894324192ef':
        stylePath = './styles/outdoornews.scss';
        break;
      default:
        stylePath = '';
    }

    if (stylePath) {
      loadStyle(stylePath);
    }
  }, [xAppID]);

  return null;
};

export default useLoadStyle;
