import React, { useEffect, useState, useContext } from "react"
import { Card,  OverlayTrigger, Tooltip } from "react-bootstrap"
import  Styles from "../components/styles/guideList.module.scss"
import { getGuideDetailsLink } from "../../../widgets/common/utils"
import Icon from "react-icons-kit"
import { star } from 'react-icons-kit/fa/star'
import MapContext from "../../../widgets/state/context/MapContext";
import dayjs from "dayjs";
import getSymbolFromCurrency from 'currency-symbol-map'
import { Expertise, displayMoney, getDiff } from "./common/data/data"
import GuideImageSlider from "./common/GuideImageSlider"


// import { size } from "lodash";

const VerticalGuideCard = ({ handleCard,  item, index,  isNarrow , allGuides, xAppID}) => {
	const searchContext = useContext(MapContext)
	const [media, setMedia] = useState([])
	const [loading, setLoading] = useState(true)
	const [profileVideo, setProfileVideo] = useState("");
	const [reviewName, setReviewName] = useState("")
	const [avgRate, setAvgRate] = useState()
	const isBrowser = typeof window !== `undefined`;
	const [allguides, setAllGuides] = useState(allGuides)

	useEffect(()=>{
		setAllGuides(allGuides)
	},[allGuides])

	useEffect(() => {
		let photosArray = item.media || []
		if (item.guidepic) {
			let obj = { media_type: "profile_pic", mediapath: item.guidepic }
			photosArray.splice(0, 1, obj)
		}
		setMedia(photosArray)
		setLoading(false)
		let videoArray = item?.profile_video_path || ""
		setProfileVideo(videoArray)

		const reviewsCount = Number(item.avg_rating).toFixed(1)
		setAvgRate(reviewsCount)
		if (reviewsCount == 5.0) {
			setReviewName("Exceptional!")
		} else if (reviewsCount >= 4.8 && reviewsCount <= 4.9) {
			setReviewName("Wonderful!")
		} else if (reviewsCount >= 4.0 && reviewsCount <= 4.7) {
			setReviewName("Excellent!")
		} else if (reviewsCount >= 3.7 && reviewsCount <= 3.9) {
			setReviewName("Good!")
		} else if (reviewsCount === 0) {
			setReviewName("New Guide")

		} else {
			setReviewName("other")

		}




	}, [item])
	const getFishingTechniques = (record) => {
		const tripTypes = record.availability_details.trip_types
		const highlights = record.guide_highlights
		const no = record?.experience_date ? 2 : 3
		let data = []
		for (let rec of Expertise()) {
			record && Object.entries(record).map((exp) => {
				if (exp.includes(rec.id)) {
					data.push({ id: exp[0], value: exp[1], label: rec.label })
				}
			})
		}
		const records = data.filter(item => item.value)

		records.forEach((record, index) => {
			if (record.label === "Good with kids") {
				highlights && highlights?.map((highlight, index) => {
					if (highlight === "Good with Kids") {
						highlights.splice(index, 1);
					}

				});



			}
			if (record.label === "Good with New Anglers") {
				highlights && highlights?.map((highlight, index) => {
					if (highlight === "Good with New Anglers") {
						highlights.splice(index, 1);
					}

				});



			}
			if (record.label === "Master Guide") {
				highlights && highlights?.map((highlight, index) => {
					if (highlight === "Master Guide") {
						highlights.splice(index, 1);
					}
				});
			}
		})

		return (
			<>
				<span className={`${Styles.techniques}`}>
					{
						records && records.slice(0, no).map((item, i) => {
							if (item.value) {
								return (
									<span key={i} className={record.experience_date ? Styles.techlabel : Styles.techlabel1}>{item.label}</span>
								)
							}
						})
					}
				</span>
				{highlights === null ? <span>
					<span className={(!record.experience_date && records.length === 0) ? `${Styles.techniques} newTechniques` : `${Styles.techniques}`}>
						{
							tripTypes && tripTypes.map((item, i) => {

								return (
									<span key={i} className={record.availability_details.trip_types ? Styles.techlabel : Styles.techlabel1}>{item}</span>
								)


							})
						}
					</span>

				</span> :
					<span className={(!record.experience_date && records.length === 0) ? `${Styles.techniques} newTechniques` : `${Styles.techniques}`}>
						{
							highlights?.map((item, i) => {


								return (
									<span key={i} className={record.experience_date ? Styles.techlabel : Styles.techlabel1}>{item}</span>
								)

							})
						}
					</span>
				}
			</>
		)

	}



	const getName = (name) => {
		const rec = name.split(" ")
		if (rec.length > 1) {
			return rec[0] + " " + rec[1].charAt(0)
		} else {
			return name
		}
	}

	const add3Dots = (string, limit) => {
		let dots = "...";
		if (string.length > limit) {
			// you can also use substr instead of substring
			string = string.substring(0, limit) + dots;
		}
		return string;
	}
	const getTestimonialName = (name) => {
		const s = name?.split(" ")
		const initial = s?.length > 1 ? s[1]?.charAt(0) : null
		let text = initial ? `${s[0]} ${initial}.` : `${s[0]}`
		return text
	}
	const SelectedDateAvail = item.availability_details?.selectedDate_available || item.availability_details?.is_available;

	return (
		<div className={`${Styles.GuideCardNew} guidecard ${Styles.GuideCardVertical} FontStyle`} onMouseOver={() => handleCard(item.guideguid)} key={index} id={index}>
			<div className={Styles.GuideSubCard}>
				<div className={Styles.guideCardLink}>
					<a className={Styles.CardPosterArea} href={SelectedDateAvail ? 
						`${getGuideDetailsLink(item, allguides, searchContext?.searchQuery,null,xAppID)}` : 
						`${getGuideDetailsLink(item, allguides, null, null,xAppID)}`} target='_blank' >
						{/* <div className={Styles.GuideImgs} style={{ padding: "0px" }}> */}
						{item.super_guide &&
							<p className={Styles.superguide1}>Superguide</p>}

						<p className={Styles.trending}>
							{(item.is_trending_guide && item.is_new_guide) || (!(item.is_trending_guide) && item.is_new_guide) ? <span style={{ marginLeft: '-8px' }}><OverlayTrigger
								key={'top3'}
								placement={'top'}
								overlay={
									<Tooltip id={`tooltip3-${'top'}`}>
										A 10% promo code will automatically be applied at checkout
									</Tooltip>
								}
							>
								<img src={`${ImageCDN}NewGuide.png`} alt="NewListingDiscount" loading={'lazy'} width='30px' height='30px' />
							</OverlayTrigger> </span> : null}
							{item.is_trending_guide && !(item.is_new_guide) ?
								<span style={{ marginLeft: '-5px' }}><OverlayTrigger
									key={'top3'}
									placement={'top'}
									overlay={
										<Tooltip id={`tooltip3-${'top'}`}>
											This guide is hot! {`${item.company && item.company != "" ? item.company : item.guidename}`} is getting booked right now.
										</Tooltip>
									}
								>
									<img src={`${ImageCDN}IdentityVerifiedBadge.png`} alt="TrendingIcon" loading={'lazy'} width='30px' height='30px' />
								</OverlayTrigger> </span> : null

							}
							{!(item.is_trending_guide) && !(item.is_new_guide) ? null : null}
						</p>
						<div className={Styles.guideImageSlider}>
							{!loading && media && (media.filter(a => a.mediapath.slice(-4) != 'heic').length > 0) ?
								<GuideImageSlider data={media} profile_video_path={profileVideo} view="desktop" />
								:
								<div className={`${Styles.GuideImgs} `} style={{ padding: "0px" }} >
									<div className={loading ? `loading ${Styles.GuideImgSizeDesktop} ` : Styles.GuideImgSizeDesktop} style={loading ? { background: "white" } : {
										background: "#224041", backgroundRepeat: "no-repeat", backgroundPosition: "top", borderRadius: '12px', backgroundSize: "cover", textAlign: "center"
									}}>
										<span className={loading ? "loading" : {}} style={{ color: "#FFF", lineHeight: "135px", fontSize: "50px", margin: "auto" }} >{getName(`${item.company && item.company != "" ? item.company : item?.guidename}`).split(" ").map((n) => n[0]).join("").toUpperCase()}</span>
									</div>
								</div>
								 }
						</div>
						{/* </div> */}

					</a>

				</div>
				<div className={`${Styles.GuideRow} ${Styles.RightColGuide}`} >
					<a  href={SelectedDateAvail ? `${getGuideDetailsLink(item, allguides, searchContext?.searchQuery, null,xAppID)}` :
					 `${getGuideDetailsLink(item, allguides,null, null,xAppID)}`} target='_blank' className={Styles.noDecoration} >
						<div className={Styles.GuideCol1} style={{ display: "flex", padding: "0px 0px", height: "100%" }}>
							<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", position: "relative" }} >
								<div className={Styles.btnTextAlign}>
									<div>
										<span className={Styles.recs} style={{ fontSize: "11px" }}>Next Available</span><img src={`${ImageCDN}next-available.png`} alt="NextAvailableIcon" loading={'lazy'} width='15px' height='15px' className={Styles.gcImage2} style={{ verticalAlign: 'middle', marginLeft: '3px' }} />
										<button ton className={`button ${Styles.AvailBtn}`} style={{display:"block"}}>{dayjs(item?.snippet?.next_availiblity, "YYYY-DD-MM").format("MMMM D, YYYY")}</button>
									</div>
									<div className={Styles.rightHead}>
										<div className={Styles.loc} >
											{
												item.display_location && item.display_location !== null ?
													<>
														<span className={item.reviews_count && Number(item.reviews_count) > 0 && Styles.addDot} >
															<img src={`${ImageCDN}location-icon.png`} alt="LocationIcon" loading={'lazy'} width='15px' height='15px' className={Styles.gcImage2} style={{ verticalAlign: 'middle', marginRight: '3px' }} />
															{isBrowser && window.matchMedia("(max-width: 1570px) and (min-width: 1100px)").matches ? (
																<span className={`${Styles.recs} ${Styles.recsLoc}`}>
																	{add3Dots(item.display_location, 45)}
																</span>
															) : (<span className={`${Styles.recs} ${Styles.recsLoc}`} style={{ textAlign: "end" }}>
																{item.display_location}
															</span>)}
														</span>
													</>
													: item.city !== null && item.state !== null ?
														<>
															<span className={item.reviews_count && Number(item.reviews_count) > 0 && Styles.addDot}  >
																<img src={`${ImageCDN}location-icon.png`} alt="LocationIcon" loading={'lazy'} width='15px' height='15px' className={Styles.gcImage2} style={{ verticalAlign: 'middle', marginRight: '3px' }} />
																<span className={`${Styles.recs} ${Styles.recsLoc}`} style={{ textAlign: "end" }}>
																	{item.city === null && item.state === null ? "" : item.city !== null && item.state === null ? `${item.city}` :
																		item.city === null && item.state !== null ? `${item.state}` :
																			`${item.city.trim()}, ${item.state}`}
																</span>
															</span>
														</> : null
											}
										</div>
										<div className='instantConText'>
											<p className={`${Styles.recs} ${Styles.Avail}`} >
												{item?.snippet?.testimonial ? <OverlayTrigger
													key={'top3'}
													placement={'auto'}
													overlay={
														<Tooltip id={item?.snippet?.testimonial?.comment.length > 180 ? `tooltipSearch` : `tooltip2`}>
															{`"${item?.snippet?.testimonial?.comment}"`}
														</Tooltip>
													}
												>
													<span className={Styles.TestimonialText}>
														<img src={`${ImageCDN}testimonial.png`} alt={getTestimonialName(item?.snippet?.testimonial?.customer)} width={`13px`} height={`13px`} style={{ marginRight: "3px" }} />
														{getTestimonialName(item?.snippet?.testimonial?.customer)} Testimonial
													</span>
												</OverlayTrigger> :
													<span style={{ fontWeight: '600' }}>Instant Booking</span>
												}
											</p>
										</div>
									</div>


								</div>


								<Card.Title className={Styles.name}>
									{
										item.company && item.company.length > 65 ?
											<span className={Styles.tag}><span className={Styles.gcName2}>{`${item.company && item.company != "" ? item.company : item.guidename}`} <img src={`${ImageCDN}IdentityVerifiedBadge.png`} alt="IdentityVerifiedBadge" loading={'lazy'} width='15px' height='15px' /></span></span> :
											<span className={Styles.gcName2}>{`${item.company && item.company != "" ? item.company : item.guidename}`} <img src={`${ImageCDN}IdentityVerifiedBadge.png`} alt="IdentityVerifiedBadge" loading={'lazy'} width='15px' height='15px' style={{ verticalAlign: 'middle' }} /></span>
									}
								</Card.Title>

								<p className={reviewName != "other" ? `${Styles.recs} ${Styles.Avail} ${Styles.Boatlimit} ${Styles.noShow}` : `${Styles.recs} ${Styles.Avail} ${Styles.Boatlimit}`} style={{ margin: "0px", width: "100%", fontWeight: "500", color: "#2d2d2d", fontSize: '10px' }}>
									<span>{item.experience_date && `${getDiff(item.experience_date)} Experience`}</span>
									{getFishingTechniques(item)}
								</p>
								<div className={Styles.TripInfo}>
									{(item?.snippet?.featured_trip || item?.snippet?.most_booked_trip) && <p className={reviewName != "other" ? `${Styles.recs} ${Styles.Avail} ${Styles.Boatlimit} ${Styles.desclimit} ${Styles.noShow}` : `${Styles.recs} ${Styles.Avail} ${Styles.desclimit}`} style={{ margin: "0px", width: "100%" }}>
										{item?.snippet?.featured_trip ? <span className={Styles.MostBookedText}> {`Featured Trip: `} <span>{item?.snippet?.featured_trip}</span></span> :
											<span className={Styles.MostBookedText}>{`Most Booked Trip: `}<span>{item?.snippet?.most_booked_trip}</span></span>}
									</p>}
									<div className={Styles.TripDescription}>
										{item?.snippet?.featured_trip ? item?.featured_trip_description : item?.most_booked_trip_description}
									</div>
								</div>
								<div className={Styles.TripBottomInfo}>
									{item.is_new_guide && <div className={Styles.priceCont} style={{ margin: "6px 0px 0px" }}>
										<button className={(item.is_new_guide) ? Styles.NewGuidebtnListing : Styles.DreviewContBtn}>
											<OverlayTrigger
												key={'top3'}
												placement={'top'}
												overlay={
													<Tooltip id={`tooltip2-${'top'}`}>
														This guide is a new listing and has a 10% promo code. Click their profile to learn more.
													</Tooltip>
												}
											>
												<span>New Listing Discount</span>
											</OverlayTrigger>

										</button    >

									</div>}
									<div className={Styles.priceCont} style={{ margin: "6px 0px 0px" }}>
										{
											Number(item.reviews_count) > 0 && !(item.is_new_guide) ? <p className={Styles.CalCellationIcon} style={{ fontStyle: 'italic' }}>Free cancellation up to {item.allowed_cancellation_days === 1 ? '24 hrs' : `${item.allowed_cancellation_days} days`}</p> : null
										}

									</div>
									<div className={Styles.priceCont} >
										<div>
											{(item.is_new_guide) ? <button className={(item.is_new_guide) && Number(item.reviews_count) > 0 ? Styles.NewGuidebtn : Styles.DreviewContBtn}>New Guide</button > : <button className={reviewName === 'other' ? Styles.NewGuidebtnLast : Styles.ExceptionalBtn}><span>{reviewName} {parseFloat(avgRate)}/5</span></button  >}
										</div>
										<p className={`${Styles.Avail} ${Styles.startAt} startAtPrice`}>Starting at <span className={Styles.startAtP}>{`${(getSymbolFromCurrency(item.currency) || '$')}${displayMoney(item.min_rate)}`}</span></p>

									</div>
									<div className={`${Styles.priceCont} lastPriceCount`} >
										<button className={(item.is_new_guide) && Number(item.reviews_count) === 0 ? Styles.NewGuidebtn : Styles.DreviewContBtn}>New Guide</button    >

										<div className={Styles.DreviewCont}>
											{item.reviews_count && Number(item.reviews_count) > 0 &&
												<>
													<Icon icon={star} size={12} />
													<p><span className="dreviewContText" style={{ color: '#2E2E2E' }}>{Number(item.avg_rating).toFixed(1)} </span><span className="reviewContText">({Number(item.reviews_count)} Reviews)</span></p>
												</>}</div>
										<p className={`${Styles.recs} ${Styles.Avail}`} style={{ fontWeight: '600', fontSize: '10px' }}>{item.availability_details.trips === 1 ? `${item.availability_details.trips} Trip Available` : item.availability_details.trips != 0 ? `${item.availability_details.trips} Trips Available` : "Show all Available Trips"}</p>
									</div>
								</div>


							</div>
						</div>
					</a>
				</div>
			</div>
		</div >
	)
}

export default VerticalGuideCard
