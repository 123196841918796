import React , {useState, useCallback, useEffect} from "react"
import Autosuggest from 'react-autosuggest';
import"./template3/styles/search.css"
import { getPlaces } from "../common/utils";
import AppleMapkit from "../common/appleMapKit";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather";
import { getMyAddress } from "../common/storage";
const HomeSearch = ({address="", handleSearchData,  }) => {
    const isBrowser = typeof window !== `undefined`
    const [showMapKit, setShowMapKit] = useState(false)
    const [value, setValue] = useState(address)
    const [places, setPlaces] = useState([])
    const [allcitystate, setAllcitystate] = useState([]);
	useEffect(() => {
		setValue(address)
	},[address])
    const onChange = (event, { newValue, method }) => {
        setValue(newValue)
    }
    const handlePress = (e, { highlightedSuggestion }) => {
        if (highlightedSuggestion && location.pathname === "/") {
            const { city, state, latitude, longitude, display } = highlightedSuggestion

            setData(city, state, latitude, longitude, "none", display)
        }
    }
    const setData = async (city, state, latitude, longitude, method, display) => {
        
            const lat = Number(latitude)
            const lng = Number(longitude)

            const data = {
                "latitude":lat,
                "longitude": lng,
                "address":display,
            }
            handleSearchData(data)
    }
    const inputProps = {
        placeholder: "Enter location",
        value,
        onChange: onChange,
        onBlur: handlePress,
        className: "react-autosuggest__innewhome"
    }
    const onSuggestionsFetchRequested = ({ value }) => {
		if (value.trim()) {
			if (isBrowser) {
				if (value.trim().length >= 1 && value != "Map Area") {
					try {
						const myLocation = getMyAddress();
                        const lat = myLocation?.lat;
                        const long = myLocation?.lng; 
						const coordinate = new window.mapkit.Coordinate(lat, long); // latitude, longitude
						const span = new window.mapkit.CoordinateSpan(.016, .016); // latitude delta, longitude delta
						const usaRegion = new window.mapkit.CoordinateRegion(coordinate, span);
						let search = new window.mapkit.Search({ region: usaRegion });
						search.autocomplete(value?.toLowerCase()?.trim(), (error, response) => {
							if (error) {
								return;
							}
							let data = null
							const places = getPlaces(response.results, value?.toLowerCase()?.trim());
							
							if (places.length === 0) {
								setPlaces([{
									citystate: "Record not found"
								}])
							} else {
								if (data && value.length > 3) {
									places.splice(0, 0, data)
								}
								setAllcitystate(places)
								setPlaces(places)
							}
						},{ includePointsOfInterest:true,
                            includePhysicalFeatures:true,
                            includeAddresses: true,
                             includeQueries:false,
							});
					} catch (exception) {
						// setPlaces([{
						// 	citystate: "Record not found. Something went wrong"
						// }])
						console.warn('Failed to get autocomplete', exception)
					}
				} else {
					setAllcitystate([])
					setPlaces([])
				}
			}
		} else {
			const result = allcitystate.filter((data) => {

				let filteredValues = data.citystate.toLowerCase();
				return filteredValues.indexOf(
					value.toLowerCase()) !== -1
			})
			setPlaces(result);
		}
	}
    const onSuggestionsClearRequested = useCallback(() => {
		setPlaces([])
	}, [])
    const getSuggestionValue = suggestion => {
		return suggestion.citystate;
	};

	const renderSuggestion = suggestion => {
		return suggestion.citystate;
	};
    const onSuggestionSelected = (event, { suggestion, method }) => {
		event.preventDefault()
		if (suggestion && suggestion.citystate !== "Record not found") {
			// const { city, state, latitude, longitude } = suggestion
			if (location.pathname === "/" && method === "enter") {
				setData(suggestion.city, suggestion.state, suggestion.latitude, suggestion.longitude, method, suggestion.display)
			} else {
				setData(suggestion.city, suggestion.state, suggestion.latitude, suggestion.longitude, method, suggestion.display)
			}
		}
	}
    const clearInput = () => {
		setValue("");
	};
    return (
        <div onMouseEnter={() => { setShowMapKit(true) }} className={`Container icon-style`}
            style={{ display: 'flex', alignItems: 'center', marginBottom: 20, height: 50, padding: 0, borderRadius: 5, fontFamily: '"Poppins", sans-serif', fontSize: 18, color: '#979797', position: 'relative', cursor: 'pointer', backgroundColor: '#fff' }}>
            <Autosuggest
                suggestions={places}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                inputProps={inputProps}
                focusInputOnSuggestionClick={false}
                highlightFirstSuggestion={true}
            />

            {value?.length>0&& <div className={`Hoverbg clear-icon_X`} onClick={() => clearInput()}>X</div>}

            {showMapKit && <AppleMapkit />}
        </div>
    )
}
export default HomeSearch