import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  block: {
    display: 'block !important'
  },
  small: {
    fontSize: 14,
  },
  container: {
    width: 'auto',
    color: 'black'
  },
  rateCardContainer: {
    // border: '1px solid #c7cbce',
    backgroundColor: 'white'
  },
  CustomInput :{
fontSize:'17px',
display:'flex',
alignItems:'center',
lineHeight:'40px',
'img': {
  width: '19px !important',
  height: '19px !important',
},
'@media screen and (max-width: 700px)': {
  fontSize: '14px',
  lineHeight:'30px'
}
  },
  fullWidth: {
    width: '100%'
  },
  heading: {
    fontWeight: 'bolder',
    display: 'block'
  },
  next: {
    background: '#42642f',
    float: 'right',
    borderRadius: '20px',
    width: '100px',
    textTransform: 'capitalize',
    margin: '10px',
    '&:hover': {
      background: '#42642f',
    },
  },
  previous: {
    float: 'right',
    background: 'white',
    borderRadius: '20px',
    width: '100px',
    margin: '10px',
    textTransform: 'capitalize',
    color: '#42642f',
    border: '1px solid #42642f',
    '&:hover': {
      background: '#FFF',
    },
  },
  ErrorCont: {
		color: "red",
		fontSize: "13px",
	},
  separator: {
    padding: '10px',
  },
  select: {
    width: '100%',
    padding: '13px 10px',
    backgroundColor: '#fff',
    color: 'black'
  },
  form: {
    width: '100%',
    "& input": {
      height: 30,
      backgroundColor: '#fff',
      color: 'black'
    }
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
  },
  label: {
    alignItems: 'center',
    color: '#6b747e',
    display: 'flex',
    fontSize: 14,
    height: 40,
    justifyContent: 'flex-end',
    marginBottom: 0,
    padding: '0 15px',
    textAlign: 'right',
    width: '40%',
    '@media screen and (max-width: 560px)': {
      width: 'auto',
      justifyContent: 'flex-start',
      textAlign: 'left',
      padding: '0',
      height: 'auto'
    },
  },
  input: {
    border: 'none !important',
    padding: '6px 0 7px !important',
    marginBottom: '0 !important',
    backgroundColor: 'none !important',
    'box-shadow': 'none !important',
    color: 'currentColor !important',
    fontSize: '14px !important',
    width: '100%',
  },
  select1: {
    padding: '6px 0 7px !important',
  },
  bold: {
    fontSize: 17,
    fontWeight: 'bold'
  },
  customDropdownoption: {
    cursor: 'pointer',
    padding: '0px 15px',
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
      backgroundColor: '#2196f3',
      opacity: 0.65
    }
  },
  selected: {
    backgroundColor: '#2196f3',
    color: '#fff',
  },
  paymentContainer: {
    backgroundColor: '#f5f5f5',
    border: '1px solid #c7cbce',
    marginBottom: 5
  },
  yourDetails: {
    backgroundColor: '#6c757e',
    color: '#fff',
    fontSize: 16,
    padding: '10px 20px'
  },
  tripHeading: {
    color: '#42642f',
    fontSize: 16,
    fontWeight: 'bolder',
    margin: '10px 26%',
    '@media screen and (max-width: 560px)': {
      margin: '10px',
    },
  },
  rowData: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    padding: '0 6.5%',
    width: '100%',
    '@media screen and (max-width: 900px)': {
      flexDirection: 'column',
      padding: '0 3%',
    },
  },
  divider: {
    marginTop: 5,
    marginBottom: 5,
  },
  errorMsg: {
    color: 'red',
    textAlign: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '5px'
  },
  textAssurance: {
    borderBottom: '1px solid #c7cbce',
    color: '#6b747e',
    fontSize: 14,
    marginBottom: 0,
    paddingBottom: 10,
    textAlign: 'center',
    width: '100%'
  },
  filter1: {
    paddingLeft: 10
  },
  guestLabel: {
    color: 'black !important'
  },
  Phone_Input: {
    height: 40,
    "& input": {
      height: 30,
      border: 'none',
      outline: 'none',
      fontSize: 16,
      // fontWeight: 'bolder'
    }
  },
  overlay: {
    backgroundColor: '#EFEFEF',
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: 1000,
    opacity: .5, /* in FireFox */
  },

  disabled: {
    pointerEvents: 'none',
    color: 'EFEFEF'
  },
  instantBook: {
    fontWeight: 600,
    padding: '5px 20px',
    color: 'white !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '0 auto',
    backgroundColor: '#086edc!important',
    border: ' 4px solid #086edc!important',
    boxShadow: '0 8px 10px -6px #000',
    '&:hover': {
      color: 'white !important',
      background: '#20b4e4',
      backgroundColor: '#086edc !important',
      border: ' 4px solid #086edc !important',
    },
    textTransform: 'unset',
    marginBottom: 10,
    '&:disabled': {
      cursor: 'not-allowed'
    },
    width: '100%'
  },
  disabledInstantBook: {
    fontWeight: 600,
    padding: '5px 20px',
    color: '#086edc !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '0 auto',
    border: ' 4px solid #086edc!important',
    boxShadow: '0 8px 10px -6px #000',
    '&:hover': {
      color: '#086edc !important',
      border: ' 4px solid #086edc !important',
    },
    textTransform: 'unset',
    marginBottom: 10,
    '&:disabled': {
      cursor: 'not-allowed'
    },
    width: '100%'
  },
  redBoundary: {
    color: 'red',
    marginTop: 10,
    marginLeft: 10,
    borderRadius: 5,
    border: `1px solid red`,
    padding: 10,
    width: '100%'
  },
  boxShadow: {
    boxShadow: '0 6px 16px rgba(0,0,0,.2)!important',
    // position: 'sticky',
    // top: '95px'
  },
  placeholder: {
    '&::placeholder': {
      color: '#aab7c4',
    }
  },
  breadcrumb:{
    display:"flex",
    alignItems:"center",
  },
  rightIcon:{
    color:"black",
    display:"flex"
  },
  whiteIcon:{
    color:"white",
    display:"flex"
  },
  bdtext:{
    color:"#1a04ec",
    cursor:"pointer"
  },
  TripDateHolder:{
    alignItems: "baseline",
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 25px",
    '& p':{
      fontSize: "14px",
    fontWeight: 600,
    margin: "5px 0px",
    }
  },
  TripDate:{
    color: "#000",
    fontSize: "14px",
    fontWeight: 500,
  },
  EditText:{
    color: "#000",
    cursor: "pointer",
    fontSize: "14px",
    textDecoration: "underline",
  },
  Gmodal:{
    margin: "auto",
    width: "20rem",
    background:"white",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding:"15px",
  },
  Gmodal1:{
    margin: "auto",
    width: "22rem",
    background:"white",
    borderRadius:"10px",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: "10px 20px",
  },
  dynamicModal: {
    margin: "auto",
    width: "36rem",
    background:"white",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '@media screen and (max-width: 600px)': {
      width:"95%"
    },
  },
  displayButtons:{
    alignItems: "center",
    borderTop: "1px solid #ccc",
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 0",
    textAlign: "center",
    "& p":{
      color: "#000",
    cursor: "pointer",
    fontSize: "13px",
    fontWeight: 600,
    margin: 0,
    textDecoration: "underline",
    }
  },
  guests__container:{
    borderBottom:" 1px solid #ccc",
    display: "flex",
    paddingBottom: "20px",
    width: "100%",
},
guests__text:{
  width: "60%",
},
guests__button:{
  alignItems: "center",
    display: "flex",
    justifyContent: 'space-around',
    width: "40%",
},
guests__button__icon:{
  alignContent: "center",
  background: "#fff",
  border:" 2px solid #c7cdbe",
  borderRadius: "50%",
  color: "grey",
  display: "grid",
  height: "22px",
  justifyContent: "center",
  width: "22px",
  "& :disabled":{
    cursor: "default",
    opacity: .5,
  }
},
  block: {
    display: 'block !important'
  },
  small: {
    fontSize: 14,
  },
  container: {
    width: 'auto',
    color: 'black'
  },
  rateCardContainer: {
    border: '1px solid #c7cbce',
    backgroundColor: 'white'
  },
  fullWidth: {
    width: '100%'
  },
  heading: {
    fontWeight: 'bolder',
    display: 'block'
  },
  next: {
    background: '#42642f',
    float: 'right',
    borderRadius: '20px',
    width: '100px',
    textTransform: 'capitalize',
    margin: '10px',
    '&:hover': {
      background: '#42642f',
    },
  },
  previous: {
    float: 'right',
    background: 'white',
    borderRadius: '20px',
    width: '100px',
    margin: '10px',
    textTransform: 'capitalize',
    color: '#42642f',
    border: '1px solid #42642f',
    '&:hover': {
      background: '#FFF',
    },
  },
  separator: {
    padding: '10px',
  },
  select: {
    width: '100%',
    padding: '13px 10px',
    backgroundColor: '#fff',
    color: 'black'
  },
  form: {
    width: '100%',
    "& input": {
      height: 30,
      backgroundColor: '#fff',
      color: 'black'
    }
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
  },
  label: {
    alignItems: 'center',
    color: '#6b747e',
    display: 'flex',
    fontSize: 14,
    height: 40,
    justifyContent: 'flex-end',
    marginBottom: 0,
    padding: '0 15px',
    textAlign: 'right',
    width: '40%',
    '@media screen and (max-width: 560px)': {
      width: 'auto',
      justifyContent: 'flex-start',
      textAlign: 'left',
      padding: '0',
      height: 'auto'
    },
  },
  input: {
    border: 'none !important',
    padding: '6px 0 7px !important',
    marginBottom: '0 !important',
    backgroundColor: 'none !important',
    'box-shadow': 'none !important',
    color: 'currentColor !important',
    fontSize: '14px !important',
    width: '100%',
  },
  select1: {
    padding: '6px 0 7px !important',
  },
  bold: {
    fontSize: 17,
    fontWeight: 'bold'
  },
  customDropdownoption: {
    cursor: 'pointer',
    padding: '0px 15px',
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
      backgroundColor: '#2196f3',
      opacity: 0.65
    }
  },
  selected: {
    backgroundColor: '#2196f3',
    color: '#fff',
  },
  paymentContainer: {
    backgroundColor: '#f5f5f5',
    border: '1px solid #c7cbce',
    marginBottom: 5
  },
  yourDetails: {
    backgroundColor: '#6c757e',
    color: '#fff',
    fontSize: 16,
    padding: '10px 20px'
  },
  tripHeading: {
    color: '#42642f',
    fontSize: 16,
    fontWeight: 'bolder',
    margin: '10px 26%',
    '@media screen and (max-width: 560px)': {
      margin: '10px',
    },
  },
  rowData: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    padding: '0 6.5%',
    width: '100%',
    '@media screen and (max-width: 900px)': {
      flexDirection: 'column',
      padding: '0 3%',
    },
  },
  divider: {
    marginTop: 5,
    marginBottom: 5,
  },
  errorMsg: {
    color: 'red',
    textAlign: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '5px'
  },
  textAssurance: {
    borderBottom: '1px solid #c7cbce',
    color: '#6b747e',
    fontSize: 14,
    marginBottom: 0,
    paddingBottom: 10,
    textAlign: 'center',
    width: '100%'
  },
  filter1: {
    paddingLeft: 10
  },
  guestLabel: {
    color: 'black !important'
  },
  Phone_Input: {
    height: 40,
    "& input": {
      height: 30,
      border: 'none',
      outline: 'none',
      fontSize: 16,
      fontWeight: 'bolder'
    }
  },
  instantBook: {
    fontWeight: 600,
    padding: '5px 20px',
    color: 'white !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '0 auto',
    backgroundColor: '#086edc!important',
    border:' 4px solid #086edc!important',
    boxShadow: '0 8px 10px -6px #000',
    '&:hover': {
      color: 'white !important',
      background: '#20b4e4',
      backgroundColor: '#086edc !important',
      border:' 4px solid #086edc !important',
    },
    textTransform: 'unset',
    marginBottom: 10,
    '&:disabled': {
      cursor: 'not-allowed'
    },
    width: '100%'
  },
  disabledInstantBook: {
    fontWeight: 600,
    padding: '5px 20px',
    color: '#086edc !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '0 auto',
    border:' 4px solid #086edc!important',
    boxShadow: '0 8px 10px -6px #000',
    '&:hover': {
      color: '#086edc !important',
      border:' 4px solid #086edc !important',
    },
    textTransform: 'unset',
    marginBottom: 10,
    '&:disabled': {
      cursor: 'not-allowed'
    },
    width: '100%'
  },
  redBoundary: {
    color: 'red', 
    marginTop: 10, 
    marginLeft: 10, 
    borderRadius: 5, 
    border: `1px solid red`,
    padding: 10, 
    width: '100%'
  },
  boxShadow: {
    boxShadow: '0 6px 16px rgba(0,0,0,.2)!important',
    // position: 'sticky',
  },
  placeholder: {
    '&::placeholder': {
      color: '#aab7c4',
    }
  },
  breadcrumb:{
    display:"flex",
    alignItems:"center",
  },
  rightIcon:{
    color:"black",
    display:"flex"
  },
  bdtext:{
    color:"#1a04ec",
    cursor:"pointer"
  },
  tooltip: {
    lineHeight: 'normal !important'
  },
}));
