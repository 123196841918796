import { Button } from "@mui/material";
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  bookNow: {
    color: 'white !important',
    borderRadius: 0,
    textTransform: 'capitalize',
    backgroundColor: '#1a04ec!important',
    border:' 1px solid #1a04ec!important',
    boxShadow: '2px 2px 4px 0px #808080',
    '&:hover': {
        color: '#1a04ec',
        backgroundColor: 'white !important',
    },
  },
  anchor: {
      textDecoration: 'none'
  }
}));

const BookNow = ({ to = '/booking', label = 'Book Now' }) => {
    const classes = useStyles()

    return  (
        <a href={to} className={classes.anchor}>
          <Button className={classes.bookNow}>{label}</Button>
        </a>
      )
};

export default BookNow;
