import React from 'react';
import { createRoot } from 'react-dom/client';
import Widgets from './Widgets';
// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://5b6cd0d4d4cc512b6c3c23169db14d34@o4506828314050560.ingest.us.sentry.io/4506834679234560",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", 'feature-staging.widgets', 'taylorlcaltest.wixsite.com'],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = createRoot(document.getElementById('app'));

root.render(
  <Widgets />
);

if (module.hot) {
  module.hot.accept();
}