import React, {  useEffect } from "react"
// import MapContext from "../../widgets/state/context/MapContext"
import Search from "./pages/search"
import useLoadStyle from "../../widgets/searchtemplates/useLoadStyle";
import "../../seo-widgets/guidefinder/components/styles/custom.scss"
import MapProvider from "../../widgets/state/providers/MapProvider";
import Fontstyle from "../../widgets/searchtemplates/Fonts";
import ReactHeap from 'reactjs-heap';
import { isBrowser } from "../../widgets/common/utils";
const GuideFinder =({xAppID})=>{
    useLoadStyle(xAppID);
    useEffect(() => {
        ReactHeap.initialize('3225535260');
        setTimeout(() => {
			if (isBrowser && window.heap) {
				window.heap.identify(window.location.hostname);
			}
		}, 300)
    },[])
    // const context = useContext(MapContext)
    // const {
    //     step, 
    // }= context

// const AllSteps = {
//     'search':(
//        <Search xAppID={xAppID}/>
//       ),
// }
//     const STEP = AllSteps
    return(
        <div className={"FontStyle"}>
            <Fontstyle xAppID={xAppID}/>
          <MapProvider>
          <Search xAppID={xAppID}/>
          </MapProvider>
        </div>
    )
}
export default GuideFinder