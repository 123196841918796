import React, { useState } from "react";
import Slider from "react-slick";
import Styles from "../styles/guideList.module.scss";
import Icon from "react-icons-kit"
import { play3 } from 'react-icons-kit/icomoon/play3'
import { getCorrectedImagePath } from "./data/data";

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={`${className} `}
			onClick={onClick}>
			<img src={`${ImageCDN}arrow_forward_right.svg`} className={Styles.img1} width={"100%"} height={"100%"} loading="lazy" />

		</div>


	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={`${className} `}
			onClick={onClick}>

			<img src={`${ImageCDN}arrow_back_left.svg`} className={Styles.img1} width={"100%"} height={"100%"} loading="lazy" />
		</div>



	);
}

const GuideImageSlider = ({ data, view, profile_video_path, }) => {
	const [hover, setHover] = useState(false);
	const [imagearray, setImageArray] = useState(data);
	const [isDisabledImage, setDisableImgae] = useState()
	const isBrowser = typeof window !== `undefined`
	const isMobile = isBrowser && window.matchMedia("only screen and (max-width: 768px)").matches

	var settings = {
		dots: imagearray?.length == 1 ? false : true,
		infinite: imagearray?.length == 1 ? false : true,
		speed: 100,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: imagearray?.length == 1 ? false : <SampleNextArrow />,
		prevArrow: imagearray?.length == 1 ? false : <SamplePrevArrow />,
		dotsClass: view === "desktop" ? `slick-dots CarouselDotsDesktop` : `slick-dots CarouselDots1`
	};

	const onErrorCallBack = (pic, e) => {
		const photos = imagearray.filter(item => item.mediapath !== pic.mediapath);
		setImageArray(photos)
	}
	return (
		<div onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)} style={{ height: "100%" }}>
			{(profile_video_path?.length > 0) && !['jpg', 'png', 'webp', 'gif', 'jpeg', 'tiff', 'jfif', 'heic'].includes(profile_video_path?.split('.')?.pop()?.toLowerCase()) ?
				<div className={`${Styles.Carousel} carouselVertical`}>
					<Slider {...settings}>
						{imagearray.map((item, index) => (
							
							<div key={index} className={`${view === "desktop" ? `${Styles.GuideImgs} ${Styles.addpadding}` : `${Styles.GuideImgs} ${Styles.GuideImgsMobView}`}  `} style={{ height: "100%" }}>
								{item.media_type === "profile_pic" && hover ?
									// <ReactPlayer
									// 	url={profile_video_path.charAt(0) == '/' ? `${cloudfront}${profile_video_path}` : `${cloudfront}${profile_video_path}`}
									// 	className={Styles.videoplayer}
									// 	playing={true}
									// 	muted={true}
									// 	width="100% !important"
									// 	height="100% !important"
									// 	loading
									// />
									<video
										src={profile_video_path.charAt(0) === '/' ? `${cloudfront}${profile_video_path}` : `${cloudfront}${profile_video_path}`}
										className={Styles.videoplayer}
										autoPlay
										muted
										controls={false}  // Remove controls if you don't want to display them
										style={{ width: '100%', height: '100%' }}
										>
									</video>
									:
									<div style={{ height: "100%" }}>
										<img src={`${GUIDESLY_GUIDEPIC_IP}300x300/filters:format(jpeg,png)/${getCorrectedImagePath(item.mediapath)}`}
											alt={item.caption ? item.caption : index} className={view === "desktop" ? Styles.GuideImgSizeDesktop : Styles.GuideImgSize}
											style={{
												objectFit: "cover", borderRadius: view === "desktop" ? 12 : 10,
												objectPosition: "top",
											}} loading="lazy" width={"100%"} height={"100%"}
											onError={(e) => {
												onErrorCallBack(item, e)
											}}
										/>
										{item.media_type === "profile_pic" && !isMobile && <Icon icon={play3} className={Styles.overImage} size={20} alt="playbutton" />}
									</div>}
							</div>
						))}
					</Slider >
				</div> :
				<div className={`${Styles.Carousel} carouselVertical`} >
					<Slider {...settings}>
						{imagearray.map((item, index) => (
							<div key={index} className={`${view === "desktop" ? `${Styles.GuideImgs} ${Styles.addpadding}` : `${Styles.GuideImgs} ${Styles.GuideImgsMobView}`}  `} >
								<img src={`${GUIDESLY_GUIDEPIC_IP}300x300/filters:format(jpeg,png)/${getCorrectedImagePath(item.mediapath)}`}
									alt={item.caption ? item.caption : index} className={view === "desktop" ? Styles.GuideImgSizeDesktop : Styles.GuideImgSize}
									style={{
										objectFit: "cover", borderRadius: view === "desktop" ? 12 : 10,
										objectPosition: "top",
									}} loading="lazy" width={"100%"} height={"100%"}
									onError={(e) => {
										onErrorCallBack(item, e)
									}}
								/>
							</div>
						))}
					</Slider >
				</div>
			}
		</div>


	);
};
export default GuideImageSlider;
