import React, { useState, useEffect } from 'react'
import { Card, Row, Col } from "react-bootstrap"
import  Styles from "./styles/guideList.module.scss"
import Icon from "react-icons-kit"
import { x } from 'react-icons-kit/feather';
import GuideImageSlider from "./common/GuideImageSlider";
import dayjs from 'dayjs'
import getSymbolFromCurrency from 'currency-symbol-map'
import { displayMoney } from './common/data/data';

const GuideCardsMobNew = ({ getGuide, item, from, showClose, handleClear }) => {
	// const allGuides = GetAllGuidesFromStrapi();
	const [media, setMedia] = useState([])
	const [loading, setLoading] = useState(true)
	const [profileVideo, setProfileVideo] = useState("");
	const page = from && (from === "article" || from === "fish") ? true : false
	const getName = (name) => {
		const rec = name.split(" ")
		if (rec.length > 1) {
			return rec[0] + " " + rec[1].charAt(0)
		} else {
			return name
		}
	}
	useEffect(() => {
		let photosArray = item.media || []
		if (item.guidepic) {
			let obj = { media_type: "profile_pic", mediapath: item.guidepic }
			photosArray.splice(0, 1, obj)
		}
		setMedia(photosArray)
		setLoading(false)
		let videoArray = item?.profile_video_path || ""
		setProfileVideo(videoArray)
	}, [item])
	const next = dayjs(item?.snippet?.next_availiblity, "YYYY-DD-MM").format("MMMM D, YYYY")
	return (
		<div className={Styles.GuideCardNew}>
			<div className={`${Styles.GuideImgs} ${Styles.GuideImgsMobView}`} style={{ padding: "0px" }}>
				{showClose && <div className={Styles.CloseDiv}>
					<button onClick={() => handleClear()}><Icon icon={x} /></button>
				</div>}
				{item.super_guide &&
					<p className={Styles.superguide1}>Superguide</p>}
				{media && media.length > 0 ?
					<GuideImageSlider data={media} profile_video_path={profileVideo} paddingOff={true} /> :
					<div className={loading ? `${Styles.GuideImgSize} loading` : Styles.GuideImgSize} style={{
						width: "auto", margin: "5px", background: "#224041", backgroundRepeat: "no-repeat", backgroundPosition: "top", backgroundSize: "cover", borderRadius: '10px', textAlign: "center"
					}}>
						<span className={Styles.imgText} >{getName(`${item.company && item.company != "" ? item.company : item?.guidename}`).split(" ").map((n) => n[0]).join("").toUpperCase()}</span>
					</div>}
			</div>
			<Row className={Styles.GuideRow} style={{ height: "auto", width: "100%", margin: "0px 10px 0px 0px" }}>
				<Col xs={12} sm={12} lg={12} className={Styles.GuideCol1} >
					<>
						<Card.Title className={`${Styles.mobComapanyText}`}>
							<div className={(!item.avg_rating || (item.avg_rating && Number(item.avg_rating) === 0)) ? Styles.fullWidth : Styles.partialWidth}>
								<div className={Styles.mobCont}>

									{
										item.display_location && item.display_location !== null ?
											< >
												<p className={page ? Styles.loc1 : `${Styles.recs} ${Styles.Avail}`}>
													{item.display_location}
												</p>
											</> : item.city !== null && item.state !== null ?
												< >
													<p className={page ? Styles.loc1 : `${Styles.recs} ${Styles.Avail}`}>
														{item.city === null && item.state === null ? "" : item.city !== null && item.state === null ? `${item.city}` :
															item.city === null && item.state !== null ? `${item.state}` :
																`${item.city.trim()}, ${item.state}`}
													</p>
												</> : null
									}

								</div>
							</div>
							{item.avg_rating && Number(item.avg_rating) > 0 && <div className={Number(item.avg_rating) > 4.5 ? `${Styles.mobRating} ${Styles.mobActive}` : `${Styles.mobRating}`}>
								{Number(item.avg_rating).toFixed(1)}
							</div>}
						</Card.Title>
						<p className={page ? Styles.gcName1 : Styles.companyHead} >
							{`${item.company && item.company != "" ? item.company : item.guidename}`}
						</p>
						<div style={{ display: "flex", paddingBottom: "10px" }}>
							<p className={`${Styles.recs} ${Styles.Avail}`} style={{ padding: "0px" }}>{item.availability_details.trips === 1 ? `${item.availability_details.trips} Trip Available` : item.availability_details.trips != 0 ? `${item.availability_details.trips} Trips Available` : "Show all Available Trips"}</p>
							<div className={Styles.DotSeparator}></div>
							<p className={`${Styles.recs} ${Styles.Avail}`} style={{ padding: 0 }}><span>Starting at</span> {`${(getSymbolFromCurrency(item.currency) || '$')}${displayMoney(item.min_rate)}`}</p>
						</div>
						<div className={Styles.btnornage}>
							<button className={`button`} style={{ float: "right", fontSize: "13px",  }}>Next available date - <b>{next}</b></button>
						</div>

					</>


					
				</Col>

			</Row>
		</div >
	)
}
export default GuideCardsMobNew
