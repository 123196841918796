import React, { useEffect } from 'react'
import MapProvider from '../../state/providers/MapProvider'
import Template3 from './template3'
import ReactHeap from 'reactjs-heap';
import { isBrowser } from '../../common/utils';
export default function Index(props) {
	useEffect(() => {
		ReactHeap.initialize('3225535260');
		setTimeout(() => {
			if (isBrowser && window.heap) {
				window.heap.identify(window.location.hostname);
			}
		}, 300)
	}, [])

	return (
	<MapProvider>
		<Template3 {...props}/>
	</MapProvider>
	)
}
