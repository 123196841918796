import React, { useContext, useState, useEffect, useRef } from "react";
import { mapPin } from 'react-icons-kit/feather/mapPin'
import axios from "axios"
import { androidCancel } from 'react-icons-kit/ionicons/androidCancel'
import { Icon } from 'react-icons-kit'
import  Styles from "../styles/searchHeader.module.scss"
import MapContext from "../../../../widgets/state/context/MapContext";
import { getPlaces } from "../../../../widgets/common/utils";
import AppleMapkit from "../../../../widgets/common/appleMapKit";


// /* global google */
const HomeSearchMobile = ({ reset, setReset, setAddress, address, alldetails, setAllDetails, from }) => {
	const isBrowser = typeof window !== `undefined`
	const location = isBrowser&& window?.location
	const context = useContext(MapContext)
	const [value, setValue] = useState(address ? address : "")
	const [places, setPlaces] = useState([])
	const [suggestions, setSuggestons] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [showOptions, setShowOptions] = useState(false)
	const [allcitystate, setAllcitystate] = useState([]);
	const node = useRef()
	var search = location.search.split("&")
	let city = ""
	let state = ""
	let urladdress = ""

	const isMobile = isBrowser && (/Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) || window.innerWidth <= 800 || ((window.screen || {}).width || 0) <= 800)
	search.filter(item => {
		if ((item.includes("city"))) {
			city = (item.split('=')[1].replace(/%20/g, ' '))
		}
		if ((item.includes("state"))) {
			state = (item.split('=')[1].replace(/%20/g, ' '))
		}
		if ((item.includes("address"))) {
			urladdress = (item.split('=')[1].replace(/%20/g, ' '))
		}
	});

	/**
	 * Added this method to control the value set for the Location search text box.
	 * While navigating to Home page, the initial value should always be blank.
	 * @param {String} val
	 */
	const handleSetValue = (val) => {
		setValue(val);
		setAddress(val)
	};
	useEffect(() => {
		if (reset) {
			setValue("")
			setAddress("")
			setReset(false);
		} else if (!context.clearSearch && (context.mapDrag || location.search.includes("mapDrag=true"))) {
			context.setSearchedValue("Map Area")
			handleSetValue("Map Area")
		}
	}, [reset, location.search])

	const setData = async (city, state, latitude, longitude, method, display) => {
		context.setLoadingMask(true)
		if (city !== state) {
			// await axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=en`)
			await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${display}&key=${MAPS_KEY}`)
				.then(response => {
					const rec = response.data.results[0]?.geometry?.location
					const lat = Number(rec.lat)
					const lng = Number(rec.lng)
					let citydetail = "";
					let statedetail = ""
					response.data.results.forEach(function (element) {
						element.address_components.forEach(function (element2) {
							element2.types.forEach(function (element3) {
								switch (element3) {
									case 'administrative_area_level_1':
										statedetail = element2.long_name;
										break;
									case 'locality':
										citydetail = element2.long_name;
										break;
								}
							})
						});
					})
					if (!citydetail) {
						citydetail = display
					}
					setAllDetails({
						...alldetails, "city": citydetail,
						"state": statedetail,
						"address": display,
						"latitude": lat,
						"longitude": lng,
					})
					setAddress(display)
					var searchObj = {};
					searchObj.location = {};
					searchObj.location.lat = Number.isNaN(lat) ? undefined : lat;
					searchObj.location.lng = Number.isNaN(lng) ? undefined : lng;
					searchObj.searchedCity = citydetail;
					searchObj.city = citydetail;
					searchObj.state = statedetail;
					searchObj.address = display;
					var data = {
						city: searchObj.searchedCity === searchObj.state ? searchObj.state : searchObj.city, state: searchObj.state, latitude: searchObj.location.lat, longitude: searchObj.location.lng,
						page: 1, address: searchObj.address, zoom: null,
						ne_lat: null,
						ne_lng: null,
						sw_lat: null,
						sw_lng: null,
						mapDrag: null,
					}
					context.setLocation({
						lat,
						lng
					});
					
				})
		} else {
			const lat = Number(latitude)
			const lng = Number(longitude)
			setAllDetails({
				...alldetails, "city": city,
				"state": state,
				"address": display,
				"latitude": lat,
				"longitude": lng
			})
			setAddress(display)
			context.setLocation({
				lat,
				lng
			});
			var searchObj = {};
			searchObj.location = {};
			searchObj.location.lat = Number.isNaN(lat) ? undefined : lat;
			searchObj.location.lng = Number.isNaN(lng) ? undefined : lng;
			searchObj.searchedCity = city;
			searchObj.city = city;
			searchObj.state = state;
			searchObj.address = display;
			var data = {
				city: searchObj.searchedCity === searchObj.state ? '' : searchObj.city, state: searchObj.state, latitude: searchObj.location.lat, longitude: searchObj.location.lng, address: searchObj.address, page: 1,
				zoom: null,
				ne_lat: null,
				ne_lng: null,
				sw_lat: null,
				sw_lng: null,
				mapDrag: null,
			}
			context.setLoadingMask(true)

		}
	}
	const onChange = (v) => {
		if (v === "") {
			setAddress("")
			setSuggestons([])
			setShowOptions(false)
		} else {
			setShowOptions(true)
		}
		context.setMapDrag(false)
		setValue(v)
		if (v.trim()) {
			if (isBrowser) {
				if (v.trim().length >= 1 && v != "Map Area") {
					try {
						const lat = 44.4499802;
						const long = -103.7856;
						const coordinate = new window.mapkit.Coordinate(lat, long); // latitude, longitude
						const span = new window.mapkit.CoordinateSpan(.016, .016); // latitude delta, longitude delta
						const usaRegion = new window.mapkit.CoordinateRegion(coordinate, span);
						let search = new window.mapkit.Search({ region: usaRegion });
						search.autocomplete(v.trim(), (error, response) => {
							if (error) {
								return;
							}
							let data = null
							const places = getPlaces(response.results, v.trim());
							// if ("california".includes(v.toLowerCase())) {
							// 	data = {
							// 		areaCode: undefined,
							// 		city: "California",
							// 		citystate: "California",
							// 		display: "California",
							// 		latitude: 38.57699966430664,
							// 		longitude: -121.49490356445312,
							// 		postCode: undefined,
							// 		state: "California",
							// 	}
							// }
							// if ("port austin".includes(v.toLowerCase())) {
							// 	data = {
							// 		areaCode: undefined,
							// 		city: "Port Austin Township",
							// 		citystate: "Port Austin Township",
							// 		display: "Port Austin Township",
							// 		latitude: 44.0959644,
							// 		longitude: -83.0886771,
							// 		postCode: undefined,
							// 		state: "Port Austin Township",
							// 	}
							// }
							if (places.length === 0) {
							} else {
								if (data && v.length > 3) {
									places.splice(0, 0, data)
								}
								setSuggestons(places)
							}
						});
					} catch (exception) {
						setSuggestons([{
							citystate: "Record not found. Something went wrong"
						}])
						console.warn('Failed to get autocomplete', exception)
					}
				} else {
					setSuggestons([])
				}
			}
		} else {
			const result = suggestions.filter((data) => {
				let filteredValues = data.citystate.toLowerCase();
				return filteredValues.indexOf(
					v.toLowerCase()) !== -1
			})
			setSuggestons(result);
		}
	};

	const handleIconClick = () => {
		setValue("");
		setAddress("")
		setAllDetails({
			"city": null,
			"state": null,
			"address": null,
			"latitude": null,
			"longitude": null
		})
		setShowOptions(false)
	}
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleClickOutside = e => {
		if (node.current?.contains(e.target)) {
			return;
		}
		setShowOptions(false)
	};



	return (
		<div className={Styles.searchCont1} ref={node}>
			<input
				type="text"
				onKeyUp={(e) => {
					if (e.key === 'Enter' && value?.length >= 1) {
						e.target.blur()
						setValue(suggestions[0]?.citystate)
						setData(suggestions[0]?.city, suggestions[0]?.state, suggestions[0]?.latitude, suggestions[0]?.longitude, "click", suggestions[0]?.display)
						setShowOptions(false)
					}
				}}
				id="destination"
				autoComplete="off"
				value={value}
				onChange={(e) => onChange(e.target.value)}
				placeholder="Search Destination"
				onClick={() => { setShowOptions(true); onChange(value) }}
			/>
			{((location.pathname.includes("/search") && isMobile) || from === "home-mobile") ?
				<Icon icon={androidCancel} size={16} className={value ? "icon-cancel" : "icon-cancel-hide"} onClick={handleIconClick} /> : null}
			{showOptions && value != "Map Area" && <div className={(suggestions && suggestions?.length > 0 && suggestions[0]?.citystate?.includes("went wrong") || (suggestions?.length === 0 || value.length < 1)) ?
				`${Styles.SearchMobCont}` : `${Styles.SearchMobCont}  ${Styles.lCont}`} style={{ maxHeight: 320 }}>
				<div className={Styles.suggestionList}>
					{
						suggestions.map((item, i) => {
							return (
								<div key={i} >
									<p onClick={() => {
										setValue(item.citystate)
										setData(item.city, item.state, item.latitude, item.longitude, "click", item.display)
										setShowOptions(false)
									}}>{!item.citystate?.includes("went wrong") && <Icon icon={mapPin} className={Styles.mapPinStyle} size={18} />} {item.citystate}</p>
								</div>
							)
						})
					}
					{suggestions && suggestions.length === 0 &&
						<p style={{ textAlign: "center" }}>No Matches Found</p>
					}
				</div>
			</div>}
			<AppleMapkit />
		</div>
	)


}


export default HomeSearchMobile

