import React, { useState, useContext, useEffect, useRef } from "react"
import dayjs from "dayjs";
import Icon from "react-icons-kit";
import { minus } from "react-icons-kit/fa";
import { plus } from "react-icons-kit/fa";
import { search } from 'react-icons-kit/feather/search'
// import { search } from "react-icons-kit/fa";
import styles from "./styles/Styles.module.scss"
import { x } from "react-icons-kit/feather";
import AppleMapkit from "../../common/appleMapKit";
import { clearFalseyValues, getPlaces, prioritizeResults } from "../../common/utils";
import LocationSearch from "./components/LocationSearch";
import SearchDatePicker from "./components/SearchDatePicker";
import useLoadStyle from "../useLoadStyle";
import Fontstyle from "../Fonts";
import InitLocation from "../../../seo-widgets/guidefinder/components/common/initLocation";
import MapContext from "../../state/context/MapContext";
import { getMyAddress } from "../../common/storage";
import useClearLocation from "../../../seo-widgets/guidefinder/components/common/useClearLocation";
const initialState = {
    "address": null,
    "latitude": null,
    "longitude": null,
    "ne_lat": null,
    "ne_lng": null,
    "sw_lat": null,
    "sw_lng": null,
    "zoom": null,
    "mapDrag": false,
}

const Template3 = ({ xAppID }) => {
    useLoadStyle(xAppID);
    const isBrowser = typeof window !== `undefined`
    const context = useContext(MapContext)
    const node1 = useRef(null)
    const [view, setView] = useState(null);
    const [selection, setSelection] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [destination, setDestination] = useState(initialState);
    const [datafromHome, setDataFromHome] = useState(initialState);
    const [guests, setGuests] = useState(0);
    const [adult, setAdult] = useState(guests ? Number(guests) : 1);
    const [child, setChild] = useState(0);
    const [showMapKit, setShowMapKit] = useState(false)
    const [onEnter, setonEnter] = useState(false)
    const [suggestions, setSuggestons] = useState([])
    const  isLocationCleared =useClearLocation()

    const handleClear = () => {
        setSelectedDay(null)
        setAdult(1)
        setChild(0)
        setSelection("")
        setView(null)
        setDestination(initialState)
        setSuggestons([])
    }
    useEffect(() => {
        setTimeout(() => {
            setView(selection)
            // if (isBrowser && selection != "location") {
            //     document.body.removeAttribute("style")
            // } else if (isBrowser && selection === "location") {
            //     document.body.style.overflow = 'hidden';
            // }
        }, 250)
        return () => {
            setView(null)
        }
    }, [selection])

    useEffect(() => {
        if (context.searchedCity) {
            const result = getMyAddress();
            if (result && result.address) {
                setShowMapKit(true)
                const { address, lat, lng } = result;
                setDataFromHome({ ...datafromHome, "address": address, "latitude": lat, "longitude": lng, "page": 1 })
            }
        }
    }, [context.searchedCity])

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            handleClear()
        };
    }, []);
    const handleClickOutside = e => {
        if (node1.current?.contains(e.target)) {
            return
        }
        setSelection("")
        setView(null)
    };

    useEffect(() => {
        if ((adult + child) > 0) {
            setGuests(Number(adult) + Number(child))
        }
    }, [adult, child])
    useEffect(() => {
        if (onEnter) {
            handleSubmit()
        }
    }, [onEnter])
    useEffect(() => {
        if (datafromHome.address&& suggestions.length === 0) {
                setDestination(datafromHome)
                setTimeout(() => {
                    handleSuggest(datafromHome.address)
                }, 300);
        }
    }, [datafromHome.address])
    const groupCount = {
        "Adults": { count: adult, setCount: setAdult, min: 0 },
        "Children": { count: child, setCount: setChild, min: 0 },
    }
    const renderGuests = (title, subTitle) => {
        return (
            <div key={title} className={styles.guests__container}>
                <div className={styles.guests__text}>
                    <div>{title}</div>
                    <div>{subTitle}</div>
                </div>
                <div className={styles.guests__button}>
                    <button type="button" id="group-size-decrement"
                        className={styles.guests__button__icon}
                        disabled={groupCount[title].count === groupCount[title].min ? true : false}
                        onClick={() => groupCount[title].count > groupCount[title].min && groupCount[title].setCount(groupCount[title].count - 1)}>
                        <Icon icon={minus} />
                    </button>
                    <span className={styles.form__guest__count}>{groupCount[title].count}</span>
                    <button type="button" id="group-size-increment"
                        className={styles.guests__button__icon}
                        onClick={() => groupCount[title].setCount(groupCount[title].count + 1)}>
                        <Icon icon={plus} />
                    </button>
                </div>
            </div>
        )
    }
    const handleSubmit = () => {
        handleSearch()
        setonEnter(false)
    }
    const handleSearch = () => {
        const formData = {
            ...destination,
            startDate: selectedDay?dayjs(selectedDay, "YYYY-MM-DD").format("YYYY-MM-DD"):null,
            guest: guests
        }
        if(formData?.address === ""|| !formData?.address){
            formData.address = context.searchedCity;
        }
        let filterparam = { ...formData }
        const omitFalseyValues = clearFalseyValues(filterparam)
        const url = new URLSearchParams(omitFalseyValues);
        const stringifiedURLParams = url.toString();
        if (isBrowser && stringifiedURLParams) {
            window.location.href = `/search?${stringifiedURLParams}&page=1`
        }
        // setSearchedData(formData)
        // handleClear()
        // console.log(formData,"sss")
    }
    const handleSuggest = (value) => {
        if(value != destination?.address){
            setDestination(prev=>({ ...prev, "address": value }));    
        }
        if (value.trim()) {
            if (isBrowser) {
                if (value.trim().length > 1 && value !== "Map Area") {
                    try {
                        const myLocation = getMyAddress();
                        const lat = myLocation?.lat;
                        const long = myLocation?.lng; // Ensure this is the correct variable name
                        const coordinate = new window.mapkit.Coordinate(lat, long); // latitude, longitude
                        const span = new window.mapkit.CoordinateSpan(0.016, 0.016); // latitude delta, longitude delta
                        const usaRegion = new window.mapkit.CoordinateRegion(coordinate, span, );
                        const search = new window.mapkit.Search({ region: usaRegion });   
                        
                        search.autocomplete(value.trim(),async (error, response) => {
                        if (error) {
                            return;
                        }
                        let data = null
                        const places = getPlaces(response.results, value.trim());
                        if (places.length === 0) {
                        } else {
                            if (data && value.length > 3) {
                                places.splice(0, 0, data)
                            }
                            setSuggestons(places)
                        }
                    },{
                            // Add your additional options here
                            includePointsOfInterest:true,
                            includePhysicalFeatures:true,
                            includeAddresses: true,
                             includeQueries:false,
                          });
                    } 
                    
                     catch (exception) {
                        setSuggestons([{
                            citystate: "Record not found. Something went wrong"
                        }])
                        console.warn('Failed to get autocomplete', exception)
                    }
                } else {
                    setSuggestons([])
                }
            }
        } else {
            const result = suggestions.filter((data) => {
                let filteredValues = data.citystate.toLowerCase();
                return filteredValues.indexOf(
                    value.toLowerCase()) !== -1
            })
            setSuggestons(result);
        }
    }
    const next = () => {
        setSelection("group")
    }
    return (
        <>
            <Fontstyle xAppID={xAppID} />
            {
               isLocationCleared|| (!context.searchQuery?.latitude && !context.searchQuery?.longitude && (!context.searchQuery?.address)) ?
                    <InitLocation setSearchQuery={context.setSearchQuery}
                        searchQuery={context.searchQuery}
                        setMapLoading={() => { }} /> : null
            }
            <p className={`FontStyle ${styles.title}`}> Find Your Next Guided Trip</p>
            <div ref={node1}
                className={`FontStyle ${styles.header_search_layout_two_inactive}`}
            >
                <div className={styles.SubCont}>
                    <div
                        onClick={() => {
                            setSelection("location");
                            setShowMapKit(true)
                        }}
                        className={selection === "location" ? styles.LItem : styles.Litem1}
                    >
                        <label htmlFor="location" className={styles.Lheading}>
                            Location
                        </label>
                        <span className={styles.flexInput}>
                            <input
                                type="text"
                                // ref={inputElement}
                                onKeyUp={(e) => {
                                    e.preventDefault();
                                    if (e.key === 'Enter' && destination?.address?.length >= 1) {
                                        e.target.blur()
                                        setDestination({ ...destination, "address": suggestions[0]?.citystate, "latitude": suggestions[0]?.latitude, "longitude": suggestions[0]?.longitude, "page": 1 })
                                        setonEnter(true)
                                    }
                                }}
                                name="destination"
                                id="destination"
                                autoComplete="off"
                                value={destination?.address}
                                onChange={(e) => {e.preventDefault();handleSuggest(e.target.value)}}
                                placeholder="Where would you like to go"
                                className={styles.TextInput}
                            />
                            {destination?.address?.length > 1 && <Icon className={"iconColor"} icon={x} size={13} onClick={() => handleSuggest("")} />}
                        </span>
                    </div>
                    {view === "location" && destination && destination?.address?.length > 0 && suggestions?.length > 0 &&
                        <div className={`${styles.dateContainer}  ${styles.lCont}`}>
                            <LocationSearch destination={destination} suggestions={suggestions}
                                setDestination={setDestination} setSelection={setSelection} />
                        </div>
                    }
                </div>

                <div className={styles.LDivider}></div>

                <div className={styles.SubCont}>
                    <div
                        onClick={() => {
                            setSelection("date");
                        }}
                        className={selection === "date" ? styles.LItem : styles.Litem1}
                    >
                        <p className={styles.Lheading}>Date</p>
                        <p className={selectedDay ? styles.content : styles.contentDis}>
                            {selectedDay ? dayjs(selectedDay, "YYYY-MM-DD").format("MMMM DD, YYYY") : "Add Dates"}
                        </p>
                    </div>
                    {(view === "date") && (
                        <div className={styles.dateContainer}>
                            <SearchDatePicker
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                next={next}
                            />
                        </div>
                    )}
                </div>

                <div className={styles.LDivider}></div>
                <div className={styles.SubCont}>
                    <div onClick={() => { setSelection("group"); }}
                        className={selection === "group" ? `${styles.LItem} ${styles.guestOn}` : `${styles.Litem1} ${styles.guestOn}`}
                    >
                        <div >
                            <p className={styles.Lheading}>Group Size</p>
                            <p className={styles.content}>
                                {guests && Number(guests) > 1 ? `${guests} guests` : Number(guests) === 1 ? `${guests} guest` : "Add Guests"}
                            </p>
                        </div>

                    </div>
                    {(view === "group") && (
                        <div className={styles.dateContainer} style={{ width: "100%" }}>
                            {renderGuests("Adults", "Ages 13 or above")}
                            {renderGuests("Children", "Ages 2-12")}
                        </div>
                    )}
                </div>
                <div className={styles.LDivider}></div>
                {/* <div className={styles.SubCont}> */}
                <button className={`button ${styles.SearchBtn}`}
                    onClick={() => handleSubmit()}>
                    {"Search"}
                    <Icon icon={search} style={{ marginLeft: "5px" }} />
                </button>
                {/* </div> */}
                {showMapKit && <AppleMapkit />}

            </div>
        </>
    )
}
export default Template3
