export const filterTripType = () => {
	return (
		[
			{ key: 'Shore', value: 'Shore', label: 'Shore Fishing', "checked": false },
			{ key: 'Inshore', value: 'Inshore', label: 'Inshore Fishing', "checked": false },
			{ key: 'Offshore', value: 'Offshore', label: 'Offshore Fishing', "checked": false },
			{ key: 'Wrecks', value: 'Wrecks', label: 'Wrecks/Reefs Fishing', "checked": false },
			{ key: 'Flats', value: 'Flats', label: 'Flats/Shallows Fishing', "checked": false },
			{ key: 'BackCountry', value: 'BackCountry', label: 'Backcountry Fishing', "checked": false },
			{ key: 'Lake', value: 'Lake', label: 'Lakes/Reservoirs/Ponds Fishing', "checked": false },
			{ key: 'River', value: 'River', label: 'River Fishing', "checked": false },
		]

	)
}
export const TripTime = () => {
	return (
		[{ "id": 1, "label": "Morning - (Starts before 12 pm)", "value": "Morning", checked: false },
		{ "id": 2, "label": "Afternoon - (Starts after 12 pm)", "value": "Afternoon", checked: false },
		{ "id": 3, "label": "Evening - (Starts after 5 pm)", "value": "Evening", checked: false }
		]

	)
}
export const Reviews = () => {
	return (
		[
			{ "id": 1, "label": "4+ stars", "value": "4", checked: false },
		]
	)
}
export const Benefits = () => {
	return (
		[
			{ "id": 1, "label": "Free Cancellation", "value": "free-cancellation", checked: false },
			{ "id": 3, "label": "Good with Kids", "value": "good-with-kids", checked: false },
			{ "id": 2, "label": "Experienced Angler", "value": "experienced-angler", checked: false },
			{ "id": 4, "label": "Good for Beginners", "value": "good-for-beginners", checked: false },
			{ "id": 5, "label": "Experienced Hunter", "value": "experienced-hunter", checked: false },
		]
	)
}
export const ActivityType = () => {
	return (
		[
			{ "id": 1, "label": "All Trips", "value": "alltrips", checked: false },
			{ "id": 3, "label": "Trending", "value": "trending", checked: false },
			{ "id": 2, "label": "Fishing", "value": "fishing", checked: false },
			{ "id": 3, "label": "Hunting", "value": "hunting", checked: false },
		]
	)
}

export const boatDetailsGuests = (rec) => {
	// let boatName = rec.boat_name && rec.boat_name != "" ? (`${rec.boat_name} -`) : ""
	// let boatLength = rec.max_boat_length ? (`${rec.max_boat_length}ft -`) : ""
	let guest = ""
	if (rec.max_boat_guests) {
		if (rec.max_boat_guests === 1) {
			guest = `${rec.max_boat_guests} guest`
		} else if (rec.max_boat_guests > 1) {
			guest = `${rec.max_boat_guests} guests`
		} else {
			guest = ""
		}
	}
	return ` ${guest}`
}
export const displayMoney = (data, n) => {
	var str
	if (Number.isInteger(data) || n === "str") {
		str = Number(data).toLocaleString(undefined);
	} else {
		str = Number(data).toLocaleString(undefined, { minimumFractionDigits: 2 });
	}
	return str
}

export const getDiff = (date, text) => {
	var a = new Date();
	var b = new Date(date);
	var years = a.getFullYear() - b.getFullYear()
	var months = b.getMonth()
	if (text) {
		return years === 1 ? `${years} Year` : years > 1 ? `${years} Years` : years === 0 && months > 1 ? `${months} Months` : `${months} Month`
	} else {
		return years === 1 ? `${years} Yr` : years > 1 ? `${years} Yrs` : years === 0 && months > 1 ? `${months} Months` : `${months} Month`
	}
}
export const Expertise = () => {
	return (
		[
			{ id: "s1", label: "Master Guide" },
			{ id: "s9", label: "Good with kids" },
			{ id: "s2", label: "Technical Fishing" },
			{ id: "s15", label: "Live Bait" },
			{ id: "s7", label: "Good with New Anglers" },
			{ id: "s10", label: "Nature / Wildlife Views" },
			{ id: "s6", label: "Good with Large Groups" },
			{ id: "s8", label: "Good with Families" },
			{ id: "s14", label: "I Tie My Own Flies" },
			{ id: "s11", label: "Saltwater Fishing" },
			{ id: "s13", label: "Bass Fishing" },
			{ id: "s4", label: "Deep Sea Fishing" },
			{ id: "s12", label: "Freshwater Fishing" },
			{ id: "s3", label: "Fly Fishing" },
			{ id: "s5", label: "Drift Fishing" },
		]
	)
}
export const getCorrectedImagePath = (image) => {
	if (image) {
		var correctPath = image.charAt(0) === '/' ? image.slice(1) : image
		//var returnPath = correctPath.replace(/\s/g, '%20')
		//console.log(correctPath)
		return correctPath;//correctPath.replace(/\s/g, '%20')
	}
}