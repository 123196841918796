import React, { useState, useEffect, useRef } from 'react'
import { CardMedia } from '@mui/material';

export default (props) => {
    const [visible, setVisible] = useState(props.visible);
    const placeholderRef = useRef(null);
    useEffect(() => {

        if (!visible && placeholderRef.current) {
            const observer = new IntersectionObserver(([{ intersectionRatio }]) => {
                if (intersectionRatio > 0) {
                    setVisible(true);
                }
            });
            observer.observe(placeholderRef.current);
            return () => observer.disconnect();
        }
    }, [visible, placeholderRef]);

    return (visible
        ?
        <CardMedia
            component='img'
            image={props.image}
            alt={props.alt}
            className={props.className}
        />
        :
        <div className={props.className} style={{ backgroundColor: '#EEE' }} aria-label={props.alt} ref={placeholderRef} />
    );
};