import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    container: {
        display: 'block',
        margin: 0,
        cursor: 'pointer'
    },
    img: {
        maxWidth: '100%',
        height: 'auto',
        verticalAlign: 'top',
        borderRadius: 5
    },
    video: {
        maxWidth: '100%',
        height: '120px',
        verticalAlign: 'top',
        borderRadius: 5,
        '@media screen and (max-width: 568px)': {
            height: '100px',
        },
    },
    caption: {
        display: 'block',
        margin: '.2em 0 0',
        fontWeight: 'bold',
        fontSize: 12
    }
}));
