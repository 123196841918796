const isBrowser = typeof window !== `undefined`

export const setSearchedData = search => (window.localStorage.searchedData = JSON.stringify(search))
const getAddress = () =>
	window.localStorage.currentlocation
		? JSON.parse(window.localStorage.currentlocation)
		: null




const getSearchData = () =>
	window.localStorage.searchedData
		? JSON.parse(window.localStorage.searchedData)
		: {}


export const getSearchedData = () => isBrowser && getSearchData()
export const setMyAddress = address => (window.localStorage.currentlocation = JSON.stringify(address))
export const getMyAddress = () => isBrowser && getAddress()