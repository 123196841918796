import React, { useState } from 'react'
import ReactStars from 'react-stars'
import { Typography, Grid } from '@mui/material';
import moment from 'moment'
import ReactPlayer from 'react-player'
import FsLightBox from '@guidesly/fslightbox-react-v2'
import playIcon from '../../assets/Play.png';
import Comment from './comment'
import './style.scss'
import GuideReply from './guide-reply-classic';
import { getNameInitials } from './data'

const avatar = `https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/avatar2.png`

const getName = (name) => {
    var fullName = name.split(' ')
    if (fullName[1]) {
        return `${fullName[0]} ${fullName[1][0]}`
    }
    return `${fullName[0]}`
}

const MobileReviews = ({ reviews, classes, cloudfront, guideDetails }) => {
    const [gallery, setGallery] = useState([])
    const [photoIndex, setPhotoIndex] = useState(0)

    const handleLightBox = (index, photosArray) => {
        setGallery(photosArray)
        setPhotoIndex(index)
    }

    const sources = gallery.map(image => `${cloudfront}${image.mediapath}`)
    const types = gallery.map(image => image.mediadescription === "Trip Rating Videos" ? 'video' : 'image')
    const captions = gallery.map(image => image.mediacaption)

    return (
        reviews.map(item => {
            const profileImg = item.is_google_review ? item.customer_google_profile_url : `${cloudfront}${item.profilepic}`;
            const firstName = item.customer_firstname ? item.customer_firstname : '';
            const lastName = item.customer_lastname ? item.customer_lastname : '';
            const customerName = item.customer ? getName(item.customer) : '';
            const name = (firstName || lastName) ? (firstName + " " + lastName) : customerName;

            const name_trimmed = name.trim();
            const name_len = name_trimmed.split(/[\t\s]+/).length - 1;
            var photosArray = []
            if (item.media_list) {
                item.media_list.map((image, i) => {
                    if (image.mediadescription === "Trip Rating" || image.mediadescription === "Trip Rating Videos") {
                        photosArray.push(image)
                    }
                })
            }

            return <>
                <Grid container className={classes.container}>
                    <div className={classes.displayFlex}>
                        {(item.profilepic || item.customer_google_profile_url) ?
                            <img src={profileImg} className={`${classes.img}`} alt={'image'} referrerpolicy="no-referrer"></img>
                            :
                            <div className={`${classes.img}`} style={{
                                background: "#224041", backgroundRepeat: "no-repeat", backgroundPosition: "top", backgroundSize: "cover", textAlign: "center", borderRadius: '5px'
                            }}>
                                <span className={'head-shot'} >
                                    {getNameInitials(firstName, lastName, name, name_trimmed, name_len, item.guest_name)}
                                </span>
                            </div>
                        }
                        <div>
                            <Typography variant='body2' className={`${classes.subtext} ${classes.name}`}>{name}</Typography>
                            <ReactStars
                                count={5}
                                size={16}
                                edit={false}
                                value={item.overall}
                                color2={'rgb(98, 157, 3)'}
                                char={'★'}
                            />
                        </div>
                        {item.created_at && <Typography component={'span'} variant='body2' className={classes.subtext}>
                            <div className={classes.reviewed}>
                                {`${moment(item.created_at).format('MMMM D, YYYY')}`}
                            </div>
                        </Typography>}
                    </div>
                    <Comment item={item} classes={classes} />
                    <div className={`guide_img_container reviewImg`}>
                        {
                            photosArray !== null && photosArray !== undefined && photosArray.length !== 0 ?
                                <div className={'guide_img_list'}>
                                    {photosArray.slice(0, 4).map((pic, i) => {
                                        if (pic.mediadescription === "Trip Rating" || pic.mediadescription === "Trip Rating Videos") {
                                            return (
                                                <Typography
                                                    component={'div'}
                                                    key={i}
                                                    className={(i === 3 && photosArray.length > 4) ? 'overlay' : null}
                                                    onClick={() => handleLightBox(i, photosArray)}
                                                >
                                                    {
                                                        pic.mediadescription === "Trip Rating Videos" ?
                                                            // <ReactPlayer
                                                            //     url={`${cloudfront}${pic.mediapath}`}
                                                            //     width="100%"
                                                            //     height="80px"
                                                            //     style={{ backgroundColor: "#000", cursor: "pointer" }}
                                                            //     light
                                                            //     playIcon={<img />}
                                                            //     config={{
                                                            //         file: {
                                                            //             forceVideo: false,
                                                            //             attributes: {
                                                            //                 playsInline: false,
                                                            //                 controls: false,
                                                            //                 // crossOrigin: "Anonymous"
                                                            //             }
                                                            //         },
                                                            //     }}
                                                            // /> 
                                                            <div className='video-overlay-container'>
                                                                <div className='video-overlay'>
                                                                    <img src={'https://da9mvpu5fnhic.cloudfront.net/images/Icons/PNG/Play.png'}/>
                                                                </div>
                                                                <video
                                                                    width="100px"
                                                                    height="80px"
                                                                    >
                                                                    <source
                                                                        src={`${cloudfront}${pic.mediapath}`}
                                                                    ></source>
                                                                </video>
                                                            </div>
                                                            :
                                                            <div style={{
                                                                width: "100%", height: "80px", backgroundImage: `url('${cloudfront}${pic.mediapath}')`,
                                                                backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", backgroundColor: "#000", cursor: "pointer"
                                                            }}></div>
                                                    }

                                                </Typography>
                                            )
                                        }
                                    })}
                                </div>
                                : null
                        }
                    </div>
                    {item.reply_comment && <GuideReply item={item} classes={classes} cloudfront={cloudfront} guideDetails={guideDetails} />}
                </Grid>
                {gallery.length > 0 &&
                    <FsLightBox
                        toggler={gallery.length}
                        types={types}
                        sources={sources}
                        captions={captions}
                        slide={photoIndex + 1}
                        disableLocalStorage
                        onClose={() => setGallery([])}
                        openOnMount
                    />
                }
            </>
        })
    )
}
export default MobileReviews
