import React, { useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import DayPicker from '../../../common/daypicker/DayPicker';
import { getMaxCapacity } from '../../../utils/guide-details';
import PersonalDetails from '../../../common/personal-details/PersonalDetailsStripeProvider';
import Confirmation from '../../../common/confirmation/Confirmation';
import TripCharge from '../../../common/TripCharge';
import GuestCount from '../../../common/filters/GuestCount'
import TripDetailsContext from '../../../state/context/TripDetailsContext';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const InfoIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/black_info.png'
import 'react-day-picker/dist/style.css';

import {
  getRateCards,
  getAvailability,
  getInitialRateCards,
  TripType,
  getAvailabilityBasedOnSelectedRateCard
} from '../../../utils/guide-details';
import { get } from '../../../utils/axiosHelper';
import {
  displayMoney,
  getTotalCharge,
} from '../../../utils/payment';
import useStyles from './styles';
import convertTo12Hour, { getDuration, getTimeFormat } from '../../../utils/time';
import { TYPES } from '../../../common/description';

export default function TripSummaryCalendar({ id, xAppID, center, hideGuestPreference }) {
  const classes = useStyles();
  const [showCalendar, setShowCalendar] = useState(null)
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const isNarrow = /Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) || window.innerWidth <= 834 || ((screen || {}).width || 0) <= 834

  const tripDetailsContext = useContext(TripDetailsContext);
  const {
    step,
    setStep,
    fromMonth,
    toMonth,
    modifiersStyles,
    modifiers,
    selectedDay,
    setSelectedDay,
    selectedHour,
    selectedRateCard,
    guestCount,
    setGuestCount,
    setModifiers,
    setAvailableRateCards,
    setAvailableSchedules,
    setMeals,
    availableRateCards: stateRateCards,
    availableSchedules,
    setRateCards,
    setAvailableMeals,
    setAvailableMeetingPoints,
    setAvailableWaterBodies,
    setMeeetingPoint,
    setPreferedWaterbody,
    setSecondFavoriteWaterbody,
    setGuideName,
    setSelectedRateCard,
    setSelectedHour,
    firstName,
    lastName,
    email,
    phoneNumber,
    rateCards,
    addOnFees,
    setAddOnFees,
    setIsGuestCountMaxed
  } = tripDetailsContext;

  let total;
  let chargeToday;
  let chargeOnDayOfTrip;

  if (selectedHour.rate) {
    total = getTotalCharge(
      selectedRateCard,
      guestCount,
      selectedHour.rate,
      addOnFees
    );

    chargeToday = (total * 0.20).toFixed(2);

    chargeOnDayOfTrip = total - chargeToday;
  }

  const instantBook = (card, hour) => {
    if (!hour.rate || card.id !== selectedRateCard.id) {
      setSelectedHour(card.rates[0]);
      setSelectedRateCard(card);
      // setStep('personal-details');
      return;
    }
    // setStep('personal-details');
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (document.querySelector('.wsb-htmlsnippet-element')) {
        document.querySelectorAll('.wsb-htmlsnippet-element').forEach(item => item.style.overflow = "scroll")
      }
    }
  }, [])

  useEffect(() => {
    if (guestCount) {
      const rateCards = selectedDay && availableSchedules.length ?
        getRateCards(
          JSON.parse(JSON.stringify(stateRateCards)),
          availableSchedules.find(
            (schedule) =>
              schedule.date === moment(selectedDay).format('YYYY-MM-DD'),
          )
        ) : stateRateCards;
      setRateCards(
        rateCards.filter(item => guestCount <= item.maximum && item.rates)
      );
      if (rateCards.length === 1 && !rateCards[0].disabled) {
        setSelectedRateCard(rateCards[0])
      }
    }
  }, [guestCount])

  useEffect(() => {
    const startDate = moment(fromMonth).format('YYYY-MM-DD');
    const endDate = moment(toMonth).format('YYYY-MM-DD');
    const url = `guide/getGBSAvailability_v2?id=${id}&start_date=${startDate}&end_date=${endDate}`;
    get(url).then((res) => {
      if (res.data) {
        const { scheduleDisable, availability } = getAvailability(
          res.data.schedule,
        );
        setModifiers({
          ...modifiers,
          availability,
          scheduleDisable,
          schedule: res.data.schedule,
        });

        setAvailableRateCards(res.data.ratecards);
        if (res.data.schedule.length > 0) {
          setAvailableSchedules(res.data.schedule);
          const selectedInitialDay = res.data.schedule.find(
            (day) => day.date === moment(new Date()).format('YYYY-MM-DD'),
          );
          const rateCards = getInitialRateCards(res.data.ratecards, selectedInitialDay, true);
          setModifiers({
            ...modifiers,
            availability,
            scheduleDisable,
            schedule: res.data.schedule,
          });
          setRateCards(
            rateCards,
          );
          if (rateCards.length === 1) {
            setSelectedRateCard(rateCards[0])
          }
          setSelectedDay(
            ''
          );
        }
        if (res.data.meals) {
          const select = { guid: 'select', name: "Select" };
          setMeals(select);
          setAvailableMeals([select, ...res.data.meals]);
        }
        if (res.data.poi) {
          setMeeetingPoint(res.data.poi[0]);
          setAvailableMeetingPoints(res.data.poi);
        }
        if (res.data.rivers) {
          setAvailableWaterBodies(res.data.rivers);
          setPreferedWaterbody(res.data.rivers[0]);
          setSecondFavoriteWaterbody(res.data.rivers[0]);
        }
        setGuideName(res.data.guidename);
        // setAddOnFees(res.data.guide_addon_fees || []);
      }
    });
  }, []);

  useEffect(() => {
    const add_on_fees = (selectedRateCard.addon_fees || []).filter(item => item.active)
    setAddOnFees(add_on_fees)
  }, [selectedRateCard])

  const handleDayClick = (e, dayPickerModifiers = {}) => {
    e.setHours(0, 0, 0, 0);
    setSelectedRateCard({});
    setSelectedHour({});

    if (dayPickerModifiers.availability) {
      setSelectedDay(e);
      const selectedSchedule = availableSchedules.find(
        (schedule) =>
          schedule.date === moment(e).format('YYYY-MM-DD'),
      );
      const rateCards1 = getRateCards(JSON.parse(JSON.stringify(stateRateCards)), selectedSchedule)
      setRateCards(
        rateCards1.filter(item => guestCount <= item.maximum && item.rates)
      );
      if (showCalendar) {
        const { rateCard, selectedHour, hourcard } = showCalendar;
        if (hourcard) {
          setSelectedRateCard(rateCard)
          setSelectedHour(selectedHour)
          setShowCalendar(null)
        } else {
          setSelectedRateCard(rateCard)
          if (selectedHour) {
            setSelectedHour(selectedHour)
          }
        }
        const completeRateCard = rateCards.find(rate => rate.id === rateCard.id)
        if (!completeRateCard.disabled && !completeRateCard.rates[0].disabled) {
          instantBook(rateCard, selectedHour)
        }
        if (rateCards.length === 1) {
          setSelectedRateCard(rateCards[0])
        }
      }
      setExpanded('triptype')
    }
    setShowCalendar(null)
    if (
      dayPickerModifiers.scheduleDisable ||
      dayPickerModifiers.unavailablity
    ) {
      return null;
    }
    return null;
  };

  const capacity = selectedRateCard.id ? selectedRateCard.maximum : Math.max(...rateCards.map(item => item.maximum || 0))

  const setGuestCountHandler = (count) => {
    setGuestCount(count)
  }

  const activeBtn = !selectedDay || Object.keys(selectedRateCard).length === 0 || Object.keys(selectedHour).length === 0
  
  const Step = {
    'trip-details': (
      <Grid item sm={12} xs={12} md={12} ld={12}>
        <Grid container style={{ borderRadius: 5 }} className={classes.rateCardContainer}>
          <Grid item sm={12} xs={12} md={6} ld={6} style={{ borderRight: '1px solid grey' }} >
            <Typography style={{ padding: '5%' }}>Trip Summary   </Typography>
          </Grid>
          <Grid style={{ padding: '2%' }} item sm={12} xs={12} md={6} ld={6} >
            <GuestCount guestLabel={classes.guestLabel} setIsGuestCountMaxed={setIsGuestCountMaxed} capacity={capacity} className={`${classes.filter} ${classes.filter1}`} setGuestCount={setGuestCountHandler} guestCount={guestCount} />
          </Grid>

          <Accordion style={{ width: '100%', margin: 0 }} expanded={expanded === 'tripdate'} onChange={handleChange('tripdate')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              classes={{
                content: classes.block
              }}
              style={{ border: expanded === 'tripdate' ? '2px solid #086edc' : 'none' }}
            >
              <Typography className={classes.secondaryHeading}>Trip Date</Typography>
              <Typography className={classes.heading}>{selectedDay ? moment(selectedDay).format('MM/DD/YYYY') : 'Add Date'}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ border: expanded === 'tripdate' ? '2px solid #086edc' : 'none' }}>
              <div style={{ width: '100%' }}>
                <DayPicker
                  month={selectedDay}
                  fromMonth={fromMonth}
                  toMonth={toMonth}
                  modifiers={modifiers}
                  modifiersStyles={modifiersStyles}
                  className={classes.fullWidth}
                  selectedDays={selectedDay}
                  onDayClick={(e, availModifiers) => {
                    handleDayClick(e, availModifiers)
                    if (availModifiers.availability) {
                      if (!selectedRateCard.id) {
                        setExpanded('triptype')
                      } else {
                        setExpanded('triptime')
                      }
                    }
                  }}
                />
                <div className={classes.btnClear}>
                  <button
                    className={`btn--blue--solid`}
                    disabled={!selectedDay}
                    onClick={() => { setSelectedDay('') }}
                    style={{ padding: "2px 7px", fontSize: "14px" }}
                  >
                    Clear Date
              </button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ width: '100%', margin: 0 }} expanded={expanded === 'triptype'} onChange={handleChange('triptype')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              classes={{
                content: classes.block
              }}
              style={{ border: expanded === 'triptype' ? '2px solid #086edc' : 'none' }}
            >
              <Typography className={classes.secondaryHeading}>Trip Type</Typography>
              <Typography className={classes.heading}>{selectedRateCard.title ? selectedRateCard.title : 'Add Trip'}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ border: expanded === 'triptype' ? '2px solid #086edc' : 'none' }}>
              <div style={{ width: '100%' }}>
                {(selectedDay ? rateCards.filter(item => !item.disabled) : rateCards).map((rateCard, i) => {
                  return (
                    <label
                      key={i}
                      style={{
                        width: "100%",
                        margin: "5px 0px"
                      }}
                      key={`available-trip-${rateCard.id}`}
                    >
                      <Grid container className={`${rateCard.disabled ? '' : ''} innercontainer`}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          lg={12}
                          md={12}
                          style={{
                            padding: "8px 5px", display: "flex",
                            width: "100%",
                            margin: "5px 0px"
                          }}
                        >
                          <svg
                            className={selectedRateCard.id === rateCard.id ? 'bookNowBtn' : 'white'}
                            onClick={() => {
                              setSelectedRateCard(rateCard);
                              setSelectedHour({});
                              if (selectedDay) {
                                setExpanded('triptime')
                              } else {
                                setExpanded('tripdate')
                              }
                              const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: {}, rateCard })
                              setModifiers({
                                ...modifiers,
                                availability,
                                scheduleDisable,
                              });
                            }}
                            style={{
                              margin: 0,
                              padding: 0,
                              borderRadius: 0,
                              marginRight: 10,
                              minWidth: 20,
                              height: 20, width: 20, border: '1px solid grey'
                            }} width="20" height="20">
                            <rect />
                          </svg>
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedRateCard(rateCard);
                              setSelectedHour({});
                              if (selectedDay) {
                                setExpanded('triptime')
                              } else {
                                setExpanded('tripdate')
                              }
                              const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: {}, rateCard })
                              setModifiers({
                                ...modifiers,
                                availability,
                                scheduleDisable,
                              });
                            }}
                          >
                            <Typography style={{ fontWeight: 'bolder' }} variant="body2" component="div" className="Head">
                              {rateCard.title}
                            </Typography>
                            {
                              rateCard.ratetype  ?
                              <Typography variant="caption" component="div" className="dsctext">
                                {TripType().find(item => item.key.toLowerCase() === rateCard.ratetype.toLowerCase())?.label} <span style={{ position: 'relative', top: 3 }}>
                                  {
                                    rateCard.activity_type === "fishing" ?
                                      <Tooltip classes={{ tooltip: 'guidesly-tooltip' }} enterTouchDelay={0} title={TYPES[rateCard.ratetype.toLowerCase()]}>
                                        <span><img src={InfoIcon} width={15} height={15} /></span>
                                      </Tooltip> : null
                                  }
                                </span>
                              </Typography> : null
                            }
                            {rateCard.maximum && !rateCard.has_boat && !rateCard?.is_shared_trip ? <Typography variant="caption" component="div" className="dsctext"> {rateCard.maximum} guest capacity</Typography> : null}
                            {rateCard.has_boat && <Typography variant="caption" component="div" className="dsctext">
                              {rateCard.length}{rateCard.length?"' ":null}{(rateCard.manufacturer_name !="Other" && rateCard.manufacturer_name !="Others")?rateCard.manufacturer_name: rateCard.other_manufacturer}{' -'} {getMaxCapacity(rateCard)} guest capacity
                    </Typography>}
                          </span>
                        </Grid>
                      </Grid>
                    </label>
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ width: '100%', margin: 0 }} expanded={expanded === 'triptime'} onChange={handleChange('triptime')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              classes={{
                content: classes.block
              }}
              style={{ border: expanded === 'triptime' ? '2px solid #086edc' : 'none' }}
            >
              <Typography className={classes.secondaryHeading}>Trip Time</Typography>
              <Typography className={classes.heading}>{selectedHour.start_time ? `${convertTo12Hour(selectedHour.start_time)} ${getDuration(selectedHour)} for $${displayMoney(selectedHour.rate)}` : 'Add Start Time'}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ border: expanded === 'triptime' ? '2px solid #086edc' : 'none' }}>
              <div style={{ width: '100%' }}>
                {selectedRateCard?.rates ? selectedRateCard?.rates.filter(item => !item.disabled).map((item, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: 'flex', borderBottom: "1px solid #42642f",
                        width: "100%",
                        margin: "5px 0px", padding: "10px 5px", justifyContent: 'space-between'
                      }}
                      className="innercontainer"
                    >
                      <div style={{ display: 'flex' }}>
                        <svg className={selectedHour.id === item.id ? 'bookNowBtn' : 'white'}
                          onClick={() => {
                            if (selectedDay) {
                              setSelectedHour(item)
                              setExpanded('')
                            } else {
                              const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item, rateCard: selectedRateCard })
                              setModifiers({
                                ...modifiers,
                                availability,
                                scheduleDisable
                              });
                              setSelectedHour(item)
                              setShowCalendar({ rateCard: selectedRateCard, selectedHour: item, hourcard: true })
                              setExpanded('tripdate')
                            }
                          }}
                          style={{
                            margin: 0,
                            padding: 0,
                            borderRadius: 0,
                            marginRight: 10,
                            height: 20, width: 20, border: '1px solid grey'
                          }} width="20" height="20">
                          <rect />
                        </svg>
                        <Typography
                          onClick={() => {
                            if (selectedDay) {
                              setSelectedHour(item)
                              setExpanded('')
                            } else {
                              const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item, rateCard: selectedRateCard })
                              setModifiers({
                                ...modifiers,
                                availability,
                                scheduleDisable
                              });
                              setSelectedHour(item)
                              setShowCalendar({ rateCard: selectedRateCard, selectedHour: item, hourcard: true })
                              setExpanded('tripdate')
                            }
                          }}
                          style={{ cursor: 'pointer' }}
                          component="div" variant="body2">
                          {convertTo12Hour(item.start_time)} {' for '} {getDuration(item)}
                        </Typography>
                      </div>
                      <Typography
                        onClick={() => {
                          if (selectedDay) {
                            setSelectedHour(item)
                            setExpanded('')
                          } else {
                            const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item, rateCard: selectedRateCard })
                            setModifiers({
                              ...modifiers,
                              availability,
                              scheduleDisable
                            });
                            setSelectedHour(item)
                            setShowCalendar({ rateCard: selectedRateCard, selectedHour: item, hourcard: true })
                            setExpanded('tripdate')
                          }
                        }}
                        style={{ cursor: 'pointer', fontWeight: 'bolder' }}
                        component="div" variant="body2">
                        ${displayMoney(item.rate)}
                      </Typography>
                    </div>
                  );
                }) : (selectedDay ? rateCards.filter(item => !item.disabled) : rateCards).map(rateCard => rateCard.rates?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: 'flex', borderBottom: "1px solid #42642f",
                        width: "100%",
                        margin: "5px 0px", padding: "10px 5px", justifyContent: 'space-between'
                      }}
                      className="innercontainer"
                    >
                      <div style={{ display: 'flex' }}>
                        <svg className={selectedHour.id === item.id ? 'bookNowBtn' : 'white'}
                          onClick={() => {
                            if (selectedDay) {
                              setSelectedHour(item)
                              setExpanded('')
                            } else {
                              const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item, rateCard })
                              setModifiers({
                                ...modifiers,
                                availability,
                                scheduleDisable
                              });
                              setSelectedHour(item)
                              setShowCalendar({ rateCard, selectedHour: item, hourcard: true })
                              setExpanded('tripdate')
                            }
                          }}
                          style={{
                            margin: 0,
                            padding: 0,
                            borderRadius: 0,
                            marginRight: 10,
                            height: 20, width: 20, border: '1px solid grey'
                          }} width="20" height="20">
                          <rect />
                        </svg>
                        <Typography
                          onClick={() => {
                            if (selectedDay) {
                              setSelectedHour(item)
                              setSelectedRateCard(rateCard)
                              setExpanded('')
                            } else {
                              const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item, rateCard })
                              setModifiers({
                                ...modifiers,
                                availability,
                                scheduleDisable
                              });
                              setSelectedHour(item)
                              setSelectedRateCard(rateCard)
                              setShowCalendar({ rateCard, selectedHour: item, hourcard: true })
                              setExpanded('tripdate')
                            }
                          }}
                          style={{ cursor: 'pointer' }}
                          component="div" variant="body2">
                          {convertTo12Hour(item.start_time)} {' for '} {getDuration(item)}
                        </Typography>
                      </div>
                      <Typography
                        onClick={() => {
                          if (selectedDay) {
                            setSelectedHour(item)
                            setExpanded('')
                          } else {
                            const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item, rateCard: selectedRateCard })
                            setModifiers({
                              ...modifiers,
                              availability,
                              scheduleDisable
                            });
                            setSelectedHour(item)
                            setShowCalendar({ rateCard: selectedRateCard, selectedHour: item, hourcard: true })
                            setExpanded('tripdate')
                          }
                        }}
                        style={{ cursor: 'pointer', fontWeight: 'bolder' }}
                        component="div" variant="body2">
                        ${displayMoney(item.rate)}
                      </Typography>
                    </div>
                  );
                }))}
                {/* {availability.length === 0 ? (
                  <p className="norc">No Trips Available</p>
                ) : null} */}
              </div>
            </AccordionDetails>
          </Accordion>

          <Grid style={{ padding: '0 16px', width: '100%' }}>
            <TripCharge from='summary' />
            <button
              disabled={activeBtn}
              onClick={() => setStep('personal-details')}
              className={'bookNowBtn'}
              style={{ marginTop: 10, width: '100%', borderRadius: 5, marginBottom: 10, marginLeft: 0 }}
            >
              Continue to Book
            </button>
          </Grid>

        </Grid>
      </Grid>
    ),
    'personal-details': (
      <PersonalDetails
        previous={() => setStep('trip-details')}
        next={() => setStep('trip-summary')}
        hideGuestPreference={hideGuestPreference}
        xAppID={xAppID}
        isLCal
        isNarrow={isNarrow}
        setGuestCountHandler={setGuestCount}
      />
    ),
  };

  if (step === 'confirmation') {
    return (
      <Card className={classes.maxRoot}>
        <CardContent>
          <Confirmation isNarrow={isNarrow}/>
        </CardContent>
      </Card>
    );
  }

  let disabled = true;

  if (step === 'trip-details')
    disabled = !(total && chargeToday && chargeOnDayOfTrip && selectedDay);
  if (step === 'personal-details')
    disabled = !(firstName && lastName && email && phoneNumber);

  return (
    <Card className={`${classes.root} ${step !== 'trip-details' && classes.maxRoot} ${center && classes.margin}`}>
      <CardContent classes={{ root: classes.noPadding }}>
        {Step[step]}
      </CardContent>
    </Card>
  );
}
