import React, { useContext, useEffect, useState, } from "react"
import { Card } from "react-bootstrap"
import  Styles from "./styles/guideList.module.scss"
import GuideMobDetail from "./GuideMobDetail";
import GuideCardsMobNew from "./GuideCardsMobNew";
// import { GetAllGuidesFromStrapi, getGuideDetailsLink } from "../../utils";
import MapContext from "../../../widgets/state/context/MapContext";
import { getGuideDetailsLink } from "../../../widgets/common/utils"
const GuideCardMobile = ({ handleCard, getGuide, item,  index, customStyles = {}, allGuides, from , xAppID}) => {
	const isBrowser = typeof window !== `undefined`
	const context = useContext(MapContext)
	const page = from && (from === "article" || from === "fish") ? true : false
	const [allguides, setAllGuides] = useState(allGuides)

	useEffect(()=>{
		setAllGuides(allGuides)
	},[allGuides])
	// const allGuides = GetAllGuidesFromStrapi()
	const SelectedDateAvail = item.availability_details?.selectedDate_available || item.availability_details?.is_available;
	return (
		<div key={index} className={`${customStyles.container} ${Styles.Pcontainer} FontStyle`} id={index}>
			{isBrowser && window.matchMedia("only screen and (min-width: 768px)").matches &&
				<a target="_blank" href={SelectedDateAvail ? `${getGuideDetailsLink(item, allguides, context?.searchQuery, null, xAppID)}` : `${getGuideDetailsLink(item, allguides, null, null, xAppID)}`} className={Styles.guideCardLink}>
					<Card
						key={index}
						className={context.hoverCardId === item.guideguid ? Styles.cardcontainer : Styles.cardcontainer1}
						onMouseOver={() => handleCard(item.guideguid)}
						onFocus={() => { return null }}
						// onClick={(e) => getGuide(item, e)}
						style={!page && isBrowser && window.matchMedia("only screen and (max-width: 500px)").matches ? { border: "none" } : { pointerEvents: "none" }}
					>
						{page && <GuideMobDetail getGuide={getGuide} item={item} from={from} />}
						{!page && isBrowser && window.matchMedia("only screen and (max-width: 500px)").matches &&
							<GuideCardsMobNew getGuide={getGuide} item={item} from={from} />
						}
						{
							!page && isBrowser && window.matchMedia("only screen and (min-width: 501px)and (max-width:768px)").matches &&
							<GuideMobDetail getGuide={getGuide} item={item} from={from} />
						}

					</Card >
				 </a>
				}
			{
				isBrowser && window.matchMedia("only screen and (max-width: 767px)").matches &&
				<a target="_blank" href={SelectedDateAvail ? `${getGuideDetailsLink(item, allguides, context?.searchQuery,null, xAppID)}` : `${getGuideDetailsLink(item, allguides, null,null, xAppID)}`} className={Styles.guideCardLink}>
					<Card
						id={index + 1}
						key={index + 1}
						className={context.hoverCardId === item.guideguid ? Styles.cardcontainer : Styles.cardcontainer1}
						onMouseOver={() => handleCard(item.guideguid)}
						onFocus={() => { return null }}
						// onClick={(e) => getGuide(item, e)}
						style={!page && isBrowser && window.matchMedia("only screen and (max-width: 500px)").matches ? { border: "none" } : null}
					>
						{page && <GuideMobDetail getGuide={getGuide} item={item} from={from} />}
						{!page && isBrowser && window.matchMedia("only screen and (max-width: 500px)").matches &&
							<GuideCardsMobNew getGuide={getGuide} item={item} from={from} />
						}
						{
							!page && isBrowser && window.matchMedia("only screen and (min-width: 501px)and (max-width:768px)").matches &&
							<GuideMobDetail getGuide={getGuide} item={item} from={from} />
						}

					</Card >
				 </a>
			}
		</div>
	)
}

export default GuideCardMobile

