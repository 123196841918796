import React, { useEffect, useRef, useState, useReducer } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import LiveCalendarLevel1 from './widgets/live-calendar-level-1';
import LiveCalendarLevel2 from './widgets/live-calendar-level-2';
import RateCards from './widgets/rate-cards-widget'
import BookNow from './widgets/book-now'
import Reviews from './seo-widgets/reviews'
import Reports from './seo-widgets/reports'
import PhotoGallery from './seo-widgets/photo-gallery'
import FishSpecies from './seo-widgets/fish-species'
import WeatherCalender from './seo-widgets/weather-calender'
import useStyles from './useStyles'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import SearchTemplate1 from './widgets/searchtemplates/template1';
import SearchTemplate2 from './widgets/searchtemplates/template2';
import SearchTemplate3 from './widgets/searchtemplates/template3';
import GuideFinder from './seo-widgets/guidefinder';
/* import Feed from './widgets/feed' */

const App = () => {
  const [guide, setGuide] = useState('712');
  const [xAppID, setXAppID] = useState('788e72ee-dcef-4600-adfa-f7e5a6b0a880')
  const [companyGuid, setCompanyGuid] = useState('cfd97e96-fa24-47f6-828e-30423160e0f4')
  const [isMultiTenantGuide, setIsMultiTenantGuide] = useState(true)
  const [isMarketPlaceGuide, setIsMarketPlaceGuide] = useState(false)
  const classes = useStyles()
  const guideslyWebWidgets = [
    {id:1, title:"Search Template 1" , link:"/searchtemplate1"},
    {id:2, title:"Search Template 2" , link:"/searchtemplate2"},
    {id:3, title:"Search Template 3" , link:"/searchtemplate3"},
    {id:3, title:"Guide Finder" , link:"/search"},
   ]
  return (
    <Router>
      <div style={{padding:"10px"}}>
        <TextField
          id="standard-basic"
          label="Guide ID"
          className={classes.margin}
          onChange={(e) => setGuide(e.target.value)}
          inputProps={{ type: 'number', value: guide }}
        />
        <TextField
          id="standard-basic"
          label="xAppID"
          className={classes.margin}
          onChange={(e) => setXAppID(e.target.value)}
          inputProps={{ type: 'text', value: xAppID }}
        />
        <FormControl className={classes.margin} style={{width:"100px"}}>
          <InputLabel id="demo-simple-select-label">isMultiTenantGuide</InputLabel>
          <Select
            labelId="isMultiTenantGuide"
            id="isMultiTenantGuidet"
            value={isMultiTenantGuide}
            label="isMultiTenantGuide"
            onChange={event => setIsMultiTenantGuide(event.target.value)}
          >
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="standard-basic"
          label="Company-Guid"
          className={classes.margin}
          onChange={(e) => setCompanyGuid(e.target.value)}
          inputProps={{ type: 'text', value: companyGuid }}
        />
        <div style={{display:"flex"}}>
        <ul>
          <li>
            <Link to={`/ratecard/${guide}`}>Rate Card </Link>
          </li>
          <li>
            <Link to={`/live-calendar-level-1/${guide}`}>Live Calendar Level 1 </Link>
          </li>
          <li>
            <Link to={`/live-calendar-level-2/${guide}`}>Live Calendar Level 2 </Link>
          </li>
          <li>
            <Link to={`/reviews/${guide}`}>Reviews </Link>
          </li>
          <li>
            <Link to={`/reports/${guide}`}>Reports </Link>
          </li>
          <li>
            <Link to={`/photo-gallery/${guide}`}>Photo Gallery </Link>
          </li>
          <li>
            <Link to={`/fish-species/${guide}`}>Fish Species </Link>
          </li>
          <li>
            <Link to={`/weather-calender/${guide}`}>Weather Calender</Link>
          </li>
        </ul>
        <div>
          <ul>
            {guideslyWebWidgets?.map(item=>(
              <li key={item.id}>
                <Link to={item.link}>{item.title} </Link>
              </li>
            ))}
          </ul>
          </div>
        </div>
        <BookNow to={`/bookings/${guide}`} />
        <hr />
        <Routes>
          <Route exact path="/ratecard/:id" element={
            <div className={classes.ratecardHolder}>
               <RateCards id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} companyGuid={companyGuid} />
            </div>
          } />
          <Route exact path="/live-calendar-level-1/:id" element={<LiveCalendarLevel1 id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} />} />
          <Route exact path="/live-calendar-level-2/:id" element={ <LiveCalendarLevel2 id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} />} />
          <Route exact path="/reviews/:id" element={
            <div className={classes.reviewsWidth}>
                <Reviews id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} />
            </div>
          } />
          <Route exact path="/reports/:id" element={
            <div className={classes.reportsWidth}>
              <Reports id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} />
            </div>
          } />
          <Route exact path="/photo-gallery/:id" element={<PhotoGallery id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} companyGuid={companyGuid}/>} />
          <Route exact path="/fish-species/:id" element={
            <div className={classes.reviewsWidth}>
              <FishSpecies id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} />
            </div>
          } />
          <Route exact path="/weather-calender/:id" element={
            <div className={classes.reviewsWidth}>
              <WeatherCalender id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} />
            </div>
          } />
        <Route exact path="/searchtemplate1" element={
            <div className={classes.reviewsWidth} style={{width:"350px"}}>
              <SearchTemplate1 xAppID={xAppID}/>
               </div>
        }
          />
          <Route exact path="/searchtemplate2" element={
            <div className={classes.reviewsWidth}>
              <SearchTemplate2 xAppID={xAppID}/>
               </div>
          }
          />
          <Route exact path="/searchtemplate3" element={
            <div style={{margin:"0px auto"}} >
              <SearchTemplate3 xAppID={xAppID}/>
               </div>
          }
          />
          <Route exact path="/search" element={
            <div style={{margin:"0px auto"}} >
              <GuideFinder xAppID={xAppID}/>
               </div>
          }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
