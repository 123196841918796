import React, { useState } from 'react'
import { Card, Row, Col, Button } from "react-bootstrap"
import  Styles from "./styles/guideList.module.scss"
import { displayMoney, getCorrectedImagePath } from './common/data/data'
import Icon from "react-icons-kit"
import { star } from 'react-icons-kit/fa/star'
import { x } from 'react-icons-kit/feather';
import getSymbolFromCurrency from 'currency-symbol-map'

const GuideMobDetail = ({ getGuide, item, from, showClose, handleClear }) => {
	const [hover, setHover] = useState(false);
	const page = from && (from === "article" || from === "fish") ? true : false
	const getName = (name) => {
		const rec = name.split(" ")
		if (rec.length > 1) {
			return rec[0] + " " + rec[1].charAt(0)
		} else {
			return name
		}
	}
	return (
		<div className={Styles.GuideCard}>
			<div className={Styles.GuideImgs}>
				{/* {showClose && <div className={Styles.CloseDiv}>
					<button onClick={() => handleClear()}><Icon icon={x} /></button>
				</div>} */}
				{item.super_guide &&
					<p className={Styles.superguide1}>Superguide</p>}
				{item.guidepic ?
					<div className={`${Styles.GuideImgSize} ${Styles.imgBg}`} style={{
						backgroundImage: `url('${GUIDESLY_GUIDEPIC_IP}/${getCorrectedImagePath(item.guidepic)}')`
					}}></div> :
					<div className={`${Styles.GuideImgSize} ${Styles.imgBg}`} style={{ background: "#224041", textAlign: "center" }}>
						<span style={{ color: "#FFF", lineHeight: "135px", fontSize: "50px" }} >{getName(`${item.company && item.company != "" ? item.company : item?.guidename}`).split(" ").map((n) => n[0]).join("").toUpperCase()}</span>
					</div>
				}
			</div>
			<Row className={Styles.GuideRow}>
				<Col xs={12} sm={12} lg={12} className={Styles.GuideCol1} >
					<div className={Styles.TitleContainer}>
						{/* <Link to={getGuideDetailsLink(item, allGuides)}> */}
						<Card.Title style={{ flexWrap: 'wrap', display: 'flex' }} className={Styles.name}>
							<span className={page ? Styles.gcName1 : from && from === "onMap" ? `${Styles.gcName2} ${Styles.gcNameWidth}` : Styles.gcName} style={{ WebkitLineClamp: 1 }}>{`${item.company && item.company != "" ? item.company : item.comp && item.comp != "" ? item.comp : item.d_name ? item.d_name : item.guidename}`}</span>
						</Card.Title>
						{/* </Link> */}
					</div>
					<Card.Subtitle className={Styles.loc}>
						{
							item.display_location && item.display_location !== null ?
								<div style={{ display: "flex", marginBottom: 2 }} >
									{/* <Image style={{ width: "15px", height: "15px", margin: " 0px 3px 0px -3px" }} src={Locimg} alt="address" /> */}
									<p className={page ? Styles.loc1 : from && from === "onMap" ? `${Styles.location} ${Styles.locationMob}` : Styles.location}>
										{item.display_location}
									</p>
								</div> : item.city !== null && item.state !== null ?
									<div style={{ display: "flex", marginBottom: 2 }} >
										{/* <Image style={{ width: "15px", height: "15px", margin: " 0px 3px 0px -3px" }} src={Locimg} alt="address" /> */}
										<p className={page ? Styles.loc1 : from && from === "onMap" ? `${Styles.location} ${Styles.locationMob}` : Styles.location}>
											{item.city === null && item.state === null ? "" : item.city !== null && item.state === null ? `${item.city}` :
												item.city === null && item.state !== null ? `${item.state}` :
													`${item.city.trim()}, ${item.state}`}
										</p>
									</div> : null
						}
					</Card.Subtitle >

					<p className={page ? Styles.recs1 : from === "onMap" ? `${Styles.Available}` : Styles.recs}>{item.reviews_count && Number(item.reviews_count) > 0 &&
						<><Icon icon={star} size={15} style={{ color: "#2d2d2d" }} />
						{Number(item.rtg) === 0 ? null :<>{Number(item.rtg).toFixed(1)}</>}</>}
						{item.reviews_count && Number(item.reviews_count) > 0 && <>{item.reviews_count && Number(item.reviews_count) > 1 ? ` (${item.reviews_count} Reviews)` : ` (${item.reviews_count} Review)`}
						</>}</p>
					<p className={page ? `${Styles.Avail} ${Styles.availArticle}` : from && from === "onMap" ? `${Styles.Available} ` : Styles.Available} >{item.availability_details.trips === 1 ? `${item.availability_details.trips} Trip Available` : item.availability_details.trips != 0 ? `${item.availability_details.trips} Trips Available` : "Show all Available Trips"}</p>
					{from && from === "onMap" ?
						<Button className={page ? `${Styles.ViewBtnMob} button` : `${Styles.ViewBtn} button`} >Starting at {item.rate ? `${(getSymbolFromCurrency(item?.currency) || '$')}${displayMoney(item.rate)}` : `${(getSymbolFromCurrency(item?.currency) || '$')}${displayMoney(item.min_rate)}`}</Button>
						: <Button className={page ? `${Styles.ViewBtnMob} button` : `${Styles.ViewBtn} button`}>Starting at {item.rate ? `${(getSymbolFromCurrency(item?.currency) || '$')}${displayMoney(item.rate)}` : `${(getSymbolFromCurrency(item?.currency) || '$')}${displayMoney(item.min_rate)}`}</Button>

					}

				</Col>
			</Row>
		</div>
	)
}
export default GuideMobDetail
