import React, { useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import RateCards from './rate-cards';
import PersonalDetails from '../../../common/personal-details/PersonalDetailsStripeProvider';
import Confirmation from '../../../common/confirmation/Confirmation';
import TripDetailsContext from '../../../state/context/TripDetailsContext';
import {
  getAllRateCardsWithDisabled,
  getAvailability,
  getInitialRateCards,
  getExpCapacity
} from '../../../utils/guide-details';
import { get } from '../../../utils/axiosHelper';
import {
  getTotalCharge,
} from '../../../utils/payment';
import useStyles from './styles';
import { isValidPhoneNumber } from 'react-phone-number-input';
import './styles.scss'
import 'react-day-picker/dist/style.css';
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp';
import Selection from '../../../common/selection';

export default function RateCardsWidget({ id, xAppID, isMultiTenantGuide, companyGuid,userData }) {
  const classes = useStyles();
  const isBrowser = typeof window !== `undefined`
  const isNarrowMob = isBrowser && (/Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) || window.innerWidth <= 768 || ((window.screen || {}).width || 0) <= 768)
  const tripDetailsContext = useContext(TripDetailsContext);
  const [referralGuides, setReferralGuides] = useState([]);
  const [showCalendar, setShowCalendar] = useState(null)
  const {
    step,
    setStep,
    fromMonth,
    toMonth,
    modifiers,
    setSelectedDay,
    selectedHour,
    selectedRateCard,
    guestCount,
    setModifiers,
    setAvailableRateCards,
    setAvailableSchedules,
    setMeals,
    setRateCards,
    setAvailableMeals,
    setAvailableMeetingPoints,
    setAvailableWaterBodies,
    setMeeetingPoint,
    setPreferedWaterbody,
    setSecondFavoriteWaterbody,
    setGuideName,
    firstName,
    lastName,
    email,
    phoneNumber,
    rateCards,
    setSelectedRateCard,
    setSelectedHour,
    selectedDay,
    setAddOnFees,
    addOnFees,
    modifiersStyles,
    availableSchedules,
    availableRateCards,
    setIsGuestCountMaxed,
    isGuestCountMaxed,
    setGuestCount,
    setGuideDetails,
    setDepositAmt,
    setBSAvailability,
    setSelectionType,
    availableSpecies,
    setAvailableSpecies,
    setReferralGuideName,
    setReferralMainId,
     setCancelationDays,
     setSchRecords,
     schrecords,
     sharedTripRateCapacity, 
     setSharedTripRateCapacity,
     setCompanyDetails, selectionType
  } = tripDetailsContext;

  let total;
  let chargeToday;
  let chargeOnDayOfTrip;

//   useEffect(() => {
//     if(guideGuidFirst) {
//         setReferralMainId(guideGuidFirst)
//     }
// }, [guideGuidFirst])

useEffect(() => {
  if(referralGuides) {
    setReferralGuideName(referralGuides)
  }
},[referralGuides])
useEffect(() => {
  if(userData && Object.keys(userData)?.length>0) {
    tripDetailsContext.setUserData(userData)
  }
},[userData])

  if (selectedHour.rate) {
    total = getTotalCharge(
      selectedRateCard,
      guestCount,
      selectedHour.rate,
      addOnFees
    );

    chargeToday = (total * 0.20).toFixed(2);

    chargeOnDayOfTrip = total - chargeToday;
  }

  useEffect(() => {
    if (guestCount) {
      if(selectedRateCard?.is_shared_trip) {
          const isGuestCountMaxed = guestCount > sharedTripRateCapacity
          setIsGuestCountMaxed(isGuestCountMaxed)
      } else {
        if (selectedRateCard) {
          const isGuestCountMaxed = guestCount > selectedRateCard.maximum
          setIsGuestCountMaxed(isGuestCountMaxed)
        } else if (rateCards.length === 1 && !rateCards[0].disabled) {
          const isGuestCountMaxed = guestCount > rateCards[0].maximum
          setIsGuestCountMaxed(isGuestCountMaxed)
        } else {
          setIsGuestCountMaxed(false)
        }
        if (guestCount > 1) {
          const ratecards = rateCards.filter(item => guestCount <= item.maximum)
          setRateCards(ratecards);
        } else {
          const ratecards = rateCards.filter(item => guestCount <= item.maximum)
          setRateCards(ratecards);
        }
      }
    }
  }, [guestCount, step, sharedTripRateCapacity])

  useEffect(() => {
    if (isBrowser) {
      if (document.querySelector('#guidesly-live-calendar') && window.location.host.includes('texasleadslingers.com')) {
        document.querySelector('#guidesly-live-calendar').style.display = 'none'
      }
      if (typeof window !== 'undefined') {
        if (document.querySelector('.wsb-htmlsnippet-element')) {
          document.querySelectorAll('.wsb-htmlsnippet-element').forEach(item => item.style.overflow = "scroll")
        }
      }
      setTimeout(() => {
        if (document.querySelector('#iframe-03')) {
          document.querySelector('#iframe-03').style.display = 'none'
        }
      }, 3000);
    }
  }, [])

  useEffect(() => {
    const startDate = moment(fromMonth).format('YYYY-MM-DD');
    const endDate = moment(toMonth).format('YYYY-MM-DD');
    const url1 =`guide/gbs/availability?id=${id}&start_date=${startDate}&end_date=${endDate}&current_date=${moment().format("YYYY-MM-DD h:mm:s a")}`;
    const getSpecies = `content/fish_species?search=all`;
    const url2 = `guide-group/${companyGuid}/ratecards?current_date=${moment().format("YYYY-MM-DD h:mm:s a")}`
    const url = isMultiTenantGuide&&companyGuid&&companyGuid!="" ? url2 :url1
    get(url).then((res) => {
      if (res?.data) {
        const BookingAcceptanceDate = res.data?.booking_acceptance_days || res.data?.booking_acceptance_days === 0 ? res.data?.booking_acceptance_days : 2
        if(isBrowser){
          window.localStorage.setItem("bookingAcceptance",BookingAcceptanceDate)
        }
        setSchRecords(res?.data)
        setCancelationDays(res?.data?.allowed_cancellation_days)
        setReferralGuides(res?.data?.referral_guides)
        setReferralMainId(res?.data?.guideguid)
        const { scheduleDisable, availability } = getAvailability(
          res.data.schedule,
        );
        setModifiers({
          ...modifiers,
          availability,
          scheduleDisable,
          schedule: res.data.schedule,
        });
        if(isMultiTenantGuide&&companyGuid&&companyGuid!="" ){
          setDepositAmt(res.data.deposit_percentage)
        }else{
          setDepositAmt(null)
        }
        setAvailableRateCards(res.data.ratecards);
        if (res.data.schedule.length > 0) {
          setAvailableSchedules(res.data.schedule);
          const selectedInitialDay = res.data.schedule.find(
            (day) => day.date === moment(new Date()).format('YYYY-MM-DD'),
          );
          const rateCards = getInitialRateCards(res.data.ratecards, selectedInitialDay);
          setRateCards(
            rateCards,
          );
          if (rateCards.length === 1) {
            setSelectedRateCard(rateCards[0])
          }
          setSelectedDay(
            ''
          );
        }
        if (res.data.meals) {
          const select = { guid: 'select', name: "Select" };
          setMeals(select);
          setAvailableMeals([select, ...res.data.meals]);
        }
        // if (res.data.poi) {
        //   setMeeetingPoint(res.data.poi[0]);
        //   setAvailableMeetingPoints(res.data.poi);
        // }
        if (res.data.rivers) {
          setAvailableWaterBodies(res.data.rivers);
          setPreferedWaterbody(res.data.rivers[0]);
          setSecondFavoriteWaterbody(res.data.rivers[0]);
        }
        setGuideName(res.data.guidename);
        // setAddOnFees(res.data.guide_addon_fees || []);
        setGuideDetails({
          guidename: res.data.guidename,
          company: res.data.company,
          location1: res.data.location1,
          location2: res.data.location2,
          title: res.data.title
        })
      }
    });
    get(getSpecies).then((res) => {
      if (res?.data?.response) {
        setAvailableSpecies(res?.data?.response)
      }
    });
  }, []);
  useEffect(() => {
    const add_on_fees = (selectedRateCard.addon_fees || []).filter(item => item.active)
    setAddOnFees(add_on_fees)
  }, [selectedRateCard])

  useEffect(() =>{
    setSharedTripRateCapacity(getExpCapacity(availableSchedules,selectedHour,selectedDay))
  }, [selectedHour,selectedDay])


  const instantBook = (card, hour) => {
    if (!hour.rate || card.id !== selectedRateCard.id) {
      setSelectedHour(card.rates[0]);
      setSelectedRateCard(card);
      // setStep('personal-details')
      return;
    }
  }

  const handleDayClick = (e, dayPickerModifiers = {}) => {
    e.setHours(0, 0, 0, 0);

    if (dayPickerModifiers.availability) {
      setSelectedDay(e);
      const selectedSchedule = availableSchedules.find(
        (schedule) =>
          schedule.date === moment(e).format('YYYY-MM-DD'),
      );
      const ratecards = getAllRateCardsWithDisabled(availableRateCards, selectedSchedule)
      setRateCards(ratecards);
      if (showCalendar) {
        const { rateCard = {}, selectedHour = {} } = showCalendar;
        const completeRateCard = ratecards.find(rate => rate.id === rateCard.id)
        if (!completeRateCard.disabled && !completeRateCard.rates[0].disabled) {
          instantBook(rateCard, selectedHour)
        }
      }
      // setModifiers({
      //   ...modifiers,
      //   availability: modifiers.availableSchedule
      // })
    }
  };
 const capacity = isMultiTenantGuide&&selectionType?.type&&selectionType?.type==="Boat"?selectionType?.data?.guest_capacity:selectedRateCard?.is_shared_trip ? getExpCapacity(availableSchedules,selectedHour,selectedDay) || selectedHour?.max_occurrence : selectedRateCard?.id ? selectedRateCard.maximum : Math.max(...rateCards.map(item => item.maximum || 0))

  const commontripDetails = {
      'trip-details': (
        <Grid container>
          <RateCards
            isGuestCountMaxed={isGuestCountMaxed}
            setModifiers={setModifiers}
            modifiers={modifiers}
            setShowCalendar={setShowCalendar}
            capacity={capacity}
            selectedDay={selectedDay}
            selectedRateCard={selectedRateCard}
            rateCards={rateCards}
            selectedHour={selectedHour}
            guestCount={guestCount}
            handleClick={(hour, card) => {
              setSelectedHour(hour);
              setSelectedRateCard(card);
            }}
            instantBook={instantBook}
            addOnFees={addOnFees}
            disabled={!(total && chargeToday && chargeOnDayOfTrip && selectedDay)}
            disabledClear={!(total || chargeToday || chargeOnDayOfTrip || selectedDay)}
            disabledDate={!selectedDay}
            setStep={setStep}
            fromMonth={fromMonth}
            toMonth={toMonth}
            month={selectedDay}
            modifiersStyles={modifiersStyles}
            setSelectedRateCard={setSelectedRateCard}
            setSelectedHour={setSelectedHour}
            setSelectedDay={setSelectedDay}
            setRateCards={setRateCards}
            showCalendar={showCalendar}
            availableSchedules={availableSchedules}
            stateRateCards={availableRateCards}
            setGuestCountHandler={setGuestCount}
            xAppID={xAppID}
            handleDayClick={handleDayClick}
            setIsGuestCountMaxed={setIsGuestCountMaxed}
            template={FormatListBulletedSharpIcon}
            rivers={setAvailableWaterBodies}
            isMultiTenantGuide={isMultiTenantGuide}
            companyGuid={companyGuid}
            setBSAvailability={ setBSAvailability}
            setSelectionType={setSelectionType}
            availableSpecies={availableSpecies}
            setReferralGuideName={setReferralGuideName}
            referral={referralGuides}
            schrecords={schrecords}
            setCompanyDetails={setCompanyDetails}
            userData={userData}
          />
        </Grid>
      ),
  }
  const itsCompany ={
    ...commontripDetails,
    'selection':(
      <Selection
      previous={() => setStep('trip-details')}
      next={() => setStep('personal-details')}
      companyGuid={companyGuid}
      />
    ),
    'personal-details': (
      <PersonalDetails
        previous={() => setStep('selection')}
        next={() => setStep('confirmation')}
        tripsPage={() => setStep('trip-details')}
        xAppID={xAppID}
        capacity={capacity}
        setGuestCountHandler={setGuestCount}
        isNarrow={isNarrowMob}
        template={false}
        isMultiTenantGuide={isMultiTenantGuide}
        companyGuid={companyGuid}
      />
    ),
  }
  const itsIndividualGuide ={
    ...commontripDetails,
    'personal-details': (
      <PersonalDetails
        previous={() => setStep('trip-details')}
        next={() => setStep('confirmation')}
        xAppID={xAppID}
        capacity={capacity}
        setGuestCountHandler={setGuestCount}
        isNarrow={isNarrowMob}
        template={false}
        isMultiTenantGuide={isMultiTenantGuide}
        companyGuid={companyGuid}
      />
    )
  }

  const Step = isMultiTenantGuide&&companyGuid&&companyGuid!=""? itsCompany : itsIndividualGuide;

  if (step === 'confirmation') {
    return (
      <Card className={classes.root}>
        <CardContent>
          <Confirmation isMultiTenantGuide={isMultiTenantGuide}
        companyGuid={companyGuid} />
        </CardContent>
      </Card>
    );
  }

  let disabled = true;
  let disabledClear = true;
  let disabledDate = true;

  if (step === 'trip-details')
    disabled = !(total && chargeToday && chargeOnDayOfTrip);
  disabledClear = !(total || chargeToday || chargeOnDayOfTrip);
  disabledDate = !(selectedDay);
  if (step === 'personal-details')
    disabled = !(firstName && lastName && email && phoneNumber) || !isValidPhoneNumber(phoneNumber) || !(total && chargeToday && chargeOnDayOfTrip) || isGuestCountMaxed

  const title = {
    'trip-details': 'Trip Types',
    'personal-details': ``,
    payment: '',
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        {Step[step]}
      </CardContent>
    </Card>
  );
}

