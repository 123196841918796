import { Button } from "@mui/material";
import React, { useState, useMemo, } from "react"
import DropdownOptions from "./DropdownOptions"
import { MakeUrls } from "./MakeUrls";
import useStyles from './styles';

const AddtoCalendar = ({ event, filename = "download" }) => {
	const [open, setOpen] = useState(false)
	const classes = useStyles()
	const onToggle = () => {
		setOpen(!open)
	}
	const urls = useMemo(() => MakeUrls(event), [event]);
	return (
		<div style={{
			display: 'inline-block',
			position: 'relative',
			width: '100%'
		  }}>
			{event && (
				<Button style={{ width: '100%' }} type="button" className={classes.Addbutton} onClick={onToggle}>
					Add to My Calendar
				</Button>
			)}
			{open && <DropdownOptions filename={filename} setOpen={setOpen} urls={urls} />}
		</div>
	)
}
export default AddtoCalendar
