import React, { useEffect, useRef, useState } from 'react'
import { Card, CardContent, Grid, Tooltip, Typography } from '@mui/material';
import './style.scss'
import playIcon from '../../assets/Play.png';
// import FsLightBox from '@guidesly/fslightbox-react-v2'
import ReactPlayer from 'react-player'
import moment from 'moment'
// import { isBrowser } from "../../../utils/constants";
import Slider from './SlickCarousel'
import Sun from '../../assets/sun.svg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Report = ({ report, previous }) => {
    console.log(report)
    const [fish , setFish] = useState(report?.catches?.data && report?.catches?.data.length > 0 ? report?.catches?.data[0] : '')
    const [active, setActive] =  useState(report?.catches?.data && report?.catches?.data.length > 0 ? report?.catches?.data[0].fish_name : '')
    const [gallery, setGallery] = useState([])
    const [photoIndex, setPhotoIndex] = useState(0)
    const [images, setImages] = useState([])
    const [video, setVideo] = useState([])
    const [isMobile, setIsMobile] = useState(/Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) || window.innerWidth <= 489 || ((screen || {}).width || 0) <= 489)
    const imageCount = isMobile ? 6 : 8;
    const TempImage ="https://guidesly-assets.s3.us-east-2.amazonaws.com/email/consumer/images/marketplace_web_layout_mp.png"
    
    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
            block: 'center',
        })
    },[])

    const FishingInfo = {
        "FEET": [
            { key: '0', label: '0 Ft' },
            { key: '1', label: '1 Ft' },
            { key: '2', label: '2 Ft' },
            { key: '3', label: '3 Ft' },
            { key: '4', label: '4 Ft' },
            { key: '5', label: '5 Ft' },
            { key: '6', label: '6 Ft' },
            { key: '7', label: '7 Ft' },
            { key: '8', label: '8 Ft' },
            { key: '9', label: '9 Ft' },
            { key: '10', label: '10 Ft' },
            { key: '11', label: '11 Ft' },],
        "PRESSURE": [
            { key: 'None', label: 'No Other Anglers' },
            { key: 'Low', label: 'Light Fishing Pressure' },
            { key: 'Heavy', label: 'Heavy Fishing Pressure' },],
        "LOCATION": [
            { key: 'Freshwater', label: 'Freshwater' },
            { key: 'Saltwater', label: 'Saltwater' },
            { key: 'Brackish', label: 'Brackish Water' },],
        "STRUCTURE": [
            { key: 'Clear', label: 'Clear & Open' },
            { key: 'Rocky', label: 'Rocks/Boulders' },
            { key: 'Trees', label: 'Trees & Logs in Water' },
            { key: 'Vegetation', label: 'Vegetation Growing' },],
        "STYLE": [
            { key: 'FlyFishing', label: 'Fly Fishing' },
            { key: 'Rocky', label: 'Rocks/Boulders' },
            { key: 'Trees', label: 'Trees & Logs in Water' },
            { key: 'Vegetation', label: 'Vegetation Growing' },
            { key: 'SpeyFishing', label: 'Spey Fishing' },
            { key: 'SpinCasting', label: 'Spin Casting' },
            { key: 'IceFishing', label: 'Ice Fishing' },
            { key: 'Surf', label: 'Surf Casting' }
        ],
        "CLARITY": [
            { key: '6-Inches', label: 'Limited, 6 Inches or less' },
            { key: '12-Inches', label: 'Murky, 6-12 Inches' },
            { key: '2-Feet', label: 'Cloudy, 1-2 Feet' },
            { key: '6-Feet', label: 'Clear, 2-6 Feet' },
            { key: '12Feet', label: 'Very Clear, 6-12 Feet' },
            { key: '12FeetOver', label: 'Crystal Clear, Over 12 Feet' },],
        "TIDE": [
            { key: 'HighTide', label: 'High Tide' },
            { key: 'Ebb', label: 'Ebb from High to Low Tide' },
            { key: 'LowTide', label: 'Low Tide' },
            { key: 'Flow', label: 'Flow from Low to High Tide' },],
    };

    let mediaTypes = ['jpg','png','webp','gif','jpeg','tiff']

    var photosArray = []
    if (report?.pictures?.total) {
        report.pictures.data.map((image, i) => {
                image.mediapath !== report.coverpicture && photosArray.push(image)
        })
    }

    const handleLightBox = (index, photosArray) => {
        setGallery(photosArray)
        setPhotoIndex(index)
    }

    const sources = gallery.map(image => `${cloudfront}${image.mediapath}`)
    const types = gallery.map(image => image.mediadescription === 'videos' ? 'video' : 'image')
    const captions = gallery.map(image => image.caption)
    const onErrorCallBack = (e) => {
		e.target.src = TempImage
	}

    let v = false
    if (images && images.length) {
        v = true
    }

    const handleFish = (data) => {
        setActive(data)
    }

    const items = report?.catches?.data && report?.catches?.data.map((item, index) => {
        return (
            <div key={index}  
                onClick={() => {
                        setFish(item)
                        handleFish(item.fish_name)
                    }}>
                    <div className={active === item.fish_name ? `fishCard showActive` : `fishCard` }
                    style={{ backgroundImage : `url('${item.coverpicture}')`}}></div>
            </div>
        )
    })

    var test = report.fishing_type || report.longdescription || report.water_tide || report.fishcaught

    console.log(photosArray, "photosArray")

    return (
        <CardContent>
            <ArrowBackIcon className={'BackArrow'} onClick={previous} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={report.publication_status && report.weather_feels_like ? 8 : 12} lg={report.publication_status && report.weather_feels_like ? 8 : 12}>
                   <Typography variant='h3' className={'MainTitle'}>{report.title}</Typography>
                   <Typography variant='h5' className={'waterbodyname'}>{report.location_waterbodyname}</Typography>
                   {
                       report.coverpicture && 
                       <div >
                            <img src={`${cloudfront}${report.coverpicture}`} alt="img" className={'MainCoverImage'} onError={(e) => onErrorCallBack(e)}  />
                        </div>
                   }
                </Grid>
                {
                    report.publication_status && report.weather_feels_like ? 
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Typography variant='body2' className={'SubTitle'} style={{  marginTop: '10px'}} >WEATHER DATA</Typography>
                    {
                        report?.weather_desc && 
                        <div className={'WeatherForecast'}>
                            <Typography variant='body2'  >FORECAST</Typography>
                            <Typography variant='body2'  >Weather at {moment(report.startdate).format("hh:mm a") +', '+ `${report.weather_desc}`}</Typography>
                        </div>
                    }
                    <Grid container spacing={1}>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <div className={'Current'}>
                                <p className={'actualValue'}>{report?.weather_temp}</p>
                                <div className={'averageValue'}> 
                                    <Typography variant='body2'  >Current</Typography>
                                    <Typography variant='body2'  >Feels {report?.weather_feels_like}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <div className={'Highs'}>
                                <p className={'actualValue'}>{report?.weather_temp_max}</p>
                                <div className={'averageValue'}> 
                                    <Typography variant='body2'  >Highs</Typography>
                                    <Typography variant='body2'  >Low {report?.weather_temp_min}</Typography>
                                </div>
                            </div>   
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <div className={'Winds'}>
                                <p className={'actualValue'}>{report?.weather_wind}</p>
                                <div className={'averageValue'}> 
                                    <Typography variant='body2'  >Winds</Typography>
                                    <Typography variant='body2'  >Gusts {report?.weather_wind_gust}</Typography>
                                </div>
                            </div>   
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div className={'Humidity'}>
                                <p className={'actualValue'}>{report?.weather_humidity}</p>
                                <div className={'averageValue'}> 
                                    <Typography variant='body2'>Humidity</Typography>
                                    <Typography variant='body2'>Dew {report?.weather_dew}</Typography>
                                </div>
                            </div>   
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} style={{ display : 'flex', justifyContent: 'space-between'}}>
                            <div className={'sun'}>
                                    <img src={Sun} />
                                    <Typography variant='body2'>{moment(report?.weather_sunrise, 'hh:mm:ss').format('hh:mm A')}</Typography>
                            </div>  
                            <div className={'sun'}> 
                                    <img src={Sun} />
                                    <Typography variant='body2'>{moment(report?.weather_sunset, 'hh:mm:ss').format('hh:mm A')}</Typography>
                            </div>   
                        </Grid>
                    </Grid>  
                </Grid> : null }
            </Grid> 
            <Grid container spacing={3} style={{ marginTop : '30px'}}>
                <Grid item xs={12} sm={12} md={ test && report.publication_status !== null ? 6 : 12} lg={test && report.publication_status !== null ? 6 : 12}>
                    {
                        report.description && 
                        <div className={'Desc'}>
                            <Typography variant='body2' className={'SubTitle'} >DESCRIPTION</Typography>
                            <Typography variant='body2' className={'SubTitle'} >{moment(report.startdate ? report.startdate : report.enddate).format("LL")}</Typography>
                        </div>
                    }
                    {report.description && <Typography variant="body2" className={'fullDesc'} dangerouslySetInnerHTML={{ __html: report.description }}></Typography>}
                    {
                         (report.fishpressure || report.water_clarity || report.water_structure) && 
                            <Grid container spacing={2} style={{ marginTop : '8px', marginBottom: '30px'}}>
                                {
                                    report.fishpressure && 
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Typography variant='body2' className={'sectionTitle'}>POPULARITY</Typography>
                                        <Typography variant='body2'>{FishingInfo.PRESSURE.find(item => item.key === report.fishpressure)?.label ?? report.fishpressure}</Typography>
                                    </Grid>
                                }
                                {
                                    report.water_clarity && 
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Typography variant='body2' className={'sectionTitle'}>WATER CLARITY</Typography>
                                        <Typography variant='body2'>{FishingInfo.CLARITY.find(item => item.key === report.water_clarity)?.label ?? report.water_clarity}</Typography>
                                    </Grid>
                                }
                                {
                                    report.water_structure && 
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Typography variant='body2' className={'sectionTitle'}>SCENIC SETTING</Typography>
                                        <Typography variant='body2'>{FishingInfo.STRUCTURE.find(item => item.key === report.water_structure)?.label ?? report.water_structure}</Typography>
                                    </Grid>
                                }
                            </Grid>
                    }
                    {
                        photosArray && photosArray.length > 0 &&
                        <>
                            <div className={'Desc'}>
                                <Typography variant='body2' className={'SubTitle'} >MEDIA GALLERY</Typography>
                            </div>
                            <div className={`guide_img_container reviewImg`}>
                                {
                                    photosArray !== null && photosArray !== undefined && photosArray.length !== 0 ?
                                        <div className={photosArray.length <= 7 ? `guide_img_list nowrap` : `guide_img_list`}>
                                            {photosArray.slice(0, imageCount).map((pic, i) => {
                                                    return (
                                                        <Typography
                                                            component={'span'}
                                                            key={i}
                                                            className={(i === imageCount - 1 && photosArray.length > imageCount) ? `overlay` : null}
                                                            onClick={() => handleLightBox(i, photosArray)}
                                                        >
                                                            {
                                                                pic.mediadescription === 'videos' ?
                                                                    // <ReactPlayer
                                                                    //     url={`${cloudfront}${pic.mediapath}`}
                                                                    //     width="100px"
                                                                    //     height="100px"
                                                                    //     style={{ backgroundColor: "#000", cursor: "pointer" }}
                                                                    //     light
                                                                    //     playIcon={<img loading="lazy" src={playIcon} width={'10px'} height={'10px'} />}
                                                                    //     config={{
                                                                    //         file: {
                                                                    //             forceVideo: false,
                                                                    //             attributes: {
                                                                    //                 playsInline: false,
                                                                    //                 controls: false,
                                                                    //                 // crossOrigin: "Anonymous"
                                                                    //             }
                                                                    //         },
                                                                    //     }}
                                                                    // />
                                                                    <div className='video-overlay-container fishing-report'>
                                                                        <div className='video-overlay video-overlay-report'>
                                                                            <img src={'https://da9mvpu5fnhic.cloudfront.net/images/Icons/PNG/Play.png'}/>
                                                                        </div>
                                                                        <video
                                                                            width="100px"
                                                                            height="100px"
                                                                            >
                                                                            <source
                                                                                src={`${cloudfront}${pic.mediapath}`}
                                                                            ></source>
                                                                        </video>
                                                                    </div>
                                                                    :
                                                                    <div style={{
                                                                        width: "100px", height: "100px", backgroundImage: `url('${cloudfront}${pic.mediapath}')`,
                                                                        backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", backgroundColor: "#000", cursor: "pointer"
                                                                    }}></div>
                                                            }

                                                        </Typography>
                                                    )
                                            })}
                                        </div>
                                    : null
                                }
                            </div>
                         </>
                    }
                        {gallery.length > 0 &&
                            <FsLightBox
                                toggler={gallery.length}
                                types={types}
                                sources={sources}
                                captions={captions}
                                slide={photoIndex + 1}
                                disableLocalStorage
                                onClose={() => setGallery([])}
                                openOnMount
                            />
                        }
                </Grid>
                {
                    report.publication_status ? 
                <Grid item xs={12} sm={12} md={test ? 6 : 12} lg={test ? 6 : 12}>
                    {
                        report?.catches?.total ?
                        <>
                            {
                                report?.catches?.total && 
                                <div className={'Desc'}>
                                    <Typography variant='body2' className={'SubTitle'} >CATCHES</Typography>
                                    <Typography variant='body2'>{report.catches?.total === 1 ? 'Type of Fish' : 'Types of Fishes'}
                                        <Typography variant='p' className={'Count'}>{report?.catches?.total}</Typography>
                                    </Typography>
                                </div>
                            }
                            {
                                items && items.length > 0 && 
                                <>
                                    <div className={'BigFish'}>
                                        <div
                                            style={{ backgroundImage : `url('${fish.coverpicture}')`, width : ''}}
                                        ></div>
                                    </div>
                                    <div className={'fishCaught'}>
                                        <Typography variant='body2' className={'SubTitle'} >{fish.fish_name}</Typography>
                                        <Typography variant='body2'>{fish?.fish_caught === 1 ?? Number(fish.title) === 1 ? 'No. of Fish' : 'No. of Fishes'}
                                            <Typography variant='p' className={'Count'}>{fish?.fish_caught ?? fish.title}</Typography>
                                        </Typography>
                                    </div>
                                    {fish.description && <Typography variant="body2" className={'fullDesc'} dangerouslySetInnerHTML={{ __html: fish.description }}></Typography>}
                                    {
                                        items.length > 3 ?
                                        <Slider data={items} /> :  items.length === 1 ? null :
                                        <div className={'noSlider'}>
                                            {
                                                report?.catches?.data && report?.catches?.data.map((item, index) => {
                                                    return (
                                                        <div key={index}  
                                                            onClick={() => {
                                                                setFish(item)
                                                                handleFish(item.fish_name)
                                                            }}>
                                                                 <div className={active === item.fish_name ? `fishCard showActive` : `fishCard` }
                                                                style={{ backgroundImage : `url('${item.coverpicture}')`}}></div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </>
                            }
                        </> : null
                    }
                    {
                        (report.fishing_type || report.longdescription || report.water_tide) ?
                        <>
                            <div className={'Desc'}>
                                <Typography variant='body2' className={'SubTitle'} >FISHING STYLE</Typography>
                            </div>
                            <div>
                                {
                                    report.fishing_type && <Typography variant='body2' className={'FishingStyles'}>Technique: 
                                    <Typography variant='p' >{FishingInfo.STYLE.find(item => item.key === report.fishing_type)?.label ?? report.fishing_type}</Typography></Typography>
                                }
                                {
                                    report.water_tide && <Typography variant='body2' className={'FishingStyles'}>Bait/Lure: 
                                    <Typography variant='p' >{report.water_tide}</Typography></Typography>
                                }
                                {
                                    report.longdescription && 
                                    <>
                                        <Typography variant='body2' className={'FishingStyles'} >Gear & Tackle Used: </Typography>
                                        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: report.longdescription }}></Typography>
                                    </>
                                }
                            </div>
                        </> : null
                    }
                </Grid> : null }
            </Grid>
        </CardContent >
    )
}

export default Report