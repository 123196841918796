import React, { useState, useEffect } from "react"
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import CurrencyInput from "react-currency-input-field";
import styles from "../styles/categoryFilter.module.scss"

const SliderRange = ({ type, filters, setFilters, currentrange, min, max, setChange, setLoaded }) => {
	const [rangeValue, setRangeValue] = useState({
		"minRate": min,
		"maxRate": max,
		"minHours": 1,
		"maxHours": max,
	})
	useEffect(() => {
		setRangeValue({
			"minRate": filters.minRate,
			"maxRate": filters.maxRate,
			"minHours": filters.minHours,
			"maxHours": filters.maxHours,
		})
	}, [filters.minRate, filters.maxRate, filters.minHours, filters.maxHours])
	const Currency = (placeH, p, v, param) => {
		return <label className={styles.Currency}>
			{placeH}
			<CurrencyInput
				placeholder={p}
				prefix="$"
				value={v}
				onValueChange={(value) => {
					// setRangeValue({ ...rangeValue, [param]: value })
					setFilters({ ...filters, [param]: value })
					setChange(true)
					setLoaded(true)
				}}
			/>
		</label>
	}
	const DefualtInput = (placeH, p, v, param) => {
		return <label className={styles.Currency}>
			{placeH}
			<input
				type={"number"}
				placeholder={p}
				value={v}
				min={min}
				max={max}
				onChange={(e) => {
					// setRangeValue({ ...rangeValue, [param]: value })
					setFilters({ ...filters, [param]: e.target.value })
					setChange(true)
					setLoaded(true)
				}}
			/>
		</label>
	}
	const renderInputCurrency = () => {
		return (
			<>
				{Currency("min price", "$1", rangeValue?.minRate, "minRate")}
				{Currency("max price", "$753", rangeValue?.maxRate, "maxRate")}
			</>
		)
	}
	const renderInput = () => {
		return (
			<>
				{DefualtInput("min hours", "1", rangeValue?.minHours, "minHours")}
				{DefualtInput("max hours", "73", rangeValue?.maxHours, "maxHours")}
			</>
		)
	}
	return (
		<>
			<Slider
				range
				defaultValue={currentrange}
				value={type === "price" ? [rangeValue.minRate, rangeValue?.maxRate] : [rangeValue?.minHours, rangeValue?.maxHours]}
				min={min}
				max={max}
				onChange={(e) => {
					if (type === "price") {
						setRangeValue({ ...rangeValue, "minRate": e[0], "maxRate": e[1] })
						setChange(true)
					} else {
						setRangeValue({ ...rangeValue, "minHours": e[0], "maxHours": e[1] })
						setChange(true)
					}
				}}
				onAfterChange={(e) => {
					if (type === "price") {
						setFilters({ ...filters, "minRate": e[0], "maxRate": e[1] })
						setChange(true)
						setLoaded(true)
					} else {
						setFilters({ ...filters, "minHours": e[0], "maxHours": e[1] })
						setChange(true)
						setLoaded(true)
					}
				}}
			/>
			<div className={styles.flexCont}>
				{type === "price" ? renderInputCurrency() : renderInput()}
			</div>
		</>
	)
}
export default SliderRange
