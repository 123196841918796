export const isBrowser = typeof window !== `undefined`
export const initMapkitJS = () => {
	if (isBrowser) {
		if (window.mapkit) {
			window.mapkit.init({
				authorizationCallback: function (done) {
					done('eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlMzMkpSNDhRRlQifQ.eyJpc3MiOiJTTUNHNDc0MkdEIiwiaWF0IjoxNzE1NTk1MjM2LjMzMiwiZXhwIjoxNzMxMzc0MDM2LjMzMn0.HKzi_56fb-tz5roFRJo06cOn9i8zBpeSWQD7PsqiUh8jkvXiumWNpnKVSXSgBd0pZ9iCkc2BQ4ui0CRQeByJJw')
				}
			})
		}
	}
};
export const clearFalseyValues = obj => {
	Object.keys(obj).forEach(key => (obj[key] == null || obj[key] == undefined || obj[key] == '' || obj[key] === []) && delete obj[key]);
	return obj;
};


export const getPlaces = (results, searchedText) => results.map(data => {
	const displayName = `${data.displayLines[0]}, ${data.displayLines[1]}`

	// if ((displayName.includes('United States') || displayName.includes('Search Nearby'))) {
	if (displayName?.toLowerCase()?.includes(searchedText?.toLowerCase())) {
		const display = displayName;
		const formatteddisplay = display.replace(', undefined', '')
		const state = !display.includes(', ') && !display.includes('Search Nearby') ? formatteddisplay : (states.find(item => item.Code === formatteddisplay.split(', ')[1]) || {}).State
		const place = {
			display: formatteddisplay,
			citystate: formatteddisplay,
			city: data.locality || formatteddisplay.split(', ')[0],
			state: data.administrativeArea || state,
			areaCode: data.administrativeAreaCode,
			postCode: data.postCode,
			data
		};
		if (data.coordinate) {
			place.latitude = data.coordinate.latitude;
			place.longitude = data.coordinate.longitude;
		}
		return place;
	}
}).filter(place => place !== undefined)
export const getGuideDetailsLink = (item={}, allGuides = [], extraParam,param, xAppID) => {
	 const id =  param === "id"?item.id:item.guideid;
	const currentGuide = allGuides.find(( node ) => node.guideid === id || node.guideid === id)
	let pathname = '-';
	if ((item.company || item.comp) && currentGuide) {
		const removeInBetweenQuotes = currentGuide.company?.replace(/['"]+/g, '')
		pathname = removeInBetweenQuotes;
		pathname = pathname?.trim();
		if (currentGuide.iscompanyduplicate && item.guidename) {
			let getAllCompanies = allGuides.filter(edge => item.company === edge.company)
			getAllCompanies = getAllCompanies.sort((a, b) => new Date(dayjs(b.user_profile_creat_date)) - new Date(dayjs(a.user_profile_creat_date)))
			if (getAllCompanies[0] && getAllCompanies[0].guideid !== id) {
				pathname = `${pathname}/${item.guidename}`
			}
		}
	}
	/* if (item.show_title && item.title && item.title !== "none") {
		pathname = pathname ? `${pathname}-${item.title}` : `${item.title}`;
	}
	pathname = `${pathname}-${item.guidename}` */
	pathname = pathname?.trim();
	pathname = pathname?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
	pathname = pathname?.replace(/ /g, "-");

	let link
	const url = apiHost.includes("staging") ? "https://staging.guidesly.com" : "https://guidesly.com"
	if (extraParam) {
		if (extraParam?.startDate) {
			link = `${url}/book-a-guide/guide-details/${pathname}?referrer=${xAppID}&tripDate=${extraParam?.startDate}&guests=${extraParam?.guest}`
		} else {
			link = `${url}/book-a-guide/guide-details/${pathname}?referrer=${xAppID}&guests=${extraParam?.guest}`
		}
	} else {
		link = `${url}/book-a-guide/guide-details/${pathname}?referrer=${xAppID}`
	}
	return link

}

const states = [
	{
		"State": "Alabama",
		"Abbrev": "Ala.",
		"Code": "AL"
	},
	{
		"State": "Alaska",
		"Abbrev": "Alaska",
		"Code": "AK"
	},
	{
		"State": "Arizona",
		"Abbrev": "Ariz.",
		"Code": "AZ"
	},
	{
		"State": "Arkansas",
		"Abbrev": "Ark.",
		"Code": "AR"
	},
	{
		"State": "California",
		"Abbrev": "Calif.",
		"Code": "CA"
	},
	{
		"State": "Colorado",
		"Abbrev": "Colo.",
		"Code": "CO"
	},
	{
		"State": "Connecticut",
		"Abbrev": "Conn.",
		"Code": "CT"
	},
	{
		"State": "Delaware",
		"Abbrev": "Del.",
		"Code": "DE"
	},
	{
		"State": "District of Columbia",
		"Abbrev": "D.C.",
		"Code": "DC"
	},
	{
		"State": "Florida",
		"Abbrev": "Fla.",
		"Code": "FL"
	},
	{
		"State": "Georgia",
		"Abbrev": "Ga.",
		"Code": "GA"
	},
	{
		"State": "Hawaii",
		"Abbrev": "Hawaii",
		"Code": "HI"
	},
	{
		"State": "Idaho",
		"Abbrev": "Idaho",
		"Code": "ID"
	},
	{
		"State": "Illinois",
		"Abbrev": "Ill.",
		"Code": "IL"
	},
	{
		"State": "Indiana",
		"Abbrev": "Ind.",
		"Code": "IN"
	},
	{
		"State": "Iowa",
		"Abbrev": "Iowa",
		"Code": "IA"
	},
	{
		"State": "Kansas",
		"Abbrev": "Kans.",
		"Code": "KS"
	},
	{
		"State": "Kentucky",
		"Abbrev": "Ky.",
		"Code": "KY"
	},
	{
		"State": "Louisiana",
		"Abbrev": "La.",
		"Code": "LA"
	},
	{
		"State": "Maine",
		"Abbrev": "Maine",
		"Code": "ME"
	},
	{
		"State": "Maryland",
		"Abbrev": "Md.",
		"Code": "MD"
	},
	{
		"State": "Massachusetts",
		"Abbrev": "Mass.",
		"Code": "MA"
	},
	{
		"State": "Michigan",
		"Abbrev": "Mich.",
		"Code": "MI"
	},
	{
		"State": "Minnesota",
		"Abbrev": "Minn.",
		"Code": "MN"
	},
	{
		"State": "Mississippi",
		"Abbrev": "Miss.",
		"Code": "MS"
	},
	{
		"State": "Missouri",
		"Abbrev": "Mo.",
		"Code": "MO"
	},
	{
		"State": "Montana",
		"Abbrev": "Mont.",
		"Code": "MT"
	},
	{
		"State": "Nebraska",
		"Abbrev": "Nebr.",
		"Code": "NE"
	},
	{
		"State": "Nevada",
		"Abbrev": "Nev.",
		"Code": "NV"
	},
	{
		"State": "New Hampshire",
		"Abbrev": "N.H.",
		"Code": "NH"
	},
	{
		"State": "New Jersey",
		"Abbrev": "N.J.",
		"Code": "NJ"
	},
	{
		"State": "New Mexico",
		"Abbrev": "N.M.",
		"Code": "NM"
	},
	{
		"State": "New York",
		"Abbrev": "N.Y.",
		"Code": "NY"
	},
	{
		"State": "North Carolina",
		"Abbrev": "N.C.",
		"Code": "NC"
	},
	{
		"State": "North Dakota",
		"Abbrev": "N.D.",
		"Code": "ND"
	},
	{
		"State": "Ohio",
		"Abbrev": "Ohio",
		"Code": "OH"
	},
	{
		"State": "Oklahoma",
		"Abbrev": "Okla.",
		"Code": "OK"
	},
	{
		"State": "Oregon",
		"Abbrev": "Ore.",
		"Code": "OR"
	},
	{
		"State": "Pennsylvania",
		"Abbrev": "Pa.",
		"Code": "PA"
	},
	{
		"State": "Rhode Island",
		"Abbrev": "R.I.",
		"Code": "RI"
	},
	{
		"State": "South Carolina",
		"Abbrev": "S.C.",
		"Code": "SC"
	},
	{
		"State": "South Dakota",
		"Abbrev": "S.D.",
		"Code": "SD"
	},
	{
		"State": "Tennessee",
		"Abbrev": "Tenn.",
		"Code": "TN"
	},
	{
		"State": "Texas",
		"Abbrev": "Tex.",
		"Code": "TX"
	},
	{
		"State": "Utah",
		"Abbrev": "Utah",
		"Code": "UT"
	},
	{
		"State": "Vermont",
		"Abbrev": "Vt.",
		"Code": "VT"
	},
	{
		"State": "Virginia",
		"Abbrev": "Va.",
		"Code": "VA"
	},
	{
		"State": "Washington",
		"Abbrev": "Wash.",
		"Code": "WA"
	},
	{
		"State": "West Virginia",
		"Abbrev": "W.Va.",
		"Code": "WV"
	},
	{
		"State": "Wisconsin",
		"Abbrev": "Wis.",
		"Code": "WI"
	},
	{
		"State": "Wyoming",
		"Abbrev": "Wyo.",
		"Code": "WY"
	}
]