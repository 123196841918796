import React from "react"
import { Modal } from 'react-bootstrap'
import  Styles1 from "../styles/modal.module.scss"
import ModalCloseBtn from "./ModalCloseButton"
const ModalComponent = ({ dialogClassName, show, from, size, hideHeader, onHide, header, children, headClass, headerStyle, closeButton, backstatic, commonHeader, id, classname, leftHeaderField }) => {
	const head = hideHeader ? hideHeader : false;
	const commonHead = commonHeader ? commonHeader : false;
	const back = backstatic ? backstatic : false;
	const Closebtn = closeButton ? closeButton : true;
	const isBrowser = typeof window !== `undefined`
	const isMobile = isBrowser && (/Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) || window.innerWidth <= 991 || ((window.screen || {}).width || 0) <= 991)
	const isId = id ? id : null
	const classStyle = classname ? classname : "modalVceter"
	return (
		<Modal
			show={show}
			size={size ? size : "sm"}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={onHide}
			id={isId}
			className={`${classStyle} FontStyle`}
			backdrop={back ? "static" : true}
			dialogClassName={dialogClassName ? dialogClassName : from === "Ratecard" ? Styles1.RCmodal : from === "calendar" ? Styles1.CalendarModal : from === "company" ? `${Styles1.modalCont} CompanyWrapper ` : from === "datepicker" ? Styles1.modalCont1 : Styles1.modalCont}
		>
			{!head && (<Modal.Header className={headClass ? `${headClass} d-flex justify-content-between` : `${Styles1.modalHead} d-flex justify-content-between`} style={headerStyle ? headerStyle : { padding: "10px" }} >
				{!leftHeaderField && <span></span>}
				<span>{header}</span>
				{Closebtn && <ModalCloseBtn handleClose={onHide} />}
			</Modal.Header>)}
			{commonHead && (<Modal.Header className={Styles1.commonModalHead} style={{ padding: "20px", color: '#000', justifyContent: "space-between" }} >
				{!leftHeaderField && <span></span>}
				<span>{header}</span>
				{Closebtn && <ModalCloseBtn handleClose={onHide} />}
			</Modal.Header>)}
			<Modal.Body style={from === "permissions" ? { padding: "0px" } : from === "company" && isMobile ? { height: "81vh", overflowY: "scroll" } : from === "company" ? { height: "87vh" } : null}>
				{children}
			</Modal.Body>

		</Modal >
	)
}
export default ModalComponent
