import React from "react";
import { Helmet } from "react-helmet";

const Fontstyle=({xAppID})=>{
    const getFont=(xAppID)=>{
        switch (xAppID) {
            case 'b7e5448c-edc9-11ec-847f-0aa12f2f2cfe':
                return<link
                key="roboto-font"
                href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
                rel="stylesheet"
            />
            default:
              return  <link
					key="roboto-font"
					href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
					rel="stylesheet"
				/>;
        }
    }
    return(
         <Helmet>
				{getFont(xAppID)}
		</Helmet> 
    )
}
export default Fontstyle;