import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    paddingBottom: 10,
    fontSize: 24,
    fontWeight: 500
  },
  reporttitle: {
    background: theme.palette.text.primary,
    fontSize: 22,
    fontWeight: 500,
    color: 'white',
    padding: '0 10px',
    marginBottom: 5
  },
  strong: {
    fontWeight: 400
  },
  paddingSides: {
    padding: '0 10px'
  },
  paddingSides1: {
    padding: '5px 0px'
  },
  verticalAlignCenter: {
    fontSize: 21,
    paddingRight: 5,
    color: theme.palette.text.subtext
  },
  date: {
    display: 'flex',
    flexDirection: 'row-reverse',
    borderTop: `1px solid ${theme.palette.border.primary}`,
    padding: 10,
    color: theme.palette.text.primary,
    fontSize: 16
  },
  root: {
    padding: 0,
    width: 1024,
    '@media screen and (max-width: 568px)': {
      width: '100%',
    },
  },
  marginTop: {
    marginTop: 20
  },
  img: {
    height: 108,
    width: 108,
    marginLeft: 10,
    marginRight: 10
  },
  horizontalAlignCenter: {
    justifyContent: 'center',
    display: 'flex',
    margin: 'auto'
  },
  subtext: {
    color: theme.palette.text.subtext
  },
  container: {
    display: 'flex',
    paddingBottom: 10,
    position: 'relative'
  },
  comments: {
    '& p': {
      margin: 0
    }
  },
  paddingBottom: {
    paddingBottom: 16
  },
  readmore: {
    color: theme.palette.hyperlink.primary,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  media: {
    height: 300,
    width: 300,
  },
  displayFlex: {
    display: 'flex',
    '@media screen and (max-width: 568px)': {
      display: 'block',
    },
  },
  bannerContainer: {
    backgroundSize: '100% 100%',
    textAlign: 'center',
    marginBottom: '20px'
  },
  labelRoot: {
    display: 'flex',
    paddingBottom: 10,
    width: '100%'
  },
  labelLabel: {
    fontWeight: '600',
    color: theme.palette.text.subtext,
    '@media screen and (max-width: 568px)': {
      width: '50%',
      fontWeight: '300',
    },
  },
  labelValue: {
    color: theme.palette.text.subtext,
    paddingLeft: 2,
    '@media screen and (max-width: 568px)': {
      width: '50%',
    },
  },
  title1: {
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: 500
  },
  padding: {
    padding: 10
  },
  padding1: {
    padding: 10,
    marginRight: 10,
    '@media screen and (max-width: 568px)': {
      marginRight: 0
    },
  },
  albummedia: {
    height: 250,
  },
  bolder: {
    position: "relative",
    height: "2px",
    background: "#42642f",
    bottom: "-2px"
  },
  lighter: {
    position: "relative",
    height: "2px",
    background: "#c7d9be",
    bottom: "0px"
  },
  cardSeparator: {
    height: 10,
    background: theme.palette.background.secondary,
    marginBottom: 10
  },
  cardSeparatorSM: {
    height: 5,
    background: theme.palette.background.secondary,
    marginBottom: 10
  },
  paddingTop: {
    paddingTop: 10
  },
  width: {
    width: '100%'
  },
  floatingIcon: {
    position: 'absolute',
    color: theme.palette.text.secondary,
    '-webkit-transform': 'rotate(180deg)',
    '-moz-transform': 'rotate(180deg)',
    '-ms-transform': 'rotate(180deg)',
    '-o-transform': 'rotate(180deg)',
    transform: 'rotate(180deg)'
  },
  positionAbsolute: {
    '-webkit-transform': 'rotate(180deg)',
    '-moz-transform': 'rotate(180deg)',
    '-ms-transform': 'rotate(180deg)',
    '-o-transform': 'rotate(180deg)',
    transform: 'rotate(180deg)'
  },
  item: {
    paddingRight: 40
  },
  borderAround: {
    border: `1px solid ${theme.palette.text.primary}`
  },
  tabColor: {
    color: `${theme.palette.text.primary} !important`
  },
  coverpicture1: {
    '@media screen and (max-width: 568px)': {
      marginTop: 10
    },
  },
  className: {
    width: 325,
    '@media screen and (max-width: 568px)': {
      width: '100%'
    },
  },
  tooltip: {
    fontSize: 16
  }
}));
