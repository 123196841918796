import React, { useRef, useState,  useEffect } from "react"
import Styles from "../styles/searchHeader.module.scss"
import HeaderSearchLayoutOne from "./headerSearchLayout1";
import Styles1 from "../styles/filterGuides.module.scss"
import dayjs from "dayjs";
import Icon from "react-icons-kit";
import { search } from "react-icons-kit/fa/search";
import HeaderSearchLayoutTwo from "./headerSearchLayout2";
// import SearchModal from "../../../home/SearchModal";
import FilterCategory from "../filterCategory";
import SearchModal from "./SearchModal";
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

let hasRendered = false;
const isBrowser = typeof window !== `undefined`
const SearchHeader = ({ searchQuery, Mobile, setSearchQuery, setZoomCount }) => {
	const node = useRef()
	const node1 = useRef()
	const [overlay, setOverlay] = useState(false);
	const [selection, setSelection] = useState(null);
	const [headerSearch, setHeaderSearch] = useState(false);
	const [selectedDay, setSelectedDay] = useState(null);
	const [guests, setGuests] = useState(searchQuery.guest??1);
	const [showSearchModal, setShowSeachModal] = useState(false)
	const [destination, setDestination] = useState({
		"address": null,
		"latitude": null,
		"longitude": null,
		"ne_lat": null,
		"ne_lng": null,
		"sw_lat": null,
		"sw_lng": null,
		"zoom": null,
		"mapDrag": false,
		"page": null
	});

	useEffect(() => {
		setSelectedDay(searchQuery.startDate?dayjs(searchQuery.startDate, "YYYY-MM-DD").format("YYYY-MM-DD"):null)
		setGuests(searchQuery.guest)
		setDestination({
			"address": searchQuery.address,
			"latitude": searchQuery.latitude,
			"longitude": searchQuery.longitude,
			"ne_lat": searchQuery?.ne_lat || null,
			"ne_lng": searchQuery?.ne_lng || null,
			"sw_lat": searchQuery?.sw_lat || null,
			"sw_lng": searchQuery?.sw_lng || null,
			'zoom': searchQuery?.zoom || null,
			"mapDrag": searchQuery?.mapDrag || false,
			"page": searchQuery?.page || null
		})
	}, [searchQuery])
	const [view, setView] = useState("")

	useEffect(() => {
		setTimeout(() => {
			setView(selection)
			if (isBrowser && selection != "location") {
				document.body.removeAttribute("style")
			} else if (isBrowser && selection === "location") {
				document.body.style.overflow = 'hidden';
			}
		}, 250)
		return () => {
			setView(null)
		}
	}, [selection])
	useEffect(() => {
		if (headerSearch) {
			hasRendered = true;
		} else {
			setSelection(null)
		}
	}, [headerSearch]);
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);
	const handleClickOutside = e => {
		if (node.current?.contains(e.target) || e.target.id === "desktopHam") {
			return;
		} else if (node1.current?.contains(e.target)) {
			return
		}
		setView(null)
		if (isBrowser && view != "location") {
			document.body.removeAttribute("style")
		}
		setHeaderSearch(false)
	};

	const handleSearch = () => {
		let query = {}
		if (destination?.address && destination?.address !== "") {
			query = { ...query, ...destination }
		}
		if (!["West Coast", "North East", "Mid West", "South East", "Rocky Mountains", "Gulf Coast"].includes(destination.address)) {
			const s = {
				"ne_lat": null,
				"ne_lng": null,
				"sw_lat": null,
				"sw_lng": null,
				"zoom": null,
				"mapDrag": false,
				"page": 1,
			}
			query = { ...query, ...s }
		}
		query = { ...query, "startDate": selectedDay ? selectedDay : null }

		if (guests && guests >= 1) {
			query = { ...query, "guest": guests }
		}
		setZoomCount(0)
		const final = { ...searchQuery, ...query }
		setSearchQuery(final)
	}
	return (
		<>
		<header className={Styles.Header}>
			<div className={Styles.insideHeader}>
				<div className={Styles.innerCont}>

					<div className={Styles.SearchCont}
						style={{
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
						}}
					>
						<HeaderSearchLayoutOne
							setSelection={setSelection}
							setHeaderSearch={setHeaderSearch}
							headerSearch={headerSearch}
							selectedDay={searchQuery.startDate}
							destination={searchQuery}
							guests={searchQuery.guest}
							setOverlay={setOverlay}
							styles={Styles}
						/>
						<div ref={node1}>
							<HeaderSearchLayoutTwo
								setSelection={setSelection}
								selection={selection}
								selectedDay={selectedDay}
								guests={guests}
								headerSearch={headerSearch}
								setGuests={setGuests}
								setSelectedDay={setSelectedDay}
								destination={destination}
								setDestination={setDestination}
								setHeaderSearch={setHeaderSearch}
								setOverlay={setOverlay}
								styles={Styles}
								handleSearch={handleSearch}
								view={view}
							/>
						</div>
					</div>
					
				</div>
				<div
					onClick={() => {
						setSelection(null);
					}}
					className={`${Styles.whitebox} ${headerSearch ? `${Styles.animationOn}` : hasRendered && `${Styles.animationOff}`}`}
				></div>
				{/* Mobile view */}
				<div className={`${Styles1.SubMenuBar} ${Styles1.HomeMobileBar} ${Styles1.searchText} ${Styles1.mobileView}`} onClick={() => setShowSeachModal(true)}>
					<span className={Styles1.iconContainer} >
						<Icon
							icon={search}
							className="Searchicon1"
							size={20}
						/>
					</span>
					<div className="searchText" style={{ width: "65%", }}>
						<div>	{searchQuery.mapDrag ? "Map Area" : searchQuery.address ? searchQuery?.address : "Where to?"}
						</div>
						<div style={{ display: "flex", alignItems: "center" }}>{`${searchQuery.startDate ? dayjs(searchQuery.startDate).format('MMM D') : `Any date`}`}
							<span className={Styles1.point}></span>
							{` ${searchQuery?.guest && searchQuery?.guest === 1 ? `${searchQuery?.guest} guest` :
								searchQuery?.guest && searchQuery?.guest > 1 ? `${searchQuery?.guest} guests` : `Add guests`}`}</div>
					</div>
					<span className={Styles1.iconContainer} >

					</span>
				</div>

				
			</div>
			{isBrowser && window.matchMedia("only screen and (min-width: 769px)").matches &&
				<FilterCategory searchQuery={searchQuery} setSearchQuery={setSearchQuery} setZoomCount={setZoomCount} />
			}
		</header>
			{showSearchModal && <SearchModal close={() => setShowSeachModal(false)} setZoomCount={setZoomCount} searchQuery={searchQuery}/>}
		</>
	)
}
export default SearchHeader
