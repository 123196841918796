import React, { useContext, useState, useEffect, useMemo,  } from 'react';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import TripDetailsContext from '../../state/context/TripDetailsContext';
import { displayMoney, getTotalCharge } from '../../utils/payment';
import { TripType } from '../../utils/guide-details';
import AddToCalendar from '../../common/addtocalendar/AddToCalendar'
import getSymbolFromCurrency from 'currency-symbol-map'
import useStyles from './styles';
import TripCharge from '../../common/TripCharge';
import '../payment/index.scss'
import { TextField, Typography } from '@mui/material';
import convertTo12Hour, { getDuration, getTimeFormat } from '../../utils/time';
import './index1.scss'
import moment from 'moment';
import { getVehicleDetails } from '../../rate-cards-widget/components/narrow/rate-cards';
import { post } from '../../utils/axiosHelper';
import ChatModal from '../personal-details/ChatModal';
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
const intialSign = {
  "name": null,
  "waiver_id": null,
  "accept": false
}
const getCorrectedImagePath = (image) => {
  if (image) {
    const correctPath = image.charAt(0) === '/' ? image.slice(1) : image
    return correctPath;
  }
}
export default function Confirmation({ isNarrow, isMultiTenantGuide, companyGuid }) {
  const classes = useStyles();
  const [email, setEmail] = useState({ items: [], value: "", error: null });
  const tripDetailsContext = useContext(TripDetailsContext);
  const [confirmationName, setConfirmationName] = useState('');
  const [referral, setReferral] = useState({});
  const [showWaiver, setShowWaiver] = useState(false);
  const [signature, setSignature] = useState(intialSign)
  const [docs, setDocs] = useState([])  
  const [errmsg, setErrMsg] = useState(null)
  const isBrowser = typeof window !== 'undefined'
  const isMobile = isBrowser && window.innerWidth <= 600
  const {
    selectedHour,
    selectedRateCard,
    guestCount,
    addOnFees,
    paymentCardDetails,
    tipamount,
    guideDetails,
    depositAmt,
    selectionType,
    referralGuideName,
    confirmationRate,
    referralLocation, setReferralLocation,
    referralCompany, setReferralCompany,
    referralConfirmationName,setReferralConfirmationName,
    waiver,addonAmt
  } = tripDetailsContext;

  let total;
  let chargeToday;
  let chargeOnDayOfTrip;
  let totalIncludingMatesFees;

  useEffect(() => {
    if (isBrowser) {
      window.scroll(0, 0)
    }
  }, [])
  useEffect(() => {
    if (waiver) {
      const fileObj = {}
      const name = waiver.file_path
      const uri = `https://guidesly-assets.s3.us-east-2.amazonaws.com/${getCorrectedImagePath(waiver.file_path)}`
      const type = name.split('.').pop()
      fileObj.fileName = waiver.file_name
      fileObj.uri = uri
      fileObj.fileData = uri
      fileObj.fileType = type
      setDocs([fileObj])
      if(!waiver?.trips){
        setSignature({ ...signature, "waiver_id": waiver?.id })
        setShowWaiver(true)
      }
    }
  }, [waiver])
  useEffect(() => {
    if (referralGuideName && selectedRateCard) {
      if(selectedRateCard?.is_group) {
        setConfirmationName(referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.guidename)
      }
      else {
        setConfirmationName(referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.guidename)
      }
    }
  }, [referralGuideName, selectedRateCard])
  useEffect(() => {
    if (referralGuideName && selectedRateCard) {
      if(selectedRateCard?.is_group) {
      setReferralCompany(referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.company)
      setReferralLocation(referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.display_location)
      //setReferralReview(referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.rating)
      setReferralConfirmationName(`${referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.title} ${referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.guidename}`)
      }
      else {
        setReferralCompany(referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.company)
        setReferralLocation(referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.display_location)
        //setReferralReview(referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.rating)
      setReferralConfirmationName(`${referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.title} ${referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.guidename}`)
      }
    }
  }, [referralGuideName, selectedRateCard])

  const tipAmount = tipamount && tipamount !== 'uponCash' ? Number(tipamount) : 0
  const checkCompany = isMultiTenantGuide && companyGuid && companyGuid != ""
  if (selectedHour.rate) {
    total = getTotalCharge(
      selectedRateCard,
      guestCount,
      selectedHour.rate,
      addOnFees
    );

    let fees = addOnFees.map(item => isNaN(item.charge_price) ? 0 : item.charge_price);
    fees = fees.reduce((a, b) => a + b, 0)

    totalIncludingMatesFees = total + fees + tipAmount+addonAmt
    let custom__deposit = checkCompany ?tripDetailsContext.selectedRateCard?.custom_deposit_percentage>0 ? tripDetailsContext.selectedRateCard?.custom_deposit_percentage/100 :depositAmt?(depositAmt/100):0.2 :selectedRateCard && selectedRateCard?.custom_deposit_percentage>0 ? selectedRateCard?.custom_deposit_percentage/100 :0.2
    chargeToday = ((total + fees+addonAmt) * (tripDetailsContext.selectedRateCard?.is_referral ? 0.2 : custom__deposit)).toFixed(2)

    chargeOnDayOfTrip = totalIncludingMatesFees - chargeToday;
  }

  const isValid = (mail) => {
    let error = null;

    if (isInList(mail)) {
      error = `${mail} has already been added.`;
    }

    if (!isEmail(mail)) {
      error = `${mail} is not a valid email address.`;
    }

    if (error) {
      setEmail({
        items: email.items,
        value: email.value,
        error: error
      });
      // setEmail({ error });
      return false;
    }

    return true;
  }

  const isInList = (e) => {
    if (email.items) {
      return email.items.includes(e);
    } else {
      return false
    }
  }

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  const handleSendEmail = () => {
    var formData = {};

    const vals = {
      type: "email",
      source: "trip_confirmation",
      ref_id: tripDetailsContext.bookingID,
      emails: email.items
    }

    formData.event = vals
    post('send_notification', formData, {
      headers: {
        "Authorization": `Bearer ${tripDetailsContext.token}`
      }
    })
      .then(() => {
        // console.log('alert sent')
        alert("Email sent")
      })
  }

  const handleDelete = item => {
    setEmail({
      items: email.items.filter(i => i !== item)
    });
  };

  useEffect(() => {
    if(confirmationRate && referralGuideName) {
      if(confirmationRate?.is_group) {
        setReferral(referralGuideName.filter(a => a.group_guid == confirmationRate.guideguid))
        } else {
          setReferral(referralGuideName.filter(a => a.guideguid == confirmationRate.guideguid))
        }
    }

  },[confirmationRate, referralGuideName])


  const handleKeyDown = evt => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();
      var value = email.value.trim();

      if (value && isValid(value)) {
        if (email.items) {
          setEmail({
            items: [...email.items, email.value],
            value: ""
          });
        } else {
          setEmail({
            items: [email.value],
            value: ""
          });
        }
      }
    }
  };

  const handleChange = evt => {
    if (email.items) {
      setEmail({
        items: email.items,
        value: evt.target.value,
        error: null
      });
    } else {
      setEmail({
        value: evt.target.value,
        error: null
      });
    }
  };

  const handlePaste = evt => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter(email => !isInList(email));

      setEmail({
        items: [...email.items, ...toBeAdded]
      });
    }
  };

  const disableBtn = email && email.items && email.items.length !== 0 ? false : true

  let hours = moment(tripDetailsContext.selectedHour.start_time, "h:m:s").hour()
  let minutes = moment(tripDetailsContext.selectedHour.start_time, "h:m:s").minute()
  let endhours = 0
  let eminutes = 0
  if (Number.isInteger(tripDetailsContext.selectedHour.duration_hours)) {
    endhours = hours + moment.duration(tripDetailsContext.selectedHour.duration_hours, "hours").hours()
    eminutes = minutes
  }
  let start = moment(tripDetailsContext.selectedDay).set({ 'hour': hours, 'minute': minutes }).toISOString()
  let end = moment(tripDetailsContext.selectedDay).set({ 'hour': endhours, 'minute': eminutes }).toISOString()

  let event = {
    name: tripDetailsContext.selectedRateCard.title,
    details: tripDetailsContext.selectedRateCard.description,
    location: tripDetailsContext.meeetingPoint?.description,
    startsAt: start,
    endsAt: end
  };
  const getDiff = (date) => {
    var a = moment(new Date());
    var b = moment(date);

    var years = a.diff(b, 'year');
    b.add(years, 'years');

    var months = a.diff(b, 'months');
    b.add(months, 'months');

    return years === 1 ? `${years} Yr` : years > 1 ? `${years} Yrs` : years === 0 && months > 1 ? `${months} Months` : `${months} Month`
  }
  let icon = { textOnly: 'none' };
  const handleSaveWaiver = () => {
    const token = tripDetailsContext?.userToken
    if (signature?.accept && !signature?.name) {
      setErrMsg("Please type your name as Digital Signature to accept the Waiver agreement")
    } else if (!signature?.accept && signature?.name) {
      setErrMsg("Please select the checkbox - I have carefully read and understood Waiver agreement to accept the Waiver agreement. I consent to type in my full name as digital signature.")
    } else if (signature?.name && signature?.waiver_id && signature?.accept) {
      const formData = {
        "tripguid": tripDetailsContext?.tripguid,
        "signature": signature?.name
      }
      // setLoading(true)
      post(`guide/waiver/sign/${signature?.waiver_id}`, formData, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
      .then((res) => {
        // setLoading(false)
        setErrMsg(null)
        setShowWaiver(false)
        tripDetailsContext.setWaiver({ ...waiver, "trips": true })
      })
      .catch(err => {
        // setLoading(false)
        console.log(err, "err")
      })
    } else {
        setErrMsg("Please select the checkbox and enter your name as digital signature to accept the Waiver agreement.")
      }
  }
  
  const getDocsviewer = useMemo(() => {
		return  <DocViewer
              pluginRenderers={DocViewerRenderers}
              documents={docs}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: false
                }
              }}
              style={{ height: "initial" }}
            />
	}, [docs])
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography style={{ fontSize: isNarrow ? 28 : 24, fontWeight: '' }}>Thanks, {tripDetailsContext.firstName} {tripDetailsContext.lastName}! Your trip has been booked with {confirmationRate?.is_referral ? confirmationName: guideDetails?.company}.</Typography>
        <Typography style={{ fontSize: 24, fontWeight: '' }}>We have emailed you all the details for your trip.</Typography>
      </Grid>
      <Grid container>
      {tripDetailsContext.selectedRateCard.coverpicture && tripDetailsContext.selectedRateCard.coverpicture != "" && 
      <Grid item xs={12} sm={12} md={4} lg={4} className={`${classes.center} ${classes.details}`} style={{ marginBottom: isNarrow ? 10 : 0, height: '100%'}}>
        <div className={classes.media} style={{
            backgroundImage: `url('${cloudfront}${tripDetailsContext.selectedRateCard.coverpicture}')`,
            height: '100%',
            width: '100%',
            backgroundSize: 'cover'
        }}
          ></div>
      </Grid>}
      <Grid item xs={12} sm={12} md={8} lg={8} className={classes.details}>
        <Grid container style={{ marginLeft: isNarrow ? 0 : 10, borderRadius: 5 }} className={`${classes.rateCardContainer} paymentDetails`}>
          <Grid style={{ padding: '5%' }} item xs={12} sm={12} md={6} lg={6}>
            <Typography style={{ fontSize: isNarrow ? 20 : 18, fontWeight: 'bolder' }}>
              Booking ID: {tripDetailsContext.bookingID}
            </Typography>
            <Typography style={{ fontSize: isNarrow ? 20 : 18, fontWeight: 'bolder' }}>
              Payment Details
            </Typography>
            <TripCharge isNarrow={isNarrow} from="summary" checkCompany={checkCompany}/>
          </Grid>
          <Grid style={{ padding: '5%', borderLeft: isNarrow ? 'none' : '1px solid #c7cbce', margin: 'auto 0' }} item xs={12} sm={12} md={6} lg={6}>
            <Typography style={{ fontSize: 18, fontWeight: '' }}>
              <span><img width="40" height="40" style={{ position: 'relative', top: 10, marginRight: 10 }} src="https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/mastercard.png" /></span>
              <span className={classes.cardName}>{paymentCardDetails.brand}</span>
            </Typography>
            <Typography>
              ending with
              <div> .... .... .... {paymentCardDetails.last4}</div>
            </Typography>
            <Typography>
              {`was charged ${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}${chargeToday}`}
            </Typography>
            <Typography>
              on {moment(new Date()).format('MMMM, DD, YYYY')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      </Grid>
      <Grid className={classes.rateCardContainer} container style={{ marginTop: 10, borderRadius: 5 }} item xs={12} sm={12} md={12} lg={12}>
        <Typography style={{ paddingLeft: 15, paddingTop: 15, paddingBottom: 5, fontSize: isNarrow ? 20 : 18, fontWeight: 'bolder' }}>
          Trip Details
        </Typography>
        <Grid container>
          <Grid item style={{ padding: 15 }} xs={12} sm={12} md={4} lg={4}>
            {checkCompany && <div>
              <Typography style={{ fontWeight: 'bold' }}>
                {selectionType?.type}
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {selectionType?.name}
              </Typography>
              {selectionType?.type === "Guide" && selectionType?.data?.experience_date && <Typography >
                {getDiff(selectionType?.data?.experience_date)} Experience
              </Typography>}
              {selectionType?.type != "Guide" && selectionType?.data?.guest_capacity && <Typography >
                Guests Capacity {selectionType?.data?.guest_capacity}
              </Typography>}
              {selectionType?.type === "Guide" && selectionType?.data?.display_location && selectionType?.data?.display_location != "" && <Typography >
                {selectionType?.data?.display_location}
              </Typography>}
              {selectionType?.type != "Guide" && selectionType?.data?.length && <Typography >
                Length {selectionType?.data?.length}ft
              </Typography>}
            </div>}

            {!checkCompany && <>
              <Typography style={{ fontWeight: 'bold' }}>
                {!confirmationRate?.is_referral ? `${guideDetails?.title} ${guideDetails?.guidename}` : `${referral?.[0]?.title} ${referral?.[0]?.guidename}`}
              </Typography>
              <Typography >
                {confirmationRate?.is_referral ? `${referralCompany} (Booking Partner of ${guideDetails?.company})` : `${guideDetails?.company}`}
              </Typography>
              <Typography >
                {!confirmationRate?.is_referral ? `${guideDetails?.location1}` : `${referralLocation}`}
              </Typography>
            </>}


            <Typography style={{ marginTop: 15, fontWeight: 'bold' }}>
            {confirmationRate.title}
            </Typography>
            <Typography>
              {moment(tripDetailsContext.selectedDay).format('dddd, MMM DD, YYYY')}
            </Typography>
            <Typography>
              {`${convertTo12Hour(selectedHour.start_time)} ${getDuration(selectedHour)} Trip`}
            </Typography>
            <Typography>
              {TripType().find(item => item?.key?.toLowerCase() === tripDetailsContext?.selectedRateCard?.ratetype?.toLowerCase())?.label}
            </Typography>
            {(tripDetailsContext.selectedRateCard.has_boat && tripDetailsContext.selectedRateCard.activity === 'fishing') ? <Typography>
              {tripDetailsContext.selectedRateCard.length}{`' `}{(tripDetailsContext.selectedRateCard.manufacturer_name !="Other" && tripDetailsContext.selectedRateCard.manufacturer_name !="Others")?tripDetailsContext.selectedRateCard.manufacturer_name: tripDetailsContext.selectedRateCard.other_manufacturer}
            </Typography> : null}

            {(tripDetailsContext.selectedRateCard.activity === 'hunting') ? <Typography>
              {getVehicleDetails()}
            </Typography> : null}
            <Typography>
              {tripDetailsContext.guestCount} {`${tripDetailsContext.guestCount === 1 ? 'Guest' : 'Guests'}`}
            </Typography>
          </Grid>

          {!checkCompany && <Grid item style={{ padding: 15 }} xs={12} sm={12} md={4} lg={4}>
            {tripDetailsContext.preferedWaterbody?.rivername && tripDetailsContext.selectedRateCard.activity === 'fishing' &&  <div>
              <Typography>
                Preferred Waterbody:
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {tripDetailsContext.preferedWaterbody?.rivername}
              </Typography>
            </div>}
            {tripDetailsContext.secondFavoriteWaterbody?.rivername && tripDetailsContext.selectedRateCard.activity === 'fishing' ? <div style={{ marginTop: 15 }}>
              <Typography>
                2nd Waterbody:
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {tripDetailsContext.secondFavoriteWaterbody?.rivername}
              </Typography>
            </div> : null}

            {tripDetailsContext.meeetingPoint.description && <div style={{ marginTop: 15 }}>
              <Typography>
                Meeting Point:
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {tripDetailsContext.meeetingPoint.description}
              </Typography>
            </div>}
          </Grid>}

          <Grid item style={{ padding: 15 }} xs={12} sm={12} md={4} lg={4}>
            <div>
              <button style={{ width: '100%', marginBottom: 10, marginLeft: 0 }} onClick={() => window.print()} className="printButton"> Print</button>
            </div>
            <div>
              <AddToCalendar
                event={event}>
              </AddToCalendar>
            </div>
            {waiver && <>{
              isMobile ? <button onClick={() => setShowWaiver(true)} className={"printButton "}
                style={{ marginTop: "30px" }}>Waiver Agreement (optional)</button>
                : <div className={"Waiver"}>
                  {!waiver?.trips && <input type="checkbox" checked={showWaiver} onChange={(e) => { setShowWaiver(e.target.checked) }} />}
                  {!waiver?.trips ? <p>Agree to <span onClick={() => setShowWaiver(true)}>Waiver Agreement Form</span> (optional)
                  </p> :
                    <p>You have Agreed to <span onClick={() => setShowWaiver(true)}>Waiver Agreement Form</span></p>
                  }
                </div>
            }
            </>
            }
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.rateCardContainer} container style={{ marginTop: 10, borderRadius: 5 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography style={{ fontSize: '20px', margin: '20px' }}>Forward this confirmation to a friend</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div>
            {email.items && email.items.map((item) => (
              <div className={classes.tagItem} key={item}>
                {item}
                <div>
                  <button
                    type="button"
                    className={classes.deletebtn}
                    onClick={() => handleDelete(item)}
                  >
                    &times;
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div style={{ margin: 10, }}>
            <TextField
              variant="outlined"
              required
              id="email"
              aria-describedby="Email Address"
              placeholder="Email Address"
              value={email.value}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onPaste={handlePaste}
              classes={{
                input: classes.input
              }}
              className={classes.input}
              style={{ height: 30, width: '60%', borderRadius: 0 }}
            />
            <button
              onClick={handleSendEmail}
              disabled={disableBtn}
              style={{
                height: 56,
                minWidth: '35%',
                marginLeft: 0,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                color: 'white'
              }}
              className="bookNowBtn"
            >
              Forward
            </button>
          </div>
        </Grid>
      </Grid>
      {showWaiver && <ChatModal
      size={"lg"}
        show={showWaiver}
        onHide={() => { setShowWaiver(!showWaiver); 
          setErrMsg(null) }}
        header="Agree Waiver"
        style1={"dynamicModal"}>
          <div className={"modalHeader"}>
            <p>Agree Waiver</p>
          <Icon icon={x}  size={20} onClick={() => { setShowWaiver(!showWaiver); 
              setErrMsg(null) }} />
          </div>
        <div className={`waiverBody`}>
          <p>Read and understand the terms below.</p>
          <div className={`WaiverContent`}>
            {docs && getDocsviewer}
          </div>
          <div className={`Waiver`} style={{ alignItems: "center" }}>
            <input type="checkbox" checked={signature?.accept} onChange={(e) => {
              setSignature({ ...signature, "accept": e.target.checked })
            }}
              disabled={waiver?.trips ? true : false} />
            <p>I have read and understand the Waiver Agreement Form.
            </p>
          </div>
          <div className={`Signature`}>
            <div className={`signText`}>
              <p>{waiver?.trips ? "Signed" : "Sign Here"}</p>
            </div>
            <input type="text" value={signature?.name}
              disabled={waiver?.trips ? true : false}
              onChange={(e) => {
                setSignature({ ...signature, "name": e.target.value })
              }} />
            <div className={`SignPlaceholder`}>
            </div>
            <span>Enter your full name as your signature.</span>
          </div>
          {errmsg && <p className={"errWMessage"}>{errmsg}</p>}
          {!waiver?.trips && <div className={`BtnCont`}>
            <button className={"printButton "} style={{ height: "35px" }} onClick={() => {
              setShowWaiver(!showWaiver);
              setSignature(intialSign)
              setErrMsg(null)
            }}>Cancel</button>
            <button className={"printButton"} style={{ height: "35px" }} onClick={() => handleSaveWaiver()}
             >Accept the Waiver</button>
          </div>}
        </div>
      </ChatModal>}
    </Grid>
  );
}
