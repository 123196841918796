import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { options, gradVal  } from './constants';
import useStyles from './styles'
import './styles.scss'

const Progressbar = ({value, month }) => {
    const classes = useStyles()
    const findMonth = (id) => {
        var month, i;
        for (i = 0; i < options.length; i++) {
            if (options[i].id === id) {
                month = options[i].label
            }
        }
        return month;
    }

    const displayChart = (data) => {
        var sortedData = data.sort((a, b) => a.title.localeCompare(b.title))
        return sortedData.map(item => {
            return item.availability_scale.map(monthCalender => {
                return (
                    <>
                        {month === findMonth(monthCalender.month) && (
                            <Grid container className={'monthlyCal'} >
                                <Grid item className={classes.fishTitle} xs={3} sm={3} md={3} lg={2}>
                                   <Typography className={classes.fishName} variant="body1">{item.title}</Typography>
                                </Grid>
                                <Grid item className={'guidesly-fishImage leftright'} xs={3} sm={3} md={3} lg={2}>
                                    <img className={'fishImage'} src={`${item.url}`} alt={item.title} />
                                </Grid>
                                <Grid className={'bgShadow'} item xs={6} sm={6} md={6} lg={8} > 
                                    {
                                        monthCalender.availability === null || monthCalender.availability === 0 ?
                                        <Typography className={classes.nodata} variant="body1">Out of Season</Typography> :
                                        setProgressBar(monthCalender.availability)
                                    }
                                </Grid>
                            </Grid>
                        )}
                    </>
                )
            })
        })
    }
    
    const displayStatus = val => {
		let status;
		switch(val) {
			case 1:
				status = "Poor"
			  break;
			case 2:
				status = "Average"
			  break;
			case 3:
				status = "Good"
			  break;
			case 5:
				status = "Good"
			  break;
			case 6:
				status = "Excellent"
			  break;
			case 4:
				status = "Excellent"
		}
		return <Typography className={classes.status} variant="body1">{status}</Typography> 
	}

    const setProgressBar = val => {
        var newval;
		newval = val
		if(val === 5)
			newval = 3
		if(val === 6)
			newval = 4
        return gradVal.map(item => {
            return (
                item.set.includes(val) && (
                    <div style={{ width: calculatePercentage(newval) + "%" }} className={item.style} >{displayStatus(newval)}</div>
                )
            );
        });
    };
    
    const calculatePercentage = val => {
        return Math.round((val * 100) / 4);
    };

    return (
        <>
           {
			    displayChart(value)
		   }
        </>
    );
}

export default Progressbar;
