import React, {
	useState,
	useContext,
	useEffect,
} from "react"
import { useLoadScript, GoogleMap, InfoWindow, Polygon, OverlayView } from "@react-google-maps/api"
import { Image, Form } from "react-bootstrap"
import  Styles from "./styles/map.module.scss"
import axios from "axios"
import getSymbolFromCurrency from 'currency-symbol-map'
// import { getGuideDetailsLink, GetAllGuidesFromStrapi } from "../../utils"
import Icon from "react-icons-kit"
import { repeat } from 'react-icons-kit/fa/repeat'
import { chevronLeft, chevronRight } from 'react-icons-kit/feather'
import { star } from 'react-icons-kit/fa/star'
import debounce from "lodash/debounce"
import moment from "moment";
import MapContext from "../../../widgets/state/context/MapContext";
import { clearFalseyValues, getGuideDetailsLink } from "../../../widgets/common/utils"
import { boatDetailsGuests, displayMoney } from "./common/data/data"
const isBrowser = typeof window !== `undefined`
const defaultMapOptions = {
	disableDefaultUI: true,
	gestureHandling: "greedy",
};
const defaultMapOptions1 = {
	mapTypeControl: true,
	mapTypeControlOptions: true,
	gestureHandling: "cooperative",
};

let source;
let timerid;
const MapNew = (props) => {
	const{allGuides}=props
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: MAPS_KEY
	})
	const MapInfo = useContext(MapContext);
	const location = isBrowser&&window.location;
	const [mapRef, setMapRef] = useState(null)
	const [isOpen, setIsOpen] = useState("")
	const [mapScroll, setMapScroll] = useState(true)
	const [markerId, setMarkerId] = useState({})
	const [showSearch, setShowSearch] = useState(false)
	const [positions, setPositions] = useState([])
	const [isDrag, setIsDrag] = useState(false)
	

	const getViewportData = (bounds) => {
		const data = {
			"north": Number(bounds?.northeast?.lat),
			"east": Number(bounds?.northeast?.lng),
			"south": Number(bounds?.southwest?.lat),
			"west": Number(bounds?.southwest?.lng),
		}
		return data;
	}
	const getBoundsByAPI = async (fitBoundCity) => {
		const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${fitBoundCity}&key=${MAPS_KEY}`)
		const results = response.data.results
		const { viewport, bounds, location } = results?.[0]?.geometry
		if (results) {
			const boundData = bounds?bounds:viewport
			const GLOBE_WIDTH = 256; // a constant in Google's map projection
			const west = boundData.southwest.lng;
			const east = boundData.northeast.lng;
			const angle = east - west;
			const pixelWidth = 256
			if (angle < 0) {
				angle += 360;
			}

			let zoom = Math.round(Math.log(pixelWidth * 360 / angle / GLOBE_WIDTH) / Math.LN2);

			zoom += 1
			mapRef.setCenter({ lat: location.lat, lng: location.lng });
			mapRef.setZoom(zoom)
			const boundsNew = mapRef.getBounds()
			// MapInfo.setMapBounds(bounds)
			if ((fitBoundCity && boundsNew) || props.pageChanged) {
				MapInfo.setGuidesAPICalled(true)
				MapInfo.setLoadingMask(true)
				// setZoomChange(true)
				debounce(CallApi, 500)(props.searchParams, "searchPage")
				{ !props.from && props?.from != "article" && props.setPageChanged(false) }
			}
		}
	}
	useEffect(() => {
		if (isBrowser) {
			if (mapRef && props.searchParams && props.searchParams.latitude) {
				props.setMapLoading && props.setMapLoading(true)
				if (props.searchParams?.mapDrag) {
					MapInfo.setSearchedValue("Map Area")
				} else {
					MapInfo.setSearchedValue(props.searchParams?.address || "")
				}
				MapInfo.setGuidesAPICalled(true)
				if (props.searchParams?.ne_lat) {
					// setLocSearch(true)
					debounce(CallApi, 500)(props.searchParams, "searchPage")
					MapInfo.setLoadingMask(true)
				} else if ((props.searchParams.address || props.searchParams.city || props.searchParams.state) || props.from === "article") {
					var fitBoundCity = "";
					// 	(true)
					if (props.from === "article") {
						fitBoundCity = props.searchParams.address
					} else {
						if ((props.searchParams.address) && (!props.searchParams.city) && (!props.searchParams.state)) {
							fitBoundCity = props.searchParams.address
						} else if (props.searchParams.city && props.searchParams.state && (props.searchParams.city != props.searchParams.state)) {
							fitBoundCity = props.searchParams.city + ',' + props.searchParams.state;
						} else if (props.searchParams.city) {
							fitBoundCity = props.searchParams.city
						} else if (props.searchParams.state) {
							fitBoundCity = props.searchParams.state + "," + "united states"
						}
					}
					// MapInfo.setToFitBoundCity(fitBoundCity)
					if (fitBoundCity && fitBoundCity != "") {
						getBoundsByAPI(fitBoundCity);
						// await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${fitBoundCity}&key=${MAPS_KEY}`)
						// 	.then((response) => {
						// 		const results = response.data.results
						// 		const { viewport, bounds } = results?.[0]?.geometry
						// 		if (results) {
						// 			mapRef.fitBounds(getViewportData(viewport) || getViewportData(bounds));
						// 		}
						// 	})
						// 	.catch(err => console.log(err))

					}
				} else {
					// const bounds = mapRef?.getBounds()
					MapInfo.setGuidesAPICalled(true)
					// bounds && MapInfo.setMapBounds(bounds)
					MapInfo.setLoadingMask(true)
					debounce(CallApi, 500)(props.searchParams, "searchPage")
					props.setPageChanged(false)
				}
			}
		}
	}, [mapRef, props.searchParams])
	const common = {
		textAlign: "center",
		boxShadow: "rgb(0 0 0 / 4%) 0px 0px 0px 1px, rgb(0 0 0 / 18%) 0px 2px 4px",
		transformOrigin: " 50% 50%",
		transition: "transform 150ms ease 0s",
		borderRadius: "15px",
		width: "max-content",
		padding: "3px 8px",
		fontWeight: "bold",
		cursor: "pointer",
		fontSize: "13.5px",
	}
	const hover = {
		...common,
		backgroundColor: "#1A04EC",
		color: "white",
		zIndex: 99999,
		transform: 'scale(' + 1.1 + ')',
		position: "fixed",
	}
	const normal = {
		...common,
		border: "1px solid lightgray",
		backgroundColor: "white",
		transform: 'scale(' + 1 + ')',
	}
	const getName = (name) => {
		const rec = name.split(" ")
		if (rec.length > 1) {
			return rec[0] + " " + rec[1].charAt(0)
		} else {
			return name
		}
	}
	const mouseOverOnMarker = (guide) => {
		MapInfo.setMarkerOnClick(guide.guid)
		setIsOpen(guide.guid)
		MapInfo.setMarkerOnHover(guide.guid)
		MapInfo.setHoverCardId(guide.guid)
	}

	// triggers on mouse out from the map marker
	const mouseOutFromMarker = () => {
		setMarkerId("")
		setIsOpen("")
	}
	const getGuideCurrency = (guideID, allGuides = []) => {
		const item = guideID || {}
		if(allGuides.length > 0){
			const currentGuide = allGuides.find( node  =>  node.guideid === item.id ||node.guideid === item.guideid )
			return currentGuide?.node?.currency
			
		}

	}
	const renderAllMarkers = () => {
		var markers = [];
		{
			markers = MapInfo.guidePins && MapInfo.guidePins.length > 0 && MapInfo.guidePins.map((guide, index) => {
				let PolyPath = null
				if (guide && guide.geojson.features[0].geometry) {
					var arr = guide.geojson.features[0].geometry.coordinates[0].map(obj => ({
						lat: obj[1], lng: obj[0]
					}))
					PolyPath = arr
				}
				var POIpath = {}
				POIpath = { lat: Number(guide.lat), lng: Number(guide.lng) }
				const showSnippet = (guide) => {
					return (
						<div
							onMouseOver={() => {
								if (isBrowser && window.matchMedia("only screen and (max-width: 500px)").matches) {
									return null
								} else {
									mouseOverOnMarker(guide)
								}
							}
							}
							ref={ref => ref && window.google.maps.OverlayView.preventMapHitsFrom(ref)}
							onClick={() => {
								if (isBrowser && window.matchMedia("only screen and (max-width: 500px)").matches) {
									mouseOverOnMarker(guide)
									MapInfo.setTapCard(guide.guid)
								}
							}
							}
							onMouseOut={mouseOutFromMarker}
							style={guide.guid === MapInfo.hoverCardId || guide.guideguid === markerId ? hover : normal}
						>
							{`${(getSymbolFromCurrency(getGuideCurrency(guide, allGuides)) || '$')}${guide.rate ? displayMoney(guide.rate) : 0}`}
						</div>
					)
				}
				return (
					<OverlayView
						key={index}
						position={POIpath}
						mapPaneName="overlayMouseTarget"
						getPixelPositionOffset={(width, height) => ({
							x: -22,
							y: 0,
						})}
					>
						<>
							{isBrowser && window.matchMedia("only screen and (max-width: 500px)").matches ? showSnippet(guide)
								: <a href={
									getGuideDetailsLink(guide, allGuides,null, "id", props.xAppID)
                                }
                                     target='_blank' className={Styles.guideCardLink}>
									{showSnippet(guide)}
								</a>
							}

							{(guide.guid === MapInfo.markerOnClick) && isBrowser && window.matchMedia("only screen and (min-width: 768px)").matches && guide.guid === isOpen ?
								<>
									<InfoWindow
										zIndex={9999}
										position={POIpath}
										options={{ disableAutoPan: false, maxWidth: guide?.comp?.length > 75 ? "250px" : "200px" }}
									>
										<div className={Styles.windowContainer} style={{ width: guide?.comp?.length > 75 ? "270px" : "200px" }}>
											{guide?.guidepic ?
												<Image src={`${cloudfront}` + guide?.guidepic + ''} width='50px' height="50px" className={Styles.Pic} rounded /> :
												<div className={Styles.Pic} style={{
													background: "#224041", backgroundRepeat: "no-repeat", backgroundPosition: "top", backgroundSize: "cover", borderRadius: '5px', textAlign: "center", width: 85, height: 85
												}}>
													<span style={{ color: "#FFF", lineHeight: "85px", fontSize: "30px" }} >{getName(`${guide?.comp && guide?.comp != "" ? guide?.comp : guide?.d_name}`).split(" ").map((n) => n[0]).join("").toUpperCase()}</span>
												</div>}
											<div className={Styles.details}>
												<p className={Styles.name}>{guide?.comp && guide?.comp != "" ? guide?.comp : guide?.d_name}</p>

												{
													guide?.display_location && guide?.display_location !== null ?
														<div style={{ display: "flex", marginBottom: 1 }} >
															<p className={`${Styles.recs} ${Styles.textlimit}`}>
																{guide.display_location}
															</p>
														</div> : guide?.city !== null && guide?.state !== null ?
															<div style={{ display: "flex", marginBottom: 1 }} >
																<p className={`${Styles.recs} ${Styles.textlimit}`} style={{ fontSize: "12px" }}>
																	{guide.city === null && guide.state === null ? "" : guide.city !== null && guide?.state === null ? `${guide.city}` :
																		guide.city === null && guide.state !== null ? `${guide.state}` :
																			`${guide.city.trim()}, ${guide.state}`}
																</p>
															</div> : null
												}

												<p className={Styles.recs}>{guide?.rtg && Number(guide.rtg) > 0 &&
													<><Icon icon={star} size={15} style={{ color: "#22222", marginRight: "5px" }} /> {Number(guide?.rtg).toFixed(1)}</>
												}</p>
												<p className={guide?.comp?.length > 75 ? `${Styles.recs} ${Styles.Boatlimit}` : `${Styles.recs} ${Styles.Boatlimit} ${Styles.guestonMap}`}  >{boatDetailsGuests(guide?.availability_details)}</p>
											</div>
										</div>
									</InfoWindow>
									<Polygon path={PolyPath}
										draggable={false}
										options={{
											strokeOpacity: 1,
											strokeWeight: 1,
											zindex: -1,
											fillColor: "rgba(34, 34, 34, 0.44)",
											fillOpacity: 0.4,
											strokeColor: "#000",
										}} />
								</> : null
							}
						</>
					</OverlayView>
				)
			})
		}
		return markers;
	}
	useEffect(() => {
		if (mapRef && !isDrag && positions.length > 0) {
			const currposition = positions[positions.length - 1]
			timerid = setTimeout(async () => {
				await CommonCall(currposition, mapRef)
				setPositions([])
			}, 1000)
		} else {
			if (timerid) {
				clearTimeout(timerid)
			}
		}
	}, [isDrag, positions])
	const handleZoom = () => {
		const map = mapRef
		if (map && map?.center?.lat() && (props.zoomCount && props.zoomCount > 0)) {
			const curposition = { "lat": Number(map.center.lat()), "lng": Number(map.center.lng()) }
			CommonCall(curposition, map)
		}
	}
	const handleDragEnd = async () => {
		MapInfo.setHoverCardId("")
		setIsDrag(true)
		const map = mapRef
		if (map && map?.center?.lat()) {
			const curposition = { "lat": Number(map.center.lat()), "lng": Number(map.center.lng()) }
			if (mapScroll) {
				props.setMapLoading && props.setMapLoading(true)
				MapInfo.setGuidesAPICalled(true)
				MapInfo.setLoadingMask(true)
			}
			setPositions(position => [...position, curposition])
		}
		setIsDrag(false)
	}
	const handleDragStart = () => {
		setIsDrag(true)
	}
	const CommonCall = (position, mapRef, citydetail, statedetail) => {

		MapInfo.setLocation(position)

		const bounds = mapRef.getBounds()
		const Bound1 = JSON.stringify(bounds)
		const Bound = JSON.parse(Bound1)
		// MapInfo.setMapBounds(bounds)
		const formData = {
			city: citydetail ? citydetail : null,
			state: statedetail ? statedetail : null,
			latitude: position.lat,
			longitude: position.lng,
			page: 1,
			address: "Map Area",
			ne_lat: Bound.north,
			ne_lng: Bound.east,
			sw_lat: Bound.south,
			sw_lng: Bound.west,
			zoom: Number(mapRef.zoom),
			mapDrag: true,
		}
		if (mapScroll && props.handleSearchData) {
			props.setMapLoading && props.setMapLoading(true)
			MapInfo.setGuidesAPICalled(true)
			MapInfo.setLoadingMask(true)
			props.handleSearchData(formData)
		} else {
			setShowSearch(true)
		}
	}
	const onLoad = React.useCallback(function callback(map) {
		handleSetRef(map)
		// if (props.searchParams?.ne_lat) {
		// 	// 	(true)
		// 	const query = props.searchParams
		// 	const data1 = {
		// 		"north": Number(query.ne_lat),
		// 		"east": Number(query.ne_lng),
		// 		"south": Number(query.sw_lat),
		// 		"west": Number(query.sw_lng),
		// 	}
		// 	map.fitBounds(data1)
		// 	setTimeout(() => {
		// 		map.setZoom(Number(query.zoom))
		// 	}, 400)
		// }
	}, [])
	const handleSetRef = (ref) => {
		setMapRef(ref)  // referce to the google map component
		MapInfo.setMapRef(ref)  // referce to the google map component
	}
	const CallApi = async (map, from) => {
		MapInfo.setHoverCardId("")
		MapInfo.setMarkerOnClick("")
		MapInfo.setMarkerOnHover("")
		// const token = getCurrentUser().token
		const checkStaticLoc = map.address === "West Coast" || map.address === "North East" || map.address === "Mid West" || map.address === "South East" || map.address === "Rocky Mountains" || map.address === "Gulf Coast"
		var bounds
		if (!checkStaticLoc) {
			bounds = JSON.stringify(mapRef.getBounds())
		}
		if (!bounds || bounds === "null") {
			const data1 = {
				"north": Number(map.ne_lat),
				"east": Number(map.ne_lng),
				"south": Number(map.sw_lat),
				"west": Number(map.sw_lng),
			}
			bounds = JSON.stringify(data1)
			mapRef.fitBounds(data1)
			if (map.mapDrag) {
				setTimeout(() => {
					mapRef.setZoom(Number(map.zoom))
				}, 250)
			}
		}
		const data = {
			"geometry": {
				"bounds": JSON.parse(bounds)
			}
		}
		// alaska is falling into positive meridian - WD2607
		if (data?.geometry && data.geometry?.bounds?.west > 0) {
			data.geometry.bounds.west = -(data.geometry?.bounds?.west)
		}
		MapInfo.setMapBounds(data)
		if (typeof source != typeof undefined) {
			source.cancel("Operation canceled due to new request.")
		}
		source = axios.CancelToken.source()
		let urlParams = ""
		if (map.startDate) {
			urlParams = { ...map, endDate: map.startDate }
		} else {
			urlParams = map
		}

		//Save the cancel token for the current request

		const omitFalseyValues = from && from === "searchPage" ? clearFalseyValues(urlParams) : {};
		const url = new URLSearchParams(omitFalseyValues);
		const stringifiedURLParams = url.toString();
		let pagelimit = isBrowser && window.matchMedia("only screen and (max-width: 768px)").matches ? 20 : 50;
		let url1 = from && from === "searchPage" ? `${apiHost}guide/search?limit=${pagelimit}&browser_date=${moment().format("YYYY-MM-DD")}&${stringifiedURLParams}` :
			`${apiHost}guide/search?latitude=${Number(map.lat)}&longitude=${Number(map.lng)}&page=1&limit=${pagelimit}&browser_date=${moment().format("YYYY-MM-DD")}`;
		const formData = data;
		await axios.post(url1, formData, {
			cancelToken: source.token,
			headers: {
				// "Authorization": `Bearer ${token}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				"X-APP-ID": props.xAppID,
			}
		})
			.then(response => {
				props.setZoomCount(props.zoomCount + 1)
				MapInfo.setGuidePins(response.data.pins)
				MapInfo.setSearchMetaData(response.data._metadata)
				setShowSearch(false)
				MapInfo.setLoadingMask(false)
				const guides = response.data;
				if (guides.records.length === 0) {
					// const data = { ...props.mapSearchQuerry, "page": 1 }
					MapInfo.setLoadingMask(true)
					// setZoomChange(true)
					// CallApi(data, "searchPage")
				} else {
					const guidesNearBy = guides.records.filter((guide) => guide.availability_details.is_available && guide.availability_details.is_nearby)
					// const others = guides.records.filter((guide) => !guide.availability_details.is_available)
					const others = guides.records.filter(o => !guidesNearBy.some(i => i.guideid === o.guideid));
					const otherGuides = [...others]
					if (isBrowser && window.matchMedia("only screen and (max-width: 768px)").matches && MapInfo.isPageChanged) {
						const newNear = [...MapInfo.guidesWithinRange || [], ...guidesNearBy || []]
						const newOther = [...MapInfo.guides || [], ...otherGuides]
						MapInfo.setGuidesWithinRange(omitFalseyValues && omitFalseyValues.page > 1 ? newNear : guidesNearBy)
						MapInfo.setGuides(omitFalseyValues && omitFalseyValues.page > 1 ? newOther : otherGuides)
						MapInfo.setPageChanged(false)
					} else {
						// const newNear = [...MapInfo.guidesWithinRange || [], ...guidesNearBy || []]
						// const newOther = [...MapInfo.guides || [], ...otherGuides]
						// MapInfo.setGuidesWithinRange(omitFalseyValues && omitFalseyValues.page > 1 ? newNear : guidesNearBy)
						// MapInfo.setGuides(omitFalseyValues && omitFalseyValues.page > 1 ? newOther : otherGuides)
						MapInfo.setAllGuides([...guidesNearBy, ...otherGuides])
						MapInfo.setGuidesWithinRange(guidesNearBy)
						MapInfo.setGuides(otherGuides)
					}
					// MapInfo.setGuidesAPICalled(false)
					MapInfo.setNearByGuidesCount(guides._metadata.nearby_guides_count)
					MapInfo.setSearchPagination({ ...MapInfo.searchPagination, "active": omitFalseyValues && omitFalseyValues.page ? Number(omitFalseyValues.page) : 1, "numPages": Math.ceil(guides._metadata.total_count / pagelimit), "limit": pagelimit })
				}
				// setZoomChange(false)
				// props.setShowMoreFilter(false)
				setTimeout(() => {
					// setLocSearch(false)
					props.setMapLoading && props.setMapLoading(false)
					MapInfo.setGuidesAPICalled(false)
				}, 100)
			})
			.catch(err => {
				if (axios.isCancel(err)) {
					console.log('Previous request canceled, new request is send');
				} else {
					MapInfo.setLoadingMask(false)
					// setZoomChange(false)
					console.log(err)
				}
				MapInfo.setGuidesAPICalled(false)
			})
	}
	return (
		<div className={props.dynamicheight ? `${Styles.container1}` : props.container ? `${props.container}` : `${Styles.container}`}>
			{props.mapLoading && <img src={`${cloudfront}scripts/scriptstest/images/loadingDots.gif`} alt="loading" className={Styles.LoadingIcon} />}
			{!props.mapLoading && !props.from && isBrowser && window.innerWidth > 500 && <>{
				showSearch ?
					<div
						className={Styles.mapScrollCheck1}
						onClick={() => {
							MapInfo.setGuidesAPICalled(true)
							MapInfo.setLoadingMask(true);
							CallApi(MapInfo.location)
						}
						}
					>
						<Icon icon={repeat} size={16} className={Styles.Repeat} />
						<p>Search this area</p>
					</div>
					:
					isBrowser && location?.pathname?.includes("/fishing/places/") ?
						<div></div>
						:
						<div
							className={Styles.mapScrollCheck}
						>
							<Form.Group controlId="scroll" style={{ margin: "0px" }}>
								<Form.Check
									type="checkbox"
									label="Search as map moves"
									checked={mapScroll}
									onChange={(e) => setMapScroll(!mapScroll)}
									className={Styles.dragSearch}
								/>
							</Form.Group>
						</div>
			}
				{!props?.isFromBlogs && !props.mapLoading && isBrowser && window.innerWidth > 768 && <div className={Styles.fullscreen}>
					<button onClick={() => props.handelmapview()}>{props.mapFullScreen ? <Icon icon={chevronRight} size={20} />
						: <Icon icon={chevronLeft} size={20} />}</button>
				</div>}
			</>
			}

			{isLoaded &&
				<GoogleMap
					mapContainerClassName={Styles.mapDiv}
					onLoad={onLoad}
					onZoomChanged={() => handleZoom()}
					onClick={() => { setIsOpen("") }}
					onDragStart={() => handleDragStart()}
					onDragEnd={() => { handleDragEnd() }}
					defaultOptions={isBrowser && window.matchMedia("only screen and (max-width: 768px)").matches ? defaultMapOptions : defaultMapOptions1}
					options={{
						streetViewControl: false,
						mapTypeControl: false,
						scrollwheel: true,
						zoomControl: isBrowser && location?.pathname?.includes("/fishing/places/") ? false : isBrowser && window.innerWidth > 500 ? true : false,
						fullscreenControl: false,
						styles: mapStyles,
						...(location?.pathname?.includes("/fishing/places/") && { gestureHandling: "none" })
					}}
				>
					{renderAllMarkers()}
				</GoogleMap>
			}
		</div>
	)
}

export default React.memo(MapNew)
const mapStyles = [
	{
		"featureType": "landscape",
		"stylers": [
			{
				"hue": "#F1FF00"
			},
			{
				"saturation": -27.4
			},
			{
				"lightness": 9.4
			},
			{
				"gamma": 1
			}
		]
	},
	{
		"featureType": "road.highway",
		"stylers": [
			{
				"hue": "#0099FF"
			},
			{
				"saturation": -20
			},
			{
				"lightness": 36.4
			},
			{
				"gamma": 1
			}
		]
	},
	{
		"featureType": "road.arterial",
		"stylers": [
			{
				"hue": "#00FF4F"
			},
			{
				"saturation": 0
			},
			{
				"lightness": 0
			},
			{
				"gamma": 1
			}
		]
	},
	{
		"featureType": "road.local",
		"stylers": [
			{
				"hue": "#FFB300"
			},
			{
				"saturation": -38
			},
			{
				"lightness": 11.2
			},
			{
				"gamma": 1
			}
		]
	},
	{
		"featureType": "water",
		"stylers": [
			{
				"hue": "#00B6FF"
			},
			{
				"saturation": 4.2
			},
			{
				"lightness": -63.4
			},
			{
				"gamma": 1
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"visibility": "on"
			},
			{
				"hue": "#1900ff"
			},
			{
				"color": "#c0e8e8"
			}
		]
	},
]

