import React from "react"
import Styles from "../styles/searchHeader.module.scss"
import dayjs from "dayjs"

const HeaderSearchLayoutOne = ({
	setSelection,
	selectedDay,
	guests,
	headerSearch,
	destination,
	setHeaderSearch,
	setOverlay,
}) => {
	return (
		<div
			style={{
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
			}}
			className={`${headerSearch
				? Styles.header_search_layout_one_active
				: Styles.header_search_layout_one_inactive
				} `}
		>
			<div className={Styles.Sheading}
				onClick={() => {
					setOverlay(true);
					setSelection("location");
					setHeaderSearch((prev) => !prev);
				}}
			>
				<span className={Styles.title}>
					{destination?.address || "Location"}
				</span>
			</div>
			<div className={Styles.Divider}></div>
			<div className={Styles.Sheading}
				onClick={() => {
					setOverlay(true);
					setSelection("date");
					setHeaderSearch((prev) => !prev);
				}}
			>
				<span className={Styles.title}>
					{selectedDay ? dayjs(selectedDay, "YYYY-MM-DD").format("MMM DD") : "Date"}
				</span>
			</div>
			<div className={Styles.Divider}></div>

			<div className={Styles.Sheading}>
				<span
					onClick={() => {
						setOverlay(true);
						setSelection("group");
						setHeaderSearch((prev) => !prev);
					}}
					className={Styles.title}
				>
					{guests && Number(guests) > 1 ? `${guests} guests` : Number(guests) === 1 ? `${guests} guest` : "Group Size"}
				</span>
			</div>
			<img src={`${ImageCDN}searchFilter.png`} alt="Search Filter" width={28} height={28} className={Styles.filterIcon} />
		</div>
	)
}
export default HeaderSearchLayoutOne
