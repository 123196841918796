import  { useState, useEffect } from "react"
import dayjs from 'dayjs';
import queryString from 'query-string';
import { isBrowser } from "../../../widgets/common/utils";


const useSearchParamtoContext = () => {
    const location = isBrowser ? window.location : null
	const [data, setData] = useState(null)
	useEffect(() => {
		const searchParams = queryString.parse(location?.search) // => {init: "true"}
		if (searchParams) {
			let obj = {
				"address": searchParams?.address || null,
				"latitude": searchParams?.latitude || null,
				'longitude': searchParams?.longitude || null,
				"startDate": searchParams?.startDate ? dayjs(searchParams?.startDate,"YYYY-MM-DD").format("YYYY-MM-DD") : null,
				"guest": searchParams?.guest || 1,
				'category': searchParams?.activity_type ? null : searchParams?.category || "alltrips",
				'min_rate': searchParams?.min_rate || null,
				'max_rate': searchParams?.max_rate || null,
				'activity_type': searchParams?.activity_type || null,
				"trip_type": searchParams?.trip_type || null,
				'min_time': searchParams?.min_time || null,
				'max_time': searchParams?.max_time || null,
				'species': searchParams?.species || null,
				'start_time': searchParams?.start_time || null,
				'benifits': searchParams?.benifits || null,
				'reviews': searchParams?.reviews || null,
				// 'languages': searchParams?.languages || null,
				'ne_lat': searchParams?.ne_lat || null,
				"ne_lng": searchParams?.ne_lng || null,
				"sw_lat": searchParams?.sw_lat || null,
				"sw_lng": searchParams?.sw_lng || null,
				"zoom": searchParams?.zoom || null,
				"mapDrag": searchParams?.mapDrag || false,
				"page": searchParams?.page || 1,
				"advanced_filter": searchParams?.advanced_filter || false
			}
			setData(obj)
		}
	}, [])

	return data
}
export default useSearchParamtoContext
