import React, { useState, useEffect } from "react"
import  Styles from "./styles/categoryFilter.module.scss"
import { orderBy } from "lodash";
import FilterIndex from "./moreFilter/filterIndex";
import { get, post } from "../../../widgets/utils/axiosHelper"
import ModalComponent from "./common/ModalComponent"


const FilterCategory = ({ searchQuery, setSearchQuery, setZoomCount }) => {
	const [filterModal, setFilterModal] = useState(false)
	const [hunting, setHunting] = useState([])
	const [speciesData, setSpeciesData] = useState(null)
	const NewImageCDN = `${ImageCDN}searchfilter/`; 
	const data = [
		{ "id": 1, "title": "All Guides", "value": "alltrips", "url": `${NewImageCDN}AllTrips.svg`, "icon": `${NewImageCDN}darkgrey/AllTrips.svg` },
		{ "id": 2, "title": "Trending", "value": "trending", "url": `${NewImageCDN}Trending.svg`, "icon": `${NewImageCDN}darkgrey/Trending.svg` },
		{ "id": 4, "title": "Fishing", "value": "fishing", "url": `${NewImageCDN}Fishing.svg`, "icon": `${NewImageCDN}darkgrey/Fishing.svg` },
		{ "id": 5, "title": "Hunting", "value": "hunting", "url": `${NewImageCDN}Hunting.svg`, "icon": `${NewImageCDN}darkgrey/Hunting.svg` },
		{ "id": 6, "title": "Family", "value": "family", "url": `${NewImageCDN}Family.svg`, "icon": `${NewImageCDN}darkgrey/Family.svg` },
		{ "id": 7, "title": "Fly Fishing", "value": "flyfishing", "url": `${NewImageCDN}FlyFishing.svg`, "icon": `${NewImageCDN}darkgrey/FlyFishing.svg` },
		{ "id": 8, "title": "Master Guide", "value": "masterguide", "url": `${NewImageCDN}MasterGuide.svg`, "icon": `${NewImageCDN}darkgrey/MasterGuide.svg` },
		{ "id": 9, "title": "Lakes", "value": "lakes", "url": `${NewImageCDN}Lakes.svg`, "icon": `${NewImageCDN}darkgrey/Lakes.svg` },
		{ "id": 10, "title": "Boats", "value": "boats", "url": `${NewImageCDN}Boats.svg`, "icon": `${NewImageCDN}darkgrey/Boats.svg` },
		{ "id": 11, "title": "Inshore", "value": "inshore", "url": `${NewImageCDN}Inshore.svg`, "icon": `${NewImageCDN}darkgrey/Inshore.svg` },
		{ "id": 12, "title": "Deep Sea", "value": "deepsea", "url": `${NewImageCDN}DeepSea.svg`, "icon": `${NewImageCDN}darkgrey/DeepSea.svg` },
	];
	useEffect(() => {
		getFishSpecies();
		let URL = `master_data?type=HUNTING_TYPE`
			get(URL)
			.then(response => {
				const data = orderBy(response.data, 'label', 'asc')
				let all = []
				for (let item of data) {
					all.push({ "key": item.key, "value": item.value, "label": item.label, "checked": false })
				}
				setHunting(all)
			})
			.catch(
				err => console.log(err)
			)
	}, [])
	const filterAndTransform = (data, type) => {
		return data?.filter(item => item.attributes?.vertical_type === type).map(item => ({
			  id: Number(item.id),
			  label: item.attributes.title,
			  value: item.attributes.uid_slug,
			  img: item.attributes.fishimage?.data?.attributes?.url || null,
			  checked:  false,
		  })).sort((a, b) => a.label.localeCompare(b.label));
	}
	const getFishSpecies =() =>{
		const url = `content/species`
		const data = {
			"fields" : ["id","strapi_id", "uid_slug", "title", "vertical_type"]
			}
		post(url, data)
			.then(response => {
				const allFish = filterAndTransform(response?.data?.data, "Fishing");
				const allAnimal = filterAndTransform(response?.data?.data, "Hunting");
				setSpeciesData({allFish, allAnimal})
			})
			.catch(err => {
				console.log(err)
			})
	}
	const handleFinal = (data) => {
		setFilterModal(!filterModal)
		setSearchQuery({ ...searchQuery, ...data })
	}

	const searchP = {
		"activity_type": Array.isArray(searchQuery.activity_type) ? searchQuery.activity_type : searchQuery.activity_type?.split(","),
		"start_time": Array.isArray(searchQuery.start_time) ? searchQuery.start_time : searchQuery.start_time?.split(","),
		"benefits": Array.isArray(searchQuery.benifits) ? searchQuery.benifits : searchQuery.benifits?.split(","),
		"reviews": Array.isArray(searchQuery.reviews) ? searchQuery.reviews : searchQuery.reviews?.split(","),
		"checkSpecies": Array.isArray(searchQuery.species) ? searchQuery.species : searchQuery.species?.split(","),
		"tripType": Array.isArray(searchQuery.trip_type) ? searchQuery.trip_type : searchQuery.trip_type?.split(",")
	}
	let total = (searchQuery?.max_rate ? 1 : 0) + (searchQuery?.max_time ? 1 : 0) + (searchP?.activity_type?.length || 0) + (searchP?.start_time?.length || 0) + (searchP?.benefits?.length || 0) + (searchP?.reviews?.length || 0) + (searchP?.checkSpecies?.length || 0) + (searchP?.tripType?.length || 0) + (searchQuery?.currency?.length || 0);
	return (
		<div className={Styles.filterCont}>

			<ul className={Styles.filterRow} style={{marginLeft:"3%"}}>
				{
					data.map((item, i) => {
						return (
							<li key={i} onClick={() => {
								setSearchQuery({ ...searchQuery, "category": item.value, })
								setZoomCount(0)
							}}
								className={searchQuery?.category === item.value ? `${Styles.categoryItem} ${Styles.Activeli}` : Styles.categoryItem}>
								<img src={searchQuery?.category === item.value ? item.url : item.icon} alt={item.title} loading={`eager`} />
								<p>{item.title}</p>
							</li>
						)
					})
				}
			</ul>
			<button style={{ display: "flex",marginRight:"3%", backgroundColor:"transparent"}} onClick={() => { setFilterModal(!filterModal) }} className={total && total > 0 && Styles.filterApplied}>
				<img src={`${NewImageCDN}darkgrey/Filters.svg`} alt="filter" width={18} height={18} style={{ marginRight: "10px" }} />
				Filters
				{!!total && total > 0 && <span>{total}</span>}
			</button>
			<ModalComponent show={filterModal} size="lg" onHide={() => setFilterModal(!filterModal)}
				header="Filter" headClass={Styles.ModalHead} from={"company"} backstatic={true}>
				<FilterIndex handleFinal={handleFinal} searchQuery={searchQuery} hunting={hunting}
				speciesData={speciesData} />
			</ModalComponent>
		</div>
	)
}
export default FilterCategory
