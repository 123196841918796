import React, { useState, useEffect, useRef, useContext } from "react";
import "./rate-cards-select.scss";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { convertTo12Hour } from '../../../utils/time'

import TripDetailsContext from "../../../state/context/TripDetailsContext";
import { TYPES } from "../../../common/description";
import Tooltip from '@mui/material/Tooltip';
import { handleDateSelect } from "../../../utils/guide-details";
const InfoIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/black_info.png'

const RateCardsSelect = ({
  placeholder,
  heading,
  head,
  show,
  setShow,
  setHoursShow,
  setShowCalendar
}) => {
  const {
    rateCards,
    selectedRateCard,
    setSelectedRateCard,
    setSelectedHour,
    selectedDay,
    modifiers,
    setModifiers
  } = useContext(TripDetailsContext);
  const node = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    // outside click
    setShow(false);
  };

  const handleClick = () => {
    setShow(!show);
    setHoursShow(false)
  };

  const handleSelect = (item) => {
    setSelectedRateCard(item);
    setSelectedHour({});
  };

  const list = selectedDay ? rateCards.filter(item => !item.disabled) : rateCards;

  return (
    <div ref={node} style={{ width: 'auto' }}>
      <div className="Formgroup" onClick={handleClick}>
        <Typography variant="body2" className="f_InputSingle">
          {placeholder}
        </Typography>
        <ArrowDropDownIcon />
      </div>
      {show && (
        <div className="DropSingle">
          {head && <Typography component="div" className="tabletitle" variant="body2">
            {heading}
          </Typography>}
          {list.map((rateCard, i) => {
            return (
              <label
                key={i}
                style={{
                  borderBottom: "1px solid #42642f",
                  width: "100%",
                  margin: "5px 0px"
                }}
              >
                <Grid container className={`${rateCard.disabled ? '' : ''} innercontainer`}>
                  <Grid
                    item
                    xs={9}
                    sm={9}
                    lg={9}
                    md={9}
                    style={{ padding: "0px 5px", display: "flex" }}
                  >
                    <input
                      type="radio"
                      name="selectType"
                      onChange={() => {
                        if (selectedDay) {
                          handleSelect(rateCard);
                        } else {
                          const { availability } = handleDateSelect(rateCard, modifiers.schedule)
                          setModifiers({
                            ...modifiers,
                            availability,
                          });
                          setShowCalendar({ rateCard, selectedHour: {} })
                        }
                        setShow(false)
                      }}
                      checked={
                        selectedRateCard.id === rateCard.id
                      }
                      style={{ margin: "4px 5px 0px 0px", cursor: "pointer" }}
                    />
                    <span>
                      <Typography variant="body2" className="Head">
                        {rateCard.title}
                      </Typography>
                      {/* <Typography variant="caption" className="Head">
                      {rateCard.rates[0].title} @ {convertTo12Hour(rateCard.rates[0].start_time)}{" "}
                      {rateCard.rates[0].length > 1
                        ? `(+${rateCard.rates[0].length - 1} other time options)`
                        : null}
                    </Typography> */}
                      <Typography variant="caption" className="dsctext">
                        {rateCard.ratetype} <span style={{ position: 'relative', top: 7 }}>
                          <Tooltip classes={{ tooltip: 'guidesly-tooltip' }} enterTouchDelay={0} title={TYPES[rateCard.ratetype.toLowerCase()]}>
                            <img src={InfoIcon} />
                          </Tooltip>
                        </span>
                      </Typography>
                      {rateCard.maximum ? <Typography variant="caption" className="dsctext"> Max {rateCard.maximum} guests</Typography>: null}
                      {rateCard.has_boat && <Typography variant="caption" className="dsctext">
                        {rateCard.length}{rateCard.length?"' ":null}{(rateCard.manufacturer_name !="Other" && rateCard.manufacturer_name !="Others")?rateCard.manufacturer_name: rateCard.other_manufacturer}{'-'} {rateCard.maximum} Max capacity.
                    </Typography>}
                    </span>
                  </Grid>
                  {<Grid item xs={3} sm={3} lg={3} md={3} className="singleCol">
                    <span style={{ textAlign: "center" }}>
                      <Typography variant="body2" className="Head">
                        {rateCard?.rates?.length} start {rateCard?.rates?.length > 1 ? 'times' : 'time'} available
                      </Typography>
                    </span>
                  </Grid>}
                </Grid>
              </label>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default RateCardsSelect;
