import React, {useState, useEffect , useContext} from "react";
import useSearchParamtoContext from "../hooks/useSearchParamtoContext";
import MapContext from "../../../widgets/state/context/MapContext";
import useBrowserResizer from "../hooks/useBrowserResizer";
import { clearFalseyValues, isBrowser } from "../../../widgets/common/utils";
import SearchHeader from "../components/searchBar";
import InitLocation from "../components/common/initLocation";
import { getMyAddress } from "../../../widgets/common/storage";
import Styles from "../../../seo-widgets/guidefinder/components/styles/search.module.scss"
import Styles1 from "../../../seo-widgets/guidefinder/components/styles/guideList.module.scss"
import GoogleMapComponent from "../components/GoogleMapComponent";
import GuidesList from "../components/guideList";
import SearchPagination from "../components/SearchPagination";
import GuidesListMob from "../components/guideListMob";
import Icon from "react-icons-kit";
import { locationArrow } from 'react-icons-kit/typicons/locationArrow'
import axios from "axios";
import useClearLocation from "../components/common/useClearLocation";


const Search =({xAppID})=>{
    const [loaded, setLoaded] = useState(false)
    const context = useContext(MapContext)
    const [Mobile, setIsMobile] = useState(isBrowser && window.innerWidth <= 768);
	const [mapFullScreen, setMapFullScreen] = useState(false)
    const [pageChanged, setPageChanged] = useState(false)
	const [mapLoading, setMapLoading] = useState(false)
	const [zoomCount, setZoomCount] = useState(0)
    let SearchParamAddtoContext = useSearchParamtoContext()
	let BrowserResized = useBrowserResizer()
    const {searchQuery,setSearchQuery} = context;
	const [allGuides, setAllGuides] = useState([])
	useClearLocation()

	useEffect(()=>{
		getAllGuides()
	},[])
	const getAllGuides =()=>{
		const url =apiHost.includes("staging")?`https://staging.guideslyapi.com/getGuides_v1?content=true`:
		`https://guideslyapi.com/getGuides_v1?content=true`
		axios.get(url)
		.then(res=>setAllGuides(res.data))
		.catch(err=>Console.log(err))
	}

	useEffect(() => {
		if (SearchParamAddtoContext) {
		setSearchQuery(SearchParamAddtoContext)
			setLoaded(true)
		}
	}, [SearchParamAddtoContext])
    useEffect(() => {
		if (BrowserResized) {
			setIsMobile(BrowserResized)
		}
	}, [BrowserResized])
    useEffect(() => {
		if (searchQuery?.address || searchQuery?.latitude) {
			updateUrl()
		}
	}, [searchQuery])
	const updateUrl = () => {
		if (searchQuery && isBrowser) {
			const omitFalseyValues = clearFalseyValues(searchQuery);
			const url = new URLSearchParams(omitFalseyValues);
			const stringifiedURLParams = url.toString();
			let newPath = `${window?.location.origin}${window?.location.pathname}`;
			if (stringifiedURLParams) {
				newPath = `${newPath}?${stringifiedURLParams}`;
				window.history.replaceState({}, "", `?${stringifiedURLParams}`)
				window.localStorage.setItem("pathname", newPath);
			}
		}
	}
	const handelmapview = () => {
		setMapFullScreen(!mapFullScreen)
	}
	const handleBack = async () => {
		const { city, state, address, lat, lng } = getMyAddress()
		const data = {
			"latitude": lat || null,
			"longitude": lng || null,
			"city": city || "",
			"state": state || "",
			"page": 1,
			"limit": 20,
			"address": address || "",
			"zoom": null,
		}
		const s = { ...searchQuery, ...data }
		await setSearchQuery(s)
	}
	const handleMyLocation = () => {
		if (searchQuery?.address != getMyAddress()?.address) {
			handleBack()
			context.setMapDrag(false)
		} else {
			return null
		}
	}
	const handleSearchPageChange = (num) => {
		setPageChanged(true)
		setSearchQuery({ ...searchQuery, "page": num })
	}
	
	const LoadComponent = () => {
		const id = context.hoverCardId === "" || context.hoverCardId === 0 ? "" : context.hoverCardId
		const allguide = context.guidePins || []
		const check = allguide?.some(rec => rec.guid === id)
		return (
			<>
				{isBrowser && window.matchMedia("only screen and (max-width: 768px)").matches && <div>
					<div className={context.hoverCardId ? Styles1.locationArrow1 : Styles1.locationArrow2} >
						<button onClick={() => handleMyLocation()}>
							<Icon icon={locationArrow}
								style={searchQuery?.address === getMyAddress()?.address ? { color: "black" } : { color: "lightgrey" }} />
						</button>
					</div>
					<div className={!context.hoverCardId || context.hoverCardId === "" || context.hoverCardId === "0" || context.hoverCardId === 0 ? null : check ? Styles.guidesList1 : `${Styles.guidesList1} ${setHeight}`}  >
						<GuidesListMob handleSearchPageChange={handleSearchPageChange} allGuides={allGuides} xAppID={xAppID}/>
					</div>
				</div>
				}
				{
					isBrowser && window.matchMedia("only screen and (min-width: 769px)").matches &&
					<div className={`${Styles.guidesList}`}
						style={mapFullScreen ? { display: "none" } : null}
					>

						<GuidesList handleSearchPageChange={handleSearchPageChange} allGuides={allGuides} xAppID={xAppID}/>
						{
							!context.loadingMask && (context.guidesWithinRange?.length > 0 ||
								context.guides?.length > 0) && (<SearchPagination
									handleSearchPageChange={handleSearchPageChange} />
							)}
					</div>
				}
			</>
		)
	}
	const handleSearchData = async (data) => {
		const s = { ...searchQuery, ...data }
		await setSearchQuery(s)
	}
    return(
        <div id="Search">
					<SearchHeader Mobile={Mobile} searchQuery={searchQuery} setSearchQuery={setSearchQuery} setZoomCount={setZoomCount} />
					{
						(loaded && !searchQuery?.latitude && !searchQuery?.longitude && (!searchQuery?.address)) ?
							<InitLocation setSearchQuery={setSearchQuery}
								searchQuery={searchQuery}
								setMapLoading={setMapLoading} /> : null
					}
					<div className={Styles.rowContainer1}>
						<div className={Styles.googleMap} style={mapFullScreen ? { width: "100%" } : null}>
							<GoogleMapComponent
								pageChanged={pageChanged}
								setPageChanged={setPageChanged}
								handleSearchData={handleSearchData}
								mapFullScreen={mapFullScreen}
								handelmapview={handelmapview}
								searchParams={searchQuery}
								mapLoading={mapLoading}
								setMapLoading={setMapLoading}
								zoomCount={zoomCount}
								setZoomCount={setZoomCount}
								allGuides={allGuides}
								xAppID={xAppID}
							/>
						</div>
						{LoadComponent()}
					</div>
				</div>
    )
}
export default Search;