import React, { useState, useContext, useEffect } from "react"
import Styles from "./styles/guideList.module.scss"
// import { navigate } from "gatsby"
import GuideCardMob from "./GuideCardMob";
// import { getGuideDetailsLink, GetAllGuidesFromStrapi } from "../../utils";
import Icon from "react-icons-kit";
// import { useLocation } from "@reach/router"
import { map } from 'react-icons-kit/fa/map'
import GuideCardMobile from "./GuideCardMobile";
import MapContext from "../../../widgets/state/context/MapContext";
import { getGuideDetailsLink } from "../../../widgets/common/utils"

const isBrowser = typeof window !== `undefined`
const GuidesListMob = (props) => {
	const location = isBrowser&&window.location
	const [show, setShow] = useState(true)
	const [nearbyGuides, setNearbyGuides] = useState([])
	const [count, setCount] = useState(0)
	const [y, setY] = useState(null);
	const [showFooter, setShowFooter] = useState(true);
	const context = useContext(MapContext)
	let urlparams = new URLSearchParams(location.search)
	const [pageno, setpageno] = useState(urlparams.get("page"))
	const [allguides, setAllGuides] = useState(props.allGuides)

	useEffect(()=>{
		setAllGuides(props.allGuides)
	},[props.allGuides])
	
	useEffect(() => {
		const data = context.guidePins.filter(rec => rec.guid === context.hoverCardId)
		setNearbyGuides(data)

	}, [context.hoverCardId])
	useEffect(() => {
		if (show === true) {
			const limit = 20
			const page = Number(context.searchPagination.active)
			if (page > 1 && !context.guidesAPICalled) {
				const id = (limit * (page - 1) + 1)
				const my_element = document.getElementById(id.toString());
				my_element?.scrollIntoView({
					block: "center",
				})
			} 
			// else if (isBrowser && page === 1 && !context.guidesAPICalled) {
			// 	window.scrollTo(0, 0)
			// }
		}
	}, [show, context.guidesAPICalled, context.searchPagination])
	
	useEffect(() => {
		if (show === true) {
			const limit = 20
			const page = Number(context.searchPagination.active)
			if (page > 1 && !context.guidesAPICalled) {
				const id = (limit * (page - 1) + 1)
				const my_element = document.getElementById(id.toString());
				my_element?.scrollIntoView({
					block: "center",
				})
			} 
			// else if (isBrowser && page === 1 && !context.guidesAPICalled) {
			// 	window.scrollTo(0, 0)
			// }
		}
	}, [show, context.searchPagination])
	useEffect(() => {
		let urlparams = new URLSearchParams(location.search)
		setpageno(urlparams.get("page"))
	}, [location.search])
	// set the card id on hover of the card
	const handleCard = (id) => {
		context.setHoverCardId(id)
	}
	const checkScroll = e => {
		const element = e.currentTarget;
		var st = window.pageYOffset || element.scrollTop;
		const scroll = element.scrollHeight - element.clientHeight
		if ((st > (scroll - 100))) {
			setShowFooter(false)
		} else {
			setShowFooter(true);
		}
		setY(st <= 0 ? 0 : st);
	};

	useEffect(() => {
		if (isBrowser && show) {
			setY(isBrowser && window.scrollY);
			// const element = document.querySelector(".ReactSwipeableBottomSheet--open");
			const element = document.getElementById("guidesList");
			if (element) {
				isBrowser && element?.addEventListener("scroll", checkScroll);
				return () => {
					isBrowser && element.removeEventListener("scroll", checkScroll);
				};
			}
		}
	}, [y, show]);

	// getting the guide detail on click of the card

	const getGuide = (guide, e) => {
		if (e.target.classList.contains("x-read-more")) return;
		if (e.target.classList.contains("x-read-less")) return;
		// const link = getGuideDetailsLink(guide, allGuides)
		// navigate(link, {
		// 	state: {
		// 		activeGuideTab: true,
		// 		filteredData: props.data
		// 	}
		// })
	}

	useEffect(() => {
		context.setGuideImages([])
		// handleScroll(context.markerOnClick)
	}, [context.markerOnClick])

	useEffect(() => {
		context.setGuideRateCard([]);
	}, [])


	const getGuideRate = (item) => {
		var guideRates = [];

		var wade = [];
		wade.push({
			am: item.hdamrate > 0 ? item.hdamrate : "N/A",
			pm: item.hdpmrate > 0 ? item.hdpmrate : "N/A",
			fd: item.fdrate > 0 ? item.fdrate : "N/A",
			fd2: item.multi2day > 0 ? item.multi2day : "N/A",
		})
		// guideRates.push({ Wade })

		var boat = [];
		boat.push({
			am: item.boat_hdamrate > 0 ? item.boat_hdamrate : "N/A",
			pm: item.boat_hdpmrate > 0 ? item.boat_hdpmrate : "N/A",
			fd: item.boat_fdrate > 0 ? item.boat_fdrate : "N/A",
			fd2: item.boat_multi2day > 0 ? item.boat_multi2day : "N/A",
		})
		guideRates.push({ boat, wade })
		return guideRates;

	}
	const handleClear = () => {
		if (isBrowser) {
			setShow(false)
			// const my_element = document.getElementById("guidesList");
			// if (my_element) {
			// 	my_element.scrollTop = 0;
			// }
		}
	}

	
	var xDown = null;
	var yDown = null;
	var check = 0
	function getTouches(evt) {
		return evt.touches ||             // browser API
			evt.originalEvent.touches; // jQuery
	}

	const handleTouchStart = (evt) => {
		const firstTouch = getTouches(evt)[0];
		xDown = firstTouch.clientX;
		yDown = firstTouch.clientY;
	};
	const handleTouchMove = (evt) => {
		if (!xDown || !yDown) {
			return;
		}

		var xUp = evt.touches[0].clientX;
		var yUp = evt.touches[0].clientY;

		var xDiff = xDown - xUp;
		var yDiff = yDown - yUp;

		if (Math.abs(xDiff) > Math.abs(yDiff)) {
			/*most significant*/
			if (xDiff > 0) {
				/* right swipe */
			} else {
				/* left swipe */
			}
		} else {
			if (yDiff > 0) {
				if (isBrowser) {
					setShow(true)
				} else {
					// console.log('swipe down');
					setShow(false)
					setCount(count + 1)
				}
			}
			xDown = null;
			yDown = null;
		}
	}
	const getfish=()=>{
		
	}
	return (
		<div style={{ height: "100%" }} className="FontStyle">
			{context.hoverCardId && (context.hoverCardId != "" || context.hoverCardId != "0" || context.hoverCardId != 0) && < ul className={Styles.nearYou} id="GuidesNearYou">
				{
					nearbyGuides.length === 0 ? null
						: (
							<>
								<div style={{ flex: " 0 0 5%" }}></div>
								{isBrowser && window.matchMedia("only screen and (max-width: 500px)").matches &&
									<>
										{
											nearbyGuides.map((item, index) => {
												return (
													<li className={Styles.Cardli} key={index}>
														<a href={getGuideDetailsLink(item,allguides, null, "id", props.xAppID)} 
														className={Styles.guideCardLink} target="_blank">
															<GuideCardMob key={index} fishspecies={getfish(item)} item={item} index={index} handleCard={handleCard} getGuideRate={getGuideRate} getGuide={getGuide}
																showClose={true} handleClear={() => context.setHoverCardId("")} from={"onMap"} />
														</a>
													</li>
												)
											})
										}
									</>

								}
							</>
						)
				}
			</ul>}
			<div className={Styles.BottomContainer}>
				<div id="guidesList" className={show && count === 0 ? Styles.DataContnoAni : show && count > 0 ? Styles.DataContBottom : show === false ? Styles.DataContBottom1 : Styles.DataContBottomFix} onTouchStart={(e) => handleTouchStart(e)} onTouchMove={(e) => handleTouchMove(e)}>
					<div className={Styles.guideListDiv12} >
						<div className={Styles.guideListText1} onClick={() => { setShow(!show); setCount(count + 1) }}>
							<h1 className="p-0 pb-0 text-center fs-5 fw-bold">Book your fishing trip &amp; guide</h1>
							<h2 className="text-center pt-0 m-0">Show Available Guides</h2>
						</div>
					</div>
					{show && showFooter && <div className={Styles.showMap} >
						<span onClick={() => handleClear()}>Map <Icon icon={map} /></span>
					</div>}
					{
						context.guidesAPICalled ?
							<>
								{
									context.guidesAPICalled && [{}, {}, {}, {}, {}].map((_, index) => {
										return (
											<div  key={index} style={{ marginBottom: "10px", padding: "10px" }}>
												<div className={`${Styles.GuideImgs} loading`} style={{width:"100%", height: 290 }}></div>
												<div className={`${Styles.GuideCol1} loading`} style={{ margin: "5px 0px", height: "66px" }}>
												</div>
											</div>
										)
									})
								}
							</> :
							<>
								<div style={{ padding: "15px", overflow: 'scroll', background: "#f4f4f4" }}>
									{
										context.guidesWithinRange.map((item, index) => {
											return (
												<GuideCardMobile allGuides={allguides}  isNarrow key={index + 1} item={item} index={index + 1} handleCard={handleCard} getGuideRate={getGuideRate} getGuide={getGuide} 
												xAppID={props.xAppID}/>
											)
										})
									}
									{
										context.guides.map((item, index) => {
											const i = context.guidesWithinRange.length + (index + 1)
											return (
												<GuideCardMobile allGuides={allguides} isNarrow key={i} item={item}  index={i} handleCard={handleCard} getGuideRate={getGuideRate} getGuide={getGuide}
												xAppID={props.xAppID} />
											)
										})
									}
								</div>
								{Number(pageno) === context.searchPagination.numPages ? null :
									<div style={{ background: "#f5f5f5" }}>
										<button className={`btn--blue--outlined ${Styles.pageBtn}`}
											onClick={() => {
												context.setPageChanged(true)
												props.handleSearchPageChange(Number(context.searchPagination.active) + 1)
											}}>
											Show More</button>
									</div>
								}
							</>
					}
				</div>
			</div>

		</div >
	)

}

export default GuidesListMob;
