import React from 'react';
import { withStyles } from '@mui/styles';
import Radio from '@mui/material/Radio';

const GreenRadio = withStyles({
    root: {
      color: '#42642f',
      '&$checked': {
        color:'#42642f',
      },
      padding: '2px 10px',
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export default GreenRadio;
