import React, { useState, useEffect, useContext } from "react";
import  Styles from "../components/styles/pagination.module.scss"
import MapContext from "../../../widgets/state/context/MapContext";
const SearchPagination = ({ unscroll, handleSearchPageChange }) => {
	const context = useContext(MapContext)
	const [pagination, setPagination] = useState({ "limit": 20, "active": 1, "numPages": 5 })
	useEffect(() => {
		setPagination(context.searchPagination)
	}, [context.searchPagination])
	const handlePageChange = (num) => {
		let pagescroll = unscroll ? false : true;
		const isBrowser = typeof window !== `undefined`
		handleSearchPageChange(num)
		context.setPageChanged(true)
		if (isBrowser && pagescroll) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
				block: 'center',
			})
		}
	}
	let startPage, endPage;
	if (pagination.numPages <= 5) {
		startPage = 1;
		endPage = pagination.numPages;
	} else {
		if (pagination.active <= 3) {
			startPage = 1;
			endPage = 5;
		} else if (pagination.active + 2 >= pagination.numPages) {
			startPage = pagination.numPages - 4;
			endPage = pagination.numPages;
		} else {
			startPage = pagination.active - 2;
			endPage = pagination.active + 2;
		}
	}

	let pages = [...Array(endPage + 1 - startPage).keys()].map(
		i => startPage + i
	);
	return (
		<div className={`${Styles.paginationCont} ${Styles.searchCont}`}>
			<div className={Styles.paginationStyle}>
				<div className={Styles.pagination}>
					<ul>
						{pagination.active != 1 && pagination.numPages > 5 && (<li>
							<button
								// disabled={pagination.active === 1}
								onClick={() => handlePageChange(1)}
							>
								{`First`}
							</button>
						</li>)}
						{pagination.active != 1 && (<li>
							<button
								// disabled={pagination.active === 1}
								onClick={() => handlePageChange(pagination.active - 1)}
							>
								{`<`}
							</button>
						</li>)}
						{pages.map((page, index) => {
							return (
								<li key={index}>
									<button
										className={Number(pagination.active) === page ? Styles.active : ""}
										onClick={() => handlePageChange(page)}
									>
										{page}
									</button>
								</li>
							)
						}
						)}
						{pagination.active != pagination.numPages && (<li>
							<button
								// disabled={pagination.active === pagination.numPages}
								onClick={() => handlePageChange(pagination.active + 1)}
							>
								{`>`}
							</button>
						</li>)}
						{pagination.active != pagination.numPages && pagination.numPages > 5 && (<li>
							<button
								// disabled={pagination.active === pagination.numPages}
								onClick={() => handlePageChange(pagination.numPages)}
							>
								{`Last`}
							</button>
						</li>)}
					</ul>
				</div>
			</div>
		</div>
	);

}

export default SearchPagination;
