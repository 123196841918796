import React, { useContext, useEffect, useState, useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import RateCards from './rate-cards';
import PersonalDetails from '../../../common/personal-details/PersonalDetailsStripeProvider';
import Confirmation from '../../../common/confirmation/Confirmation';
import TripDetailsContext from '../../../state/context/TripDetailsContext';
import {
  getAllRateCardsWithDisabled,
  getAvailability,
  getInitialRateCards,
  getExpCapacity
} from '../../../utils/guide-details';
import { get } from '../../../utils/axiosHelper';
import {
  getTotalCharge,
} from '../../../utils/payment';
import useStyles from './styles';
import { isValidPhoneNumber } from 'react-phone-number-input';
import './styles.scss'
import 'react-day-picker/dist/style.css';
import Selection from '../../../common/selection';
 
export default function RateCardsWidget({ id, xAppID ,isMultiTenantGuide, companyGuid,userData }) {
  const classes = useStyles();
  const isBrowser = typeof window !== `undefined`
  const tripDetailsContext = useContext(TripDetailsContext);
  const [showCalendar, setShowCalendar] = useState(null)
  const [referralGuides, setReferralGuides] = useState([]);
  const [expanded, setExpanded] = useState(false)
  const [tripCalculator, setTripCalculator] = useState(false)
  const guideGuidFirst = id;
  const guestCountRef = useRef()
  const {
    step,
    setStep,
    fromMonth,
    toMonth,
    modifiers,
    setSelectedDay,
    selectedHour,
    selectedRateCard,
    guestCount,
    setModifiers,
    setAvailableRateCards,
    setAvailableSchedules,
    setMeals,
    setRateCards,
    setAvailableMeals,
    setAvailableMeetingPoints,
    setAvailableWaterBodies,
    setMeeetingPoint,
    setPreferedWaterbody,
    setSecondFavoriteWaterbody,
    setGuideName,
    firstName,
    lastName,
    email,
    phoneNumber,
    rateCards,
    setSelectedRateCard,
    setSelectedHour,
    selectedDay,
    setAddOnFees,
    addOnFees,
    modifiersStyles,
    availableSchedules,
    availableRateCards,
    setIsGuestCountMaxed,
    isGuestCountMaxed,
    setGuestCount,
    setDepositAmt,
    setBSAvailability,
    setSelectionType,
    availableSpecies,
    setAvailableSpecies,
    setReferralGuideName,
    setReferralMainId,
    setCancelationDays,
    setSchRecords,
    schrecords,
    setSource,
    setGuideDetails,
    sharedTripRateCapacity, 
    setSharedTripRateCapacity,
    setCompanyDetails,  selectionType
  } = tripDetailsContext;

  let total;
  let chargeToday;
  let chargeOnDayOfTrip;

//   useEffect(() => {
//     if(guideGuidFirst) {
//         setReferralMainId(guideGuidFirst)
//     }
// }, [guideGuidFirst])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (selectedHour.rate) {
    total = getTotalCharge(
      selectedRateCard,
      guestCount,
      selectedHour.rate,
      addOnFees
    );

    chargeToday = (total * 0.20).toFixed(2);

    chargeOnDayOfTrip = total - chargeToday;
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (document.querySelector('#guidesly-live-calendar') && window.location.host.includes('texasleadslingers.com')) {
        document.querySelector('#guidesly-live-calendar').style.display = 'none'
      }
      if (typeof window !== 'undefined') {
        if (document.querySelector('.wsb-htmlsnippet-element')) {
          document.querySelectorAll('.wsb-htmlsnippet-element').forEach(item => item.style.overflow = "scroll")
        }
      }
      setTimeout(() => {
        if (document.querySelector('#iframe-03')) {
          document.querySelector('#iframe-03').style.display = 'none'
        }
      }, 3000);
    }
  }, [])

  useEffect(() => {
    if (guestCount) {
      if(selectedRateCard?.is_shared_trip) {
          const isGuestCountMaxed = guestCount > sharedTripRateCapacity
          setIsGuestCountMaxed(isGuestCountMaxed)
      } else {
        if (selectedRateCard) {
          const isGuestCountMaxed = guestCount > selectedRateCard.maximum
          setIsGuestCountMaxed(isGuestCountMaxed)
        } else if (rateCards.length === 1 && !rateCards[0].disabled) {
          const isGuestCountMaxed = guestCount > rateCards[0].maximum
          setIsGuestCountMaxed(isGuestCountMaxed)
        } else {
          setIsGuestCountMaxed(false)
        }
        if (guestCount > 1) {
          if (step === 'personal-details') {
            const selectedSchedule = availableSchedules.find(
              (schedule) =>
                schedule.date === moment(selectedDay).format('YYYY-MM-DD'),
            );
            let ratecards = getAllRateCardsWithDisabled(availableRateCards, selectedSchedule)
            ratecards = ratecards.filter(item => guestCount <= item.maximum)
            setRateCards(ratecards);
          } else {
            const ratecards = rateCards.map(item => ({ ...item, isGuestCountDisabled: guestCount > item.maximum }))
            setRateCards(ratecards);
          }
        } else {
          if (step !== 'personal-details') {
            const ratecards = rateCards.map(item => ({ ...item, isGuestCountDisabled: guestCount > item.maximum }))
            setRateCards(ratecards);
          }
        }
      }
    }
  }, [guestCount, step])

  useEffect(() => {
    const startDate = moment(fromMonth).format('YYYY-MM-DD');
    const endDate = moment(toMonth).format('YYYY-MM-DD');
    const url1 = `guide/gbs/availability?id=${id}&start_date=${startDate}&end_date=${endDate}&current_date=${moment().format("YYYY-MM-DD h:mm:s a")}`;
    const url2 = `guide-group/${companyGuid}/ratecards?current_date=${moment().format("YYYY-MM-DD h:mm:s a")}`
    const url = isMultiTenantGuide&&companyGuid&&companyGuid!="" ? url2 :url1
    get(url).then((res) => {
      const BookingAcceptanceDate = res.data?.booking_acceptance_days || res.data?.booking_acceptance_days === 0 ? res.data?.booking_acceptance_days : 2
      if (res?.data) {
        if(isBrowser){
          window.localStorage.setItem("bookingAcceptance",BookingAcceptanceDate)
        }
        const { scheduleDisable, availability } = getAvailability(
          res.data.schedule,
        );
        setSchRecords(res?.data)
        setCancelationDays(res?.data?.allowed_cancellation_days)
        setReferralGuides(res?.data?.referral_guides)
        setReferralMainId(res?.data?.guideguid)
        setModifiers({
          ...modifiers,
          availability,
          scheduleDisable,
          schedule: res.data.schedule,
        });
        if(isMultiTenantGuide&&companyGuid&&companyGuid!="" ){
          setDepositAmt(res.data.deposit_percentage)
        }else{
          setDepositAmt(null)
        }
        setAvailableRateCards(res.data.ratecards);
        if (res.data.schedule.length > 0) {
          setAvailableSchedules(res.data.schedule);
          const selectedInitialDay = res.data.schedule.find(
            (day) => day.date === moment(new Date()).format('YYYY-MM-DD'),
          );
          const rateCards = getInitialRateCards(res.data.ratecards, selectedInitialDay);
          setModifiers({
            ...modifiers,
            availability,
            scheduleDisable,
            schedule: res.data.schedule,
          });
          setRateCards(
            rateCards,
          );
          if (rateCards.length === 1) {
            setSelectedRateCard(rateCards[0])
          }
          setSelectedDay(
            ''
          );
        }
        if (res.data.meals) {
          const select = { guid: 'select', name: "Select" };
          setMeals(select);
          setAvailableMeals([select, ...res.data.meals]);
        }
        // if (res.data.poi) {
        //   setMeeetingPoint(res.data.poi[0]);
        //   setAvailableMeetingPoints(res.data.poi);
        // }
        if (res.data.rivers) {
          setAvailableWaterBodies(res.data.rivers);
          setPreferedWaterbody(res.data.rivers[0]);
          setSecondFavoriteWaterbody(res.data.rivers[0]);
        }
        setGuideDetails({
          guidename: res.data.guidename,
          company: res.data.company,
          location1: res.data.location1,
          location2: res.data.location2,
          title: res.data.title
        })
        setGuideName(res.data.guidename);
        // setAddOnFees(res.data.guide_addon_fees || []);
      }
    });
    const getSpecies = `content/fish_species?search=all`;
    get(getSpecies).then((res) => {
      if (res?.data?.response) {
        setAvailableSpecies(res?.data?.response)
      }
    });
  }, []);

  useEffect(() => {
    if(referralGuides) {
      setReferralGuideName(referralGuides)
    }
  },[referralGuides])

  useEffect(() => {
    const add_on_fees = (selectedRateCard.addon_fees || []).filter(item => item.active)
    setAddOnFees(add_on_fees)
  }, [selectedRateCard])

  useEffect(() =>{
    setSharedTripRateCapacity(getExpCapacity(availableSchedules,selectedHour,selectedDay))
    if(selectedRateCard && selectedRateCard?.is_shared_trip){
      if(selectedHour && (guestCount >= capacity)){
        setGuestCount(capacity)
      }
    }
  }, [selectedHour,selectedDay])

  const capacity = isMultiTenantGuide&&selectionType?.type&&selectionType?.type==="Boat"?selectionType?.data?.guest_capacity:selectedRateCard?.is_shared_trip ? getExpCapacity(availableSchedules,selectedHour,selectedDay) || selectedHour?.max_occurrence : selectedRateCard?.id ? selectedRateCard.maximum : Math.max(...rateCards.map(item => item.maximum || 0))

  const handleDayClick = (e, dayPickerModifiers = {}) => {
    e.setHours(0, 0, 0, 0);

    if (dayPickerModifiers.availability) {
      setSelectedDay(e);
      const selectedSchedule = availableSchedules.find(
        (schedule) =>
          schedule.date === moment(e).format('YYYY-MM-DD'),
      );
      const ratecards = getAllRateCardsWithDisabled(availableRateCards, selectedSchedule)
      setRateCards(ratecards);
      if (showCalendar) {
        const { rateCard = {}, selectedHour = {} } = showCalendar;
        const completeRateCard = ratecards.find(rate => rate.id === rateCard.id)
        if (!completeRateCard.disabled && !completeRateCard.rates[0].disabled) {
          instantBook(rateCard, selectedHour)
        }
      }
      // setModifiers({
      //   ...modifiers,
      //   availability: modifiers.availableSchedule
      // })
    }
  };
  useEffect(() => {
    if(userData && Object.keys(userData)?.length>0) {
      tripDetailsContext.setUserData(userData)
    }
  },[userData])

  const instantBook = (card, hour) => {
    if (!hour.rate || card.id !== selectedRateCard.id) {
      setSelectedHour(card.rates[0]);
      setSelectedRateCard(card);
      return;
    }
  }
  const commontripDetails = {
    'trip-details': (
      <Grid container>
        <RateCards
          setModifiers={setModifiers}
          modifiers={modifiers}
          setShowCalendar={setShowCalendar}
          capacity={capacity}
          selectedDay={selectedDay}
          selectedRateCard={selectedRateCard}
          rateCards={rateCards}
          selectedHour={selectedHour}
          guestCount={guestCount}
          handleClick={(hour, card) => {
            setSelectedHour(hour);
            setSelectedRateCard(card);
          }}
          instantBook={instantBook}
          addOnFees={addOnFees}
          disabled={!(total && chargeToday && chargeOnDayOfTrip && selectedDay)}
          setStep={setStep}
          fromMonth={fromMonth}
          toMonth={toMonth}
          month={selectedDay}
          modifiersStyles={modifiersStyles}
          setSelectedRateCard={setSelectedRateCard}
          setSelectedHour={setSelectedHour}
          setSelectedDay={setSelectedDay}
          setRateCards={setRateCards}
          showCalendar={showCalendar}
          availableSchedules={availableSchedules}
          stateRateCards={availableRateCards}
          setGuestCountHandler={setGuestCount}
          xAppID={xAppID}
          handleDayClick={handleDayClick}
          setTripCalculator={setTripCalculator}
          setExpandedProp={setExpanded}
          isMultiTenantGuide={isMultiTenantGuide}
          companyGuid={companyGuid}
          setBSAvailability={ setBSAvailability}
          setSelectionType={setSelectionType}
          availableSpecies={availableSpecies}
          setReferralGuideName={setReferralGuideName}
          referral={referralGuides}
          schrecords={schrecords}
          setSource={setSource}
          setCompanyDetails={setCompanyDetails}
          userData={userData}
        />
      </Grid>
    ),
  }
  const itsCompany ={
    ...commontripDetails,
    'selection':(
      <Selection
      previous={() => setStep('trip-details')}
      next={() => setStep('personal-details')}
      companyGuid={companyGuid}
      />
    ),
    'personal-details': (
      <PersonalDetails
        setGuestCountHandler={setGuestCount}
        capacity={capacity}
        previous={() => setStep('selection')}
        tripsPage={() => setStep('trip-details')}
        next={() => setStep('confirmation')}
        xAppID={xAppID}
        isNarrow
        isMultiTenantGuide={isMultiTenantGuide}
        companyGuid={companyGuid}
      />
    ),
  }
  const itsIndividualGuide ={
    ...commontripDetails,
    'personal-details': (
      <PersonalDetails
      setGuestCountHandler={setGuestCount}
      capacity={capacity}
      previous={() => setStep('trip-details')}
      next={() => setStep('confirmation')}
      xAppID={xAppID}
      isNarrow
      isMultiTenantGuide={isMultiTenantGuide}
      companyGuid={companyGuid}
      />
    )
  }
  
  const Step = isMultiTenantGuide&&companyGuid&&companyGuid!=""? itsCompany : itsIndividualGuide;

  if (step === 'confirmation') {
    return (
      <Card className={classes.root}>
        <CardContent>
          <Confirmation isNarrow />
        </CardContent>
      </Card>
    );
  }

  let disabled = true;

  if (step === 'trip-details')
    disabled = !(total && chargeToday && chargeOnDayOfTrip);
  if (step === 'personal-details')
    disabled = !(firstName && lastName && email && phoneNumber) || !isValidPhoneNumber(phoneNumber) || !(total && chargeToday && chargeOnDayOfTrip) || isGuestCountMaxed

  const title = {
    'trip-details': 'Trip Types',
    'personal-details': ``,
    payment: '',
  };

  const list = selectedDay ? rateCards.filter(item => !item.disabled) : rateCards;

  const handleHour = (item, record) => {
    setSelectedRateCard(record);
    setSelectedHour(item);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        {Step[step]}
      </CardContent>
    </Card>
  );
}
