import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  cardRoot: {
  },
  title: {
    color: theme.palette.text.primary,
    padding: 10,
    fontSize: 24,
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  name: {
    color: theme.palette.text.subtext,
    fontSize: 13
  },
  marginTop: {
    marginTop: '10px !important'
  },
  root: {
    padding: 0,
    paddingBottom: '0 !important'
  },
  imgContainer: {
    width: '20%',
    margin: '0 !important'
  },
  img: {
    height: 100,
    width: 100,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 5,
    '@media screen and (max-width: 568px)': {
      height: 40,
      width: 40,
    },
    backgroundColor: "#224041",
  },
  paddingLeft: {
    paddingLeft: 10
  },
  verticalAlignCenter: {
    margin: 'auto 0'
  },
  horizontalAlignCenter: {
    justifyContent: 'center',
    display: 'flex',
    margin: 'auto'
  },
  subtext: {
    color: theme.palette.text.subtext
  },
  container: {
    paddingBottom: 10,
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
    paddingTop: 10,
    '@media screen and (max-width: 568px)': {
      paddingBottom: 10,
      paddingTop: 10,
      display: 'block',
    },
  },
  comments: {
    '& p': {
      margin: 0,
      marginRight: 10,
      textAlign: 'justify'
    },
    '@media screen and (max-width: 568px)': {
      marginLeft: 10,
      marginRight: 10
    },
  },
  displayFlex: {
    display: 'flex'
  },
  reviewed: {
    marginLeft: 15,
    position: 'relative',
    top: 4,
    fontSize: 12,
    '@media screen and (max-width: 568px)': {
      top: 21,
    },
  },
  width: {
    width: '80%'
  },
  more: {
    fontSize: 13,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#20b4e4',
    textAlign: 'end',
    marginRight: 10
  },
  descLimitedContents: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 4,
    overflow: 'hidden',
    whiteSpace: 'pre-line',
    marginBottom: 0
  },
  descContents: {
    '& p': {
      marginBottom: 0
    }
  },
  allReviews: {
    cursor: 'pointer',
    color: '#204043',
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: 1,
    paddingLeft: 10,
    paddingBottom: 16
  },
  headline: {
    h1: {
      textAlign: "center",
      color: "#333333",
      fontWeight: 900,
      fontSize: "44px"
    }
  }
}));
