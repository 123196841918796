import React, { useState, useEffect, useRef } from "react"
import "../styles/multiselect.scss"
import { Form, InputGroup } from "react-bootstrap"
import Styles1 from "../styles/newratecard.module.scss"
import Styles from "../styles/commonGuide.module.scss"
import { v4 as uuidv4 } from "uuid"

const SearchFilterList = ({ options, from, values, placeholder, handleSelectedItems, subtext, heading, head, title, hideSearch, setData, limit }) => {
	const node = useRef();
	const [show, setShow] = useState(false);
	const [searchedText, setSearchedText] = useState("");
	const [selectedFish, setSelectedFish] = useState([])
	useEffect(() => {
		setSelectedFish(values)
	}, [values])
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [])

	const handleClickOutside = e => {
		if (node.current.contains(e.target)) {
			return;
		}
		// outside click
		setShow(false)
	};

	const handleChecked = (item, i) => {
		let newState = [...options];
		let result = newState.findIndex((obj) => {
			return obj.id === item.id;
		});
		newState[result].checked = !newState[result].checked;
		from != "search" && setData(newState)
		const onlySelected = newState.filter(item => item.checked);
		setSelectedFish(onlySelected)
		const s = []
		for (let rec of onlySelected) {
			s.push(rec.id)
		}
		from === "search" && handleSelectedItems(s)
		from === 'journal' && handleSelectedItems(onlySelected);
	}

	// const removeFromSelectedItemsArray = (data, index) => {
	// 	for (var j = 0; j < values.length; j++) {
	// 		var p = values[j]
	// 		if (p.value == data.value)
	// 			p.checked = false

	// 	}
	// 	var rec = values.filter(item => item.checked === true)
	// 	handleSelectedItems(rec)
	// };

	const handleClick = () => {
		setShow(!show)
	}
	// const filterLength = values.filter(item => item.checked === true)
	let filtersOptions = options.filter(item => item.label.toLowerCase().replace(/\s/g, '').includes(searchedText.toLowerCase().replace(/\s/g, '')))
	const handleChange = e => {
		setSearchedText(e.target.value);
		const text = e.target.value;
		if (text) {
			const filteredArray = options.filter(item =>
				item.label.toLowerCase().replace(/\s/g, '').includes(text.toLowerCase().replace(/\s/g, ''))
			);
			filtersOptions = filteredArray;
			setSearchedText(e.target.value);
			// setShow(true)
			setShow(true)
		} else {
			filtersOptions = options.filter(item => item.label.toLowerCase().replace(/\s/g, '').includes(searchedText.toLowerCase().replace(/\s/g, '')))
			setSearchedText(text);
		}
	};
	const hide = hideSearch ? hideSearch : false
	return (
		<div ref={node} style={{ width: "100%" }}>
			{!hide && <InputGroup className={`${Styles1.Formgroup} ${Styles1.InputGrp}`} >
				<Form.Control
					value={searchedText}
					type="text"
					placeholder={placeholder}
					onChange={handleChange}
					// className={Styles1.f_Input}
					onFocus={() => {
						handleClick();
					}}
					className={from === 'websiteform' ? `${Styles1.f_Input} ${Styles1.backgroundImages}` : `${Styles1.f_Input}`}
					style={{ paddingRight: "0px" }}
				/>
				<InputGroup.Text className={Styles1.inputClear}>
					{
						from === 'websiteform' ? < span onClick={() => setSearchedText("")} className={`${Styles1.clearText} ${Styles1.closeBG}`}></span>
							: < span onClick={() => setSearchedText("")} className={Styles1.clearText}>Clear Search</span>
					}
				</InputGroup.Text>
			</InputGroup>}
			{((from && from === "search") || show) && (
				<div className={Styles1.OuterCont}>
					{subtext && (<p className="feedbackText1" style={{ padding: "0px 0px 5px" }}>{title}</p>)}
					<div className={Styles1.BoatMain} style={from === "search" ? { maxHeight: "250px", borderBottom: "none", minHeight: "250px" } : { maxHeight: "275px" }}>
						{filtersOptions.map((item, i) => {
							return (
								<Form.Group key={i} controlId={"checkitem" + uuidv4()} className={Styles.GuideFormGroup} style={{ padding: "0px 10px", }}>
									<Form.Check
										type="checkbox"
										label={item.label}
										onChange={(e) => {
											if (limit && e.target.checked && selectedFish.length < limit) {
												handleChecked(item, i)
											} else if (limit && !e.target.checked && selectedFish.length <= limit) {
												handleChecked(item, i)
											} else if (!limit) {
												handleChecked(item, i)
											}
										}}
										checked={item.checked}
										className={Styles.formCheckAll}
									/>
								</Form.Group>
							);
						})}
						{
							filtersOptions.length === 0 && <p style={{ textAlign: "center" }}>no results found</p>
						}
					</div>
				</div>
			)
			}
			{/* {!from && <>
				{values.length > 0 && values.map((data, key) => (
					<div key={key} style={{ display: "flex", flexDirection: "column" }} >
						<span
							className="selected"
						>
							<Icon icon={xCircle} className="Icon"
								onClick={() => removeFromSelectedItemsArray(data, key)} />{data.label}
						</span>
					</div>
				))}
			</>
			} */}
		</div>
	);
};
export default SearchFilterList;
