import React, {  useContext, useEffect } from "react"
import Styles from "./styles/guideList.module.scss"
// import { getGuideDetailsLink, GetAllGuidesFromStrapi } from "../../utils";
import GuideCardShimmerNew from "./GuideCardShimmerNew";
import VerticalGuideCard from "./VerticalGuideCard.js";
import MapContext from "../../../widgets/state/context/MapContext.jsx";

const GuidesList = (props) => {
	const context = useContext(MapContext)
	const isBrowser = typeof window !== `undefined`
	// useEffect(() => {
	// 	if (context.guidesAPICalled && isBrowser) {
	// 		window.scrollTo(0, 0)
	// 	}
	// }, [context.guidesAPICalled])
	
	const handleCard = (id) => {
		context.setHoverCardId(id)
	}

	// handling scroll on click of marker
	const handleScroll = (id) => {
		if (id) {
			const scroll = document.getElementById(id)
			if (scroll !== null) {
				scroll.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				})
			}
		}
	}

	// getting the guide detail on click of the card
	const getGuide = (guide, e) => {
		if (e.target.classList.contains("x-read-more")) return;
		if (e.target.classList.contains("x-read-less")) return;
		// const link = getGuideDetailsLink(guide, allGuides)
		const link = ""
		// navigate(link, {
		// 	state: {
		// 		activeGuideTab: true,
		// 		filteredData: props.data,
		// 		id: guide.guideid
		// 	}
		// })
	}

	useEffect(() => {
		context.setGuideImages([])
		handleScroll(context.markerOnClick)
	}, [context.markerOnClick])

	useEffect(() => {
		context.setGuideRateCard([]);
	}, [])


	const getGuideRate = (item) => {
		var guideRates = [];

		var wade = [];
		wade.push({
			am: item.hdamrate > 0 ? item.hdamrate : "N/A",
			pm: item.hdpmrate > 0 ? item.hdpmrate : "N/A",
			fd: item.fdrate > 0 ? item.fdrate : "N/A",
			fd2: item.multi2day > 0 ? item.multi2day : "N/A",
		})
		// guideRates.push({ Wade })

		var boat = [];
		boat.push({
			am: item.boat_hdamrate > 0 ? item.boat_hdamrate : "N/A",
			pm: item.boat_hdpmrate > 0 ? item.boat_hdpmrate : "N/A",
			fd: item.boat_fdrate > 0 ? item.boat_fdrate : "N/A",
			fd2: item.boat_multi2day > 0 ? item.boat_multi2day : "N/A",
		})
		guideRates.push({ boat, wade })
		return guideRates;

	}
	const state = context.searchedCity
	

	const searchArea = context.mapDrag ? "Map Area" : context.searchedValue && context.searchedValue != "" ? context.searchedValue : "";

	return (
		<div className={`${Styles.Dcontainer} FontStyle`}>
			<h1 className="p-2 ps-0 pb-2 text-left fs-5">Book your fishing trip & guide</h1>

			{
				context.guidesAPICalled ?
					<div>
						<div className={Styles.guideListDiv}>
							<h2 className={`${Styles.guideListText} loading`} style={{ width: "150px", height: "30px" }}>
							</h2>
						</div>
						<div className={Styles.otherguidesdiv}>
							{
								[{}, {}, {}, {}, {}, {}].map((_, index) => {
									return (
										<GuideCardShimmerNew key={index} />
									)
								})
							}
						</div>
					</div>
					: (
						<>
							{!context.guidesAPICalled && context.guidesWithinRange?.length > 0 && (<div className={Styles.guideListDiv}>
								<div className={Styles.guideListText}>
									<h2>{`${context.nearByGuidesCount > 1 ? 'Over ' : ''}${context.nearByGuidesCount} ${context.nearByGuidesCount > 1 ? 'Guides' : 'Guide'} Near ${searchArea}`}</h2>
									
								</div>
							</div>
							)}

							<div className={Styles.otherguidesdiv}>
								{
									context.guidesWithinRange?.map((item, index) => {
										return (
											<VerticalGuideCard key={index + 1} allGuides={props.allGuides} xAppID={props.xAppID}
											 item={item}  index={index + 1} handleCard={handleCard} getGuideRate={getGuideRate} getGuide={getGuide} />
										)
									})
								}
								{!context.guidesAPICalled && context.guides?.length === 0 ? null :
									<div className={Styles.guideListText} ><h2>Other Guides</h2></div>
								}
								{
									context.guides?.map((item, index) => {
										const i = context.guidesWithinRange?.length + (index + 1)
										return (
											<VerticalGuideCard key={i} item={item}  allGuides={props.allGuides} xAppID={props.xAppID}
											index={i} handleCard={handleCard} getGuideRate={getGuideRate} getGuide={getGuide} />
										)
									})
								}
							</div>
						</>
					)
			}
			
		</div >
	)

}

export default GuidesList;
