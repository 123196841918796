export const daypickerStyles = `
    .rdp {
        font-size: 13px;
        outline: none;
        width:100% !important;
    }
    .rdp-month{
        outline: none;
        border-collapse: inherit !important;
        border-spacing: 0px 0px !important;
    }
    .rdp-month-wrapper{
        padding-bottom:0px !important;
        outline: none;
    }
    .rdp-day  {
        width: 40px !important;
        padding: 0.3em;
        outline:none !important;
        vertical-align:baseline !important;
    }
    .rdp-day_selected  {
        background-color: orange !important;
        border-radius:50% !important;
    }
    .rdp-day--unavailablity  {
        color: lightgrey;
    }
    @media only screen and (min-width: 500px) and (max-width: 700px) {
        .rdp-nav_button {
            position: absolute;
            top: 1em;
            right: 0em  !important;
            left: auto;
            display: inline-block;
            margin-top: 2px;
            width: 1.25em;
            height: 1.25em;
            background-position: center;
            background-size: 50%;
            background-repeat: no-repeat;
            color: #8B9898;
            cursor: pointer;
        }
        .rdp-nav_button_interactionDisabled {
            display: none;
        }
    }
`;

export default daypickerStyles;
