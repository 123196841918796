import React, { useState, useEffect, useContext } from "react"
import  Styles from "../styles/categoryFilter.module.scss"
import SliderRange from "../common/RangeSlider"
import CommonFiltersInput from "../common/CommonFiltersInput";
import { ActivityType, Benefits, Reviews, TripTime, filterTripType } from "../common/data/data";
import axios from "axios";
import SearchFilterList from "../common/SearchFilterList"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { clearFalseyValues } from "../../../../widgets/common/utils";
import MapContext from "../../../../widgets/state/context/MapContext";

const isBrowser = typeof window !== `undefined`
const initialState = {
	"minRate": undefined,
	"maxRate": undefined,
	"minHours": undefined,
	"maxHours": undefined,
	"activity_type": undefined,
	"start_time": undefined,
	"benefits": undefined,
	"reviews": undefined,
	"currency": undefined
	// "languages": null
}
let source
const FilterIndex = ({ handleFinal, searchQuery, hunting ,speciesData,}) => {
	const context = useContext(MapContext)
	const [fish_data, setFishData] = useState(speciesData?.allFish ||[]);
	const [animal_data, setAnimalData] = useState(speciesData?.allAnimal||[]);
	const [activity_data, setActivityData] = useState(ActivityType());
	const [startTime_data, setStartTimeData] = useState(TripTime());
	const [benefits_data, setBenefitsData] = useState(Benefits());
	const [reviews_data, setReviewsData] = useState(Reviews());
	// const [languages_data, setLanguages] = useState(Languages());
	const [fishingtripType_data, setFishingTripTypeData] = useState(filterTripType())
	const [huntingtripType_data, setHuntingTripTypeData] = useState([])
	const [currencyList, setCurrencyList] = useState([
		{ "id": 1, "label": "USD", "value": "USD", checked: false },
		{ "id": 2, "label": "CAD", "value": "CAD", checked: false },
	]);

	const [huntingtripType, setHuntingTripType] = useState([])
	const [fishingtripType, setFishingTripType] = useState([])
	const [species, setSpecies] = useState([])
	const [animalspecies, setAnimalSpecies] = useState([])
	const [filters, setFilters] = useState(initialState)
	const [ispriceChanged, setPriceChanged] = useState(false)
	const [ishoursChanged, setIshourchange] = useState(false)
	const [loaded, setLoaded] = useState(false)
	const [queryParam, setQueryParam] = useState(null)
	const [guideCount, setGuideCount] = useState(0)

	
	  console.log(species, animalspecies)
	
	useEffect(() => {
		setGuideCount(context.searchMetaData?.nearby_guides_count)
	}, [context.searchMetaData])
	useEffect(() => {
		if (loaded) {
			let filterparam = { ...searchQuery }
			filterparam = { ...searchQuery, ...queryParam }
			let pagelimit = isBrowser && window.matchMedia("only screen and (max-width: 768px)").matches ? 20 : 50;
			const omitFalseyValues = clearFalseyValues(filterparam)
			const url = new URLSearchParams(omitFalseyValues);
			const stringifiedURLParams = url.toString();
			let url1 = `${apiHost}guide/search?metaonly=${true}&${stringifiedURLParams}&limit=${pagelimit}`
			if (typeof source != typeof undefined) {
				source.cancel("Operation canceled due to new request.")
			}
			source = axios.CancelToken.source()
			const data = context.mapBounds;
			axios.post(url1, data, {
				cancelToken: source.token,
				headers: {
					// "Authorization": `Bearer ${getCurre}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					"X-APP-ID": "6046acd0-5c48-11ea-8f77-42010a8e00cf",
				}
			})
				.then(response => {
					setGuideCount(response.data?._metadata?.nearby_guides_count)
				})
				.catch(err => {
					if (axios.isCancel(err)) {
						console.log('Previous request canceled, new request is send');
					}
					console.log(err)
				})
		}
	}, [queryParam])
	useEffect(() => {
		if (searchQuery?.trip_type) {
			const tt = Array.isArray(searchQuery.trip_type) ? searchQuery.trip_type : searchQuery.trip_type?.split(",");
			const initial = [...hunting]
			const d = []
			for (let item of initial) {
				for (let rec of tt) {
					if (item.value === rec) {
						d.push(item.value)
						item.checked = true
					}
				}
			}
			setHuntingTripType(d)
			setHuntingTripTypeData(initial)
		} else {
			const initial = []
			for (let rec of hunting) {
				initial.push({ ...rec, checked: false })
			}
			setHuntingTripTypeData(initial)
		}
	}, [hunting])
	useEffect(() => {
		let data = {
			"minRate": searchQuery.min_rate || context.searchMetaData?.min_rate,
			"maxRate": searchQuery.max_rate || context.searchMetaData?.max_rate,
			"minHours": searchQuery.min_time || 1,
			"maxHours": searchQuery.max_time || Math.round(context.searchMetaData?.max_hour),
			"activity_type": Array.isArray(searchQuery.activity_type) ? searchQuery.activity_type : searchQuery.activity_type?.split(","),
			"start_time": Array.isArray(searchQuery.start_time) ? searchQuery.start_time : searchQuery.start_time?.split(","),
			"benefits": Array.isArray(searchQuery.benifits) ? searchQuery.benifits : searchQuery.benifits?.split(","),
			"reviews": Array.isArray(searchQuery.reviews) ? searchQuery.reviews : searchQuery.reviews?.split(","),
			"currency": Array.isArray(searchQuery.currency) ? searchQuery.currency : searchQuery.currency?.split(","),
			// "languages": Array.isArray(searchQuery.languages) ? searchQuery.languages : searchQuery.languages?.split(",")
		}
		setFilters(data)
		if (searchQuery?.min_rate || searchQuery?.max_rate) {
			setPriceChanged(true)
		}
		if (searchQuery?.min_time || searchQuery?.max_time) {
			setIshourchange(true)
		}
		const trtpType = Array.isArray(searchQuery.trip_type) ? searchQuery.trip_type : searchQuery.trip_type?.split(",");
		if (trtpType) {
			const d = []
			for (let item of fishingtripType_data) {
				for (let rec of trtpType) {
					if (item.value === rec) {
						d.push(item.value)
						item.checked = true
					}
				}
			}
			setFishingTripType(d)
			setFishingTripTypeData([...fishingtripType_data])
		}

		if (searchQuery.activity_type && data.activity_type) {
			for (let item of activity_data) {
				for (let rec of data.activity_type) {
					if (item.value === rec) {
						item.checked = true
					}
				}
			}
			setActivityData([...activity_data])
		}
		if (searchQuery.currency && data.currency) {
			for (let item of currencyList) {
				for (let rec of data.currency) {
					if (item.value === rec) {
						item.checked = true
					}
				}
			}
			setCurrencyList([...currencyList])
		}
		if (searchQuery.start_time && data.start_time) {
			for (let item of startTime_data) {
				for (let rec of data.start_time) {
					if (item.value === rec) {
						item.checked = true
					}
				}
			}
			setStartTimeData([...startTime_data])
		}
		if (searchQuery.benifits && data.benefits) {
			for (let item of benefits_data) {
				for (let rec of data.benefits) {
					if (item.value === rec) {
						item.checked = true
					}
				}
			}
			setBenefitsData([...benefits_data])
		}
		if (searchQuery.reviews && data.reviews) {
			for (let item of reviews_data) {
				for (let rec of data.reviews) {
					if (item.value === rec) {
						item.checked = true
					}
				}
			}
			setReviewsData([...reviews_data])
		}
		const checkSpecies = searchQuery.species && Array.isArray(searchQuery.species) ? searchQuery.species : searchQuery.species?.split(",")
		if (checkSpecies) {
			const d = []
			for (let item of fish_data) {
				for (let rec of checkSpecies) {
					if (item.id === Number(rec)) {
						d.push(item.id)
						item.checked = true
					}
				}
			}
			setSpecies(d)
			setFishData([...fish_data])
		}
		if (checkSpecies) {
			const d = []
			for (let item of animal_data) {
				for (let rec of checkSpecies) {
					if (item.id === Number(rec)) {
						d.push(item.id)
						item.checked = true
					}
				}
			}
			setAnimalSpecies(d)
			setAnimalData([...animal_data])
		}
		// if (searchQuery.benifits && data.languages) {
		// 	for (let item of languages_data) {
		// 		for (let rec of data.languages) {
		// 			if (item.value === rec) {
		// 				item.checked = true
		// 			}
		// 		}
		// 	}
		// 	setLanguages([...languages_data])
		// }
	}, [searchQuery])

	const handleClear = () => {
		setFilters(initialState)
		setPriceChanged(false)
		setIshourchange(false)
		const initial = []
		for (let rec of huntingtripType_data) {
			initial.push({ ...rec, checked: false })
		}
		let fish = []
		for (let r of fish_data) {
			if (r.checked) {
				fish.push({ ...r, "checked": false })
			} else {
				fish.push(r)
			}
		}
		let animal = []
		for (let r1 of animal_data) {
			if (r1.checked) {
				animal.push({ ...r1, "checked": false })
			} else {
				animal.push(r1)
			}
		}
		setFishData(fish)
		setAnimalData(animal)
		setHuntingTripTypeData(initial)
		setActivityData(ActivityType());
		setStartTimeData(TripTime());
		setBenefitsData(Benefits());
		setReviewsData(Reviews());
		// setLanguages(Languages());
		setFishingTripTypeData(filterTripType())
		setSpecies([])
		setAnimalSpecies([])
		setCurrencyList([
			{ "id": 1, "label": "USD", "value": "USD", checked: false },
			{ "id": 2, "label": "CAD", "value": "CAD", checked: false },
		])
		setHuntingTripType([])
		setFishingTripType([])
		const data = {
			"min_rate": null,
			"max_rate": null,
			"activity_type": null,
			"trip_type": null,
			"min_time": null,
			"max_time": null,
			"species": null,
			"start_time": null,
			"benifits": null,
			"reviews": null,
			"advanced_filter": false,
			"category": "alltrips",
			"currency": null
			// "languages": null,
		}
		handleFinal(data)
	}

	const handleSelectedfish = (selectedfish) => {
		setLoaded(true)
		setSpecies(selectedfish)
	}
	const handleSelectedAnimals = (selectedAnimals) => {
		setLoaded(true)
		setAnimalSpecies(selectedAnimals)
	}
	const handleSelectedfishTriptype = (selected) => {
		setLoaded(true)
		setFishingTripType(selected)
	}
	const handleSelectedAnimalsTripType = (selected) => {
		setLoaded(true)
		setHuntingTripType(selected)
	}
	const handleUpdate = (selected, param) => {
		setLoaded(true)
		setFilters({ ...filters, [param]: selected })
	}

	useEffect(() => {
		if (loaded) {
			addToFilter()
		}
	}, [filters, huntingtripType, fishingtripType, species, animalspecies, loaded])
	const addToFilter = () => {
		const finalT = [...huntingtripType, ...fishingtripType]
		const finalS = [...species, ...animalspecies]
		const data = {
			"min_rate": ispriceChanged && filters.minRate,
			"max_rate": ispriceChanged && filters.maxRate,
			"activity_type": filters?.activity_type,
			"trip_type": finalT,
			"min_time": filters.minHours != "" && ishoursChanged ? filters.minHours : null,
			"max_time": filters.maxHours != "" && ishoursChanged ? filters.maxHours : null,
			"species": finalS,
			"start_time": filters?.start_time,
			"benifits": filters?.benefits,
			"reviews": filters?.reviews,
			"advanced_filter": total && total > 0 ? true : null,
			'category': "alltrips",
			"currency": filters?.currency
			// "languages": filters?.languages,
		}
		if (filters.activity_type?.length > 0) {
			data.category = null
		}
		setQueryParam(data)
	}
	const getToolTipData = () => {
		const finalT = [...huntingtripType, ...fishingtripType]
		const finalS = [...species, ...animalspecies]
		let msg = []
		if (ispriceChanged) {
			msg.push("Price range")
		} else {
			msg = msg.filter(e => e !== "Price Range")
		}
		if (finalS?.length > 0) {
			msg.push(`${finalS?.length} Targeted Species`)
		} else {
			msg = msg.filter(e => !["Targeted Species"].includes(e))
		}
		if (filters?.activity_type?.length > 0) {
			msg.push(`${filters?.activity_type?.length} Activity Type`)
		} else {
			msg = msg.filter(e => !["Activity Type"].includes(e))
		}
		if (finalT?.length > 0) {
			msg.push(`${finalT?.length} Trip Type`)
		} else {
			msg = msg.filter(e => !["Trip Type"].includes(e))
		}
		if (filters?.start_time?.length > 0) {
			msg.push(`${filters?.start_time?.length} Start Times`)
		} else {
			msg = msg.filter(e => !["Start Times"].includes(e))
		}
		if (ishoursChanged) {
			msg.push("Trip Length")
		} else {
			msg = msg.filter(e => e !== "Trip Length")
		}
		if (filters?.benefits?.length > 0) {
			msg.push(`${filters?.benefits?.length} Guide Benefits`)
		} else {
			msg = msg.filter(e => !["Guide Benefits"].includes(e))
		}
		if (filters?.reviews > 0) {
			msg.push(`Reviews`)
		} else {
			msg = msg.filter(e => e !== "Reviews")
		}
		let res = msg + ' filter is selected.'
		if (msg.length >= 2) {
			const last = msg.pop();
			res = msg.join(', ') + ' and ' + last + ` filters are selected.`;
		}
		return res
	}
	const handleSubmit = () => {
		handleFinal(queryParam)
	}
	let total = (ispriceChanged ? 1 : 0) + (ishoursChanged ? 1 : 0) + (filters?.activity_type?.length || 0) + (filters?.start_time?.length || 0) + (filters?.benefits?.length || 0) + (filters?.reviews?.length || 0) + (species?.length || 0) + (animalspecies?.length || 0) + (fishingtripType?.length || 0) + (huntingtripType?.length || 0) + (filters?.currency?.length || 0);

	// context.selectedFishingType?.length + context.searchSelectedTripType?.length
	return (
		<>
			<div className={Styles.ModalCont}>
				<div className={Styles.FilterSubSection}>
					<p className={Styles.title}>Currency</p>
					<CommonFiltersInput
						options={currencyList}
						values={filters.currency}
						param={"currency"}
						handleSelected={(selected, param) => handleUpdate(selected, param)}
					/>
				</div>
				<div className={Styles.FilterSubSection}>
					<p className={Styles.title}>Price Range</p>
					<p className={Styles.Subtitle}>The average price of an experience is {context.searchMetaData?.avg_rate ? `$${Math.round(context.searchMetaData?.avg_rate)}` : `$350`}</p>
					<SliderRange
						type="price"
						setFilters={setFilters}
						filters={filters}
						currentrange={[Math.floor(context.searchMetaData?.min_rate || 1), context.searchMetaData?.max_rate]}
						min={Math.floor(context.searchMetaData?.min_rate || 1)}
						max={Math.floor(context.searchMetaData?.max_rate)}
						setChange={setPriceChanged}
						setLoaded={setLoaded}
					/>
				</div>
				<div className={Styles.FilterSubSection}>
					<p className={Styles.title}>Targeted Species</p>
					<div className={`${Styles.flexCont} ${Styles.mobileReponsive}`}>
						<div className={Styles.flexCol}>
							<SearchFilterList
								options={fish_data}
								values={species}
								subtext={true}
								heading=""
								placeholder={"Search Fish Species"}
								handleSelectedItems={handleSelectedfish}
								displayFishList={true}
								actionButtons={false}
								title={"Fish Species"}
								// limit={5}
								// setData={setSpecies}
								from={"search"}
							/>

						</div>
						<div className={Styles.flexCol}>
							<SearchFilterList
								options={animal_data}
								values={animalspecies}
								subtext={true}
								heading=""
								placeholder={"Search Animal Species"}
								handleSelectedItems={handleSelectedAnimals}
								displayFishList={true}
								actionButtons={false}
								title={"Animal Species"}
								// limit={5}
								// setData={setAnimalSpecies}
								from={"search"}
							/>

						</div>

					</div>
				</div>
				<div className={Styles.FilterSubSection}>
					<p className={Styles.title}>Activity Type</p>
					<CommonFiltersInput
						options={activity_data}
						values={filters.activity_type}
						param={"activity_type"}
						handleSelected={(selected, param) => handleUpdate(selected, param)}
					/>
				</div>
				<div className={Styles.FilterSubSection}>
					<p className={Styles.title}>Trip Type</p>
					<div className={`${Styles.flexCont} ${Styles.mobileReponsive}`}>
						<div className={Styles.flexCol}>
							<CommonFiltersInput
								options={fishingtripType_data}
								values={fishingtripType}
								handleSelected={handleSelectedfishTriptype}
								title={"Fishing Trip Types"}
								// limit={5}
								showTitle={true}
								listView={true}
							/>
						</div>
						<div className={Styles.flexCol}>
							<CommonFiltersInput
								options={huntingtripType_data}
								values={huntingtripType}
								handleSelected={handleSelectedAnimalsTripType}
								title={"Hunting Trip Types"}
								// limit={5}
								showTitle={true}
								listView={true}
							/>
						</div>

					</div>
				</div>
				<div className={Styles.FilterSubSection}>
					<p className={Styles.title}>Start Times</p>
					<CommonFiltersInput
						options={startTime_data}
						values={filters.start_time}
						param={"start_time"}
						handleSelected={(selected, param) => handleUpdate(selected, param)}
					/>

				</div>
				<div className={Styles.FilterSubSection}>
					<p className={Styles.title}>Trip Length</p>
					<p className={Styles.Subtitle}>The average trip length of an experience is {context.searchMetaData?.avg_hour ? `${Math.round(context.searchMetaData?.avg_hour)} hours` : `3 hours`}</p>
					<SliderRange
						type="tripLength"
						setFilters={setFilters}
						filters={filters}
						currentrange={[1, (context.searchMetaData?.max_hour)]}
						min={1}
						max={Math.floor(context.searchMetaData?.max_hour)}
						setChange={setIshourchange}
						setLoaded={setLoaded}
					/>
				</div>
				<div className={Styles.FilterSubSection}>
					<p className={Styles.title}>Guide Benefits</p>
					<CommonFiltersInput
						options={benefits_data}
						values={filters.benefits}
						param={"benefits"}
						handleSelected={(selected, param) => handleUpdate(selected, param)}
					/>
				</div>
				<div className={Styles.FilterSubSection}>
					<p className={Styles.title}>Guide Reviews</p>
					<CommonFiltersInput
						options={reviews_data}
						values={filters.reviews}
						param={"reviews"}
						handleSelected={(selected, param) => handleUpdate(selected, param)}
					/>
				</div>
				{/* <div className={Styles.FilterSubSection}>
					<p className={Styles.title}>Languages Offered</p>
					<CommonFiltersInput
						options={languages_data}
						values={filters.languages}
						param={"languages"}
						handleSelected={(selected, param) => handleUpdate(selected, param)}
					/>
				</div> */}
			</div>
			<div className={Styles.ModalFooter}>
				<p className={Styles.Clear} onClick={() => {
					handleClear()
				}}>Clear Filters</p>
				<button className={`button`} onClick={() => handleSubmit()}>{guideCount > 0 && guideCount === 1 ? `Show 1 Guide` : guideCount === 0 ? `Show Other Guides` : `Show ${guideCount} Guides`}</button>
				{
					total > 0 ?
						<OverlayTrigger
							key={'top'}
							placement={'top'}
							overlay={
								<Tooltip id={`tooltip-${'top'}`}>
									{getToolTipData()}
								</Tooltip>
							}>
							<p className={Styles.selected} style={{ cursor: "pointer" }}>{total} Filters Selected</p>
						</OverlayTrigger>
						:
						<p className={Styles.selected}>{total} Filters Selected</p>
				}
			</div>
		</>
	)
}
export default FilterIndex
