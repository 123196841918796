import React from "react"
import { Box, Modal } from '@mui/material';
const ChatModal = ({ show, size, onHide, header, children, style1 }) => {
	return (
		<Modal
			open={show}
			size={size ? size : "sm"}
			aria-labelledby="contained-modal-title-vcenter"
			onClose={onHide}
            MenuProps={{
                disableScrollLock: true,
            }}
		>
			<Box className={style1 ? style1 : null}>
				{children}
			</Box>

		</Modal >
	)
}
export default ChatModal
