import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Icon from "react-icons-kit"
import { minus, plus } from 'react-icons-kit/fa'
import useStyles from './styles';
import './styles.scss'

const GuestCount = (props) => {
  const classes = useStyles();
  const { guestCount, setGuestCount, className, guestLabel, capacity, isFilter, newDesign, setIsGuestCountMaxed } = props;

  return (
    <Grid xs={newDesign ? 7 : 12} md={6} lg={6} item className={className}>
      <Typography style={isFilter ? { color: `rgb(26, 4, 236) !important`, marginBottom: 0, marginTop: 0 } : { minWidth: 100 }} variant="body2" component="div" className={`${guestLabel} ${isFilter ? classes.color : ''}`}>
        Group Size
      </Typography>
      <div style={{ width: 'auto' }}>
        <ButtonGroup
          size="small"
          aria-label="outlined secondary button group"
        >
          <Button
            onClick={() => {
              guestCount - 1 >= 1 && setGuestCount(guestCount - 1)
              setIsGuestCountMaxed(false)
            }}
            disabled={guestCount === 1}
            className={`${classes.white} grpButton gray_btn`}
          
          >
            <Icon style={{color:'#c3c2c2 !important'}} icon={minus} />
          </Button>
          <Button className={classes.white}>{guestCount}</Button>
          <Button
            onClick={() => {
              setIsGuestCountMaxed(false)
              const newGuestCount = guestCount + 1;
              if (capacity) {
                if (newGuestCount <= capacity) {
                  setGuestCount(guestCount + 1)
                  return null;
                } else {
                  setIsGuestCountMaxed(true)
                }
              } else if (newGuestCount <= 8) {
                setGuestCount(guestCount + 1)
                return null
              } else {
                setIsGuestCountMaxed(true)
              }
            }}
            // disabled={guestCount === capacity || guestCount === 8}
            className={`${classes.white} grpButton`}
          >
            <Icon icon={plus} />
          </Button>
        </ButtonGroup>
      </div>
    </Grid>
  );
};

export default GuestCount;
