import React from 'react';


// Initializing the initial state for the context( if not, the build will break )
export const initialState = {
    "step":"search",
    "searchQuery": {
		"address": null,
		"latitude": null,
		"longitude": null,
		"startDate": null,
		"guest": 1,
		"category": "alltrips",
		"min_rate": 0,
		"max_rate": null,
		"activity_type": null,
		"triptype": null,
		"min_time": null,
		"max_time": null,
		"species": null,
		"start_time": null,
		"benifits": null,
		"reviews": null,
		"languages": null,
		"ne_lat": null,
		"ne_lng": null,
		"sw_lat": null,
		"sw_lng": null,
		"zoom": null,
		"mapDrag": false,
		"page": 1,
	}
}
const MapContext = React.createContext(initialState);

export default MapContext;
