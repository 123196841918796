import{ useState, useEffect } from "react"
import { isBrowser } from "../../../widgets/common/utils";


const useBrowserResizer = () => {
	const [mobile, setmobile] = useState(null)
	useEffect(() => {
		isBrowser && window.addEventListener("resize", checkResize);
		return () => {
			isBrowser && window.removeEventListener("resize", checkResize);
		};
	},[]);
	const checkResize = () => {
		if (isBrowser && window.innerWidth <= 768) {
			setmobile(true);
		} else {
			setmobile(false);
		}
	};

	return mobile
}
export default useBrowserResizer
