import React from "react"
import dayjs from "dayjs"
import { DayPicker } from 'react-day-picker';
import styles from "../styles/Styles.module.scss"
import "../styles/dynamicdatepicker.scss"

const SearchDatePicker = ({ selectedDay, setSelectedDay, next }) => {
    const isBrowser = typeof window !== `undefined`
    const isMobile = isBrowser && window.matchMedia("only screen and (max-width: 568px)").matches
	let modifiers = {
		unavailablity: [
			{
				// before: new Date(new Date().getTime() + (48 * 60 * 60 * 1000))
				before: new Date()
			}
		],
		today: new Date(),
	}
	const modifiersStyles = {
		unavailablity: {
			color: "rgb(121, 121, 121)",
			cursor: "pointer",
			fontWeight: "normal",
			pointerEvents: "none",
			background: "#f5f5f5",
			borderRadius: "0px",
			border: "1px solid #eeeeee",
		},
		availability: {
			color: 'black',
			borderRadius: '0px',
			fontWeight: "normal",
			cursor: "pointer",
			pointerEvents: "auto",
			background: "#fff",
			
		},
		scheduleDisable: {
			color: "lightgrey",
			cursor: "pointer",
			fontWeight: "normal",
			border: "1px solid #eee",
			background: "#f5f5f5",
			pointerEvents: "none",
			borderRadius: "0px",
		},
		today:{
			fontWeight:400,
		}
	};

	const month = new Date().setHours(0, 0, 0, 0)
	return (
		<>
			<div className={`Search`}>
				<DayPicker
					defaultMonth={selectedDay ? dayjs(selectedDay).toDate() : null}
					numberOfMonths={isMobile ? 1 : 2}
					fromMonth={new Date(month)}
					modifiers={modifiers}
					modifiersStyles={modifiersStyles}
					selected={selectedDay ? dayjs(selectedDay).toDate() : null}
					onDayClick={(e) => { setSelectedDay(dayjs(e).format("YYYY-MM-DD")); next() }}
				/>
			</div>
			<span className={styles.ClearDate} onClick={() => setSelectedDay(null)}>Clear Date</span>
		</>
	)
}
export default SearchDatePicker
