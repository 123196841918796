import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  margin: {
    margin: '0 auto'
  },
  btnClear : {
    display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
  },
  form: {
    width: '100%',
    "& input": {
      padding: '7px 10px !important',
      height: 30,
      backgroundColor: '#fff',
      color: 'black'
    }
  },
  rateCardContainer: {
    border: '1px solid #c7cbce',
    backgroundColor: 'white',
    boxShadow: '0 6px 16px rgba(0,0,0,.2)!important'
  },
  block: {
    display: 'block !important'
  },
  heading: {
    fontWeight: 'bolder',
    display: 'block'
  },
  fullWidth: {
    width: '100%'
  },
  instantBook: {
    fontWeight: 600,
    padding: '5px 20px',
    color: 'white !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '0 auto',
    backgroundColor: '#086edc!important',
    border:' 4px solid #086edc!important',
    boxShadow: '0 8px 10px -6px #000',
    '&:hover': {
      color: 'white !important',
      background: '#20b4e4',
      backgroundColor: '#086edc !important',
      border:' 4px solid #086edc !important',
    },
    textTransform: 'unset',
    marginBottom: 10,
    '&:disabled': {
      cursor: 'not-allowed'
    },
    width: '100%'
  },
  disabledInstantBook: {
    fontWeight: 600,
    padding: '5px 20px',
    color: '#086edc !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '0 auto',
    border:' 4px solid #086edc!important',
    boxShadow: '0 8px 10px -6px #000',
    '&:hover': {
      color: '#086edc !important',
      border:' 4px solid #086edc !important',
    },
    textTransform: 'unset',
    marginBottom: 10,
    '&:disabled': {
      cursor: 'not-allowed'
    },
    width: '100%'
  },
  root: {
    width: 650,
    padding: '6px 15px',
    backgroundColor: '#efefef',
    '@media screen and (max-width: 568px)': {
      width: '100%',
    }
  },
  maxRoot: {
    maxWidth: 'inherit',
    padding: '6px 15px',
    backgroundColor: '#efefef'
  },
  paymentRoot: {
    maxWidth: 900
  },
  noPadding: {
    padding: 0
  },
  disabled: {
    opacity: 0.65,
    color: 'white !important'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  previous: {
    float: 'right',
    color:' #20b4e4',
    border: '1px solid #20b4e4!important',
    boxShadow: '0 6px 10px -6px #000',
    backgroundColor: '#fff !important',
    padding: 9,
    width: 100,
    '&:hover': {
      color:' #20b4e4',
      border: '1px solid #20b4e4!important',
      boxShadow: '0 6px 10px -6px #000',
    },
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
  },
  pos: {
    marginBottom: 12,
  },
  subtitle1: {
    fontSize: 13,
    fontWeight: 700,
    color: '#1a691a !important',
    marginRight: 10,
    marginTop: 5,
  },
  divider: {
    marginTop: 5,
    marginBottom: 5,
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  continue: {
    color: 'white',
    backgroundColor: '#1a04ec!important',
    border:' 4px solid #1a04ec!important',
    boxShadow: '0 8px 10px -6px #000',
    '&:hover': {
      color: 'white',
      background: '#20b4e4',
      backgroundColor: '#1a04ec!important',
      border:' 4px solid #1a04ec!important',
    },
  },
  actionBar: {
    float: 'right',
  },
  selectedGuest: {
    border: '1px solid #497049',
    backgroundColor: '#c7d9be!important',
  },
  customInput: {},
  customText: {
    margin: 5,
    fontSize: 'inherit',        
  },
  customGuest: {
    width: 50,
    margin: 5,
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&:-webkit-inner-spin-button': {
      opacity: 1,
    },
    '&:-webkit-outer-spin-button': {
      opacity: 1,
    },
  },
  selectedCustomGuest: {
    width: 50,
    margin: 5,
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&:-webkit-inner-spin-button': {
      opacity: 1,
    },
    '&:-webkit-outer-spin-button': {
      opacity: 1,
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  dayPicker: {
    margin: '0 auto',
    'padding-bottom': 10,
  },
  guestLabel: {
    paddingBottom: 5,
  },
  input: {
    border: 'none !important',
    padding: '0px 10px !important',
    marginBottom: '0 !important',
    background: 'none !important',
    width: '80px !important',
    fontSize: '14px !important'
  },
  calendarModal: {
    width: 300,
    margin: '10% auto',
    borderRadius: 5,
    paddingBottom: 5,
    '&:focus': {
      outline: 'none'
    }
  },
  closeContainer: {
    padding: 10, 
    borderBottom: `1px solid grey`
  },
  chooseTripDate: {
    color: '#1a04ec',
    fontSize: '1rem'
  },
}));
