import React, { useRef, useState, useContext, useEffect } from 'react'
import Icon from "react-icons-kit";
import { minus, plus, search } from 'react-icons-kit/fa';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import "../styles/customdatepicker.scss";
import dayjs from 'dayjs';
import styles from "../styles/searchmodal.module.scss";
import MapContext from '../../../../widgets/state/context/MapContext';
import { clearFalseyValues, isBrowser } from '../../../../widgets/common/utils';
import HomeSearchMobile from './HomeSearchMobile';

const initialstate = {
	"city": null,
	"state": null,
	"address": null,
	"latitude": null,
	"longitude": null
}
const SearchModal = ({ close, handleSearch, from, setZoomCount , searchQuery}) => {
	const context = useContext(MapContext)
	const [expanded, setExpanded] = useState({ tab1: true, tab2: false, tab3: false });
	const [date, setDate] = useState(context.datefromHome && context.datefromHome != "" ? dayjs(context.datefromHome).toDate() : null);
	const [address, setAddress] = useState(context.searchedValue);
	const [alldetails, setAllDetails] = useState(initialstate)
	const [adult, setAdult] = useState(context.count ? context.count : 0);
	const [child, setChild] = useState(0);
	const [reset, setReset] = useState(false);
	const [calendarView, setCalendarView] = useState(true);
	const [flexibleDate, setFlexibleDate] = useState(false);
	const ref = useRef();
	useEffect(() => {
		setAddress(context.searchedValue);
	}, [context.searchedValue]);
	const closeModal = () => {
		ref.current.classList.add(styles.hide);
		setTimeout(() => {
			close();
		}, 350)
	}
	useEffect(() => {
		if ((!alldetails?.state && alldetails.state != "") && (!alldetails.address && alldetails.address != "")) {
			setAllDetails({
				"city": context.searchedCity,
				"state": context.searchedState,
				"address": context.searchedValue,
				"latitude": context.location?.lat ||searchQuery?.latitude,
				"longitude": context.location?.lng||searchQuery?.longitude,
			})
		}
	}, [context.location, context.searchedValue, context.searchedState])

	useEffect(() => {
		if ((adult + child) > 0) {
			context.setCount(adult + child)
		}
	}, [adult, child])
	const groupCount = {
		"Adults": { count: adult, setCount: setAdult, min: 0 },
		"Children": { count: child, setCount: setChild, min: 0 },
	}
	const handleDate = (date1) => {
		const getDate = date1 ? dayjs(date1).format('YYYY-MM-DD') : null
		context.setEndDate(dayjs(date1).format("YYYY-MM-DD"))
		context.setDateFromHome(getDate);
		context.setStartDate(getDate);
		setDate(date1);
		setExpanded({ tab1: false, tab2: false, tab3: true });
	};
	// console.log(alldetails, address, context.searchedValue, date, context.datefromHome, "ss")
	const renderGuests = (title, subTitle) => {
		return (
			<div key={title} className={styles.guests__container}>
				<div className={styles.guests__text}>
					<div>{title}</div>
					<div>{subTitle}</div>
				</div>
				<div className={styles.guests__button}>
					<button type="button" id="group-size-decrement"
						className={styles.guests__button__icon}
						disabled={groupCount[title].count === groupCount[title].min ? true : false}
						onClick={() => groupCount[title].count > groupCount[title].min && groupCount[title].setCount(groupCount[title].count - 1)}>
						<Icon icon={minus} />
					</button>
					<span className={styles.form__guest__count}>{groupCount[title].count}</span>
					<button type="button" id="group-size-increment"
						className={styles.guests__button__icon}
						onClick={() => groupCount[title].setCount(groupCount[title].count + 1)}>
						<Icon icon={plus} />
					</button>
				</div>
			</div>
		)
	}
	const handleClearDate = () => {
		setDate('')
		context.setEndDate(null)
		context.setDateFromHome(null);
		context.setStartDate(null);
	};
	const handleClearAll = () => {
		setDate('');
		setAddress("")
		setAllDetails(initialstate)
		setAdult(0);
		setChild(0);
		setAddress("");
		context.setClearSearch(true)
		context.setMapDrag(false)
		setReset(true);
		// if (handleClear) {
		// 	handleClear()
		// 	closeModal();
		// }
		setExpanded({ tab1: false, tab2: false, tab3: false });
	}
	const clearContext = () => {
		const { city, state, address } = alldetails
		context.setEndDate(null)
		context.setDateFromHome(null);
		context.setStartDate(null);
		context.setSearchedValue(city ? city : "");
		context.setSearchedCity(state ? state : "");
		context.setSearchedState(address ? address : "");
		context.setCount(0);
	}
	const intialObj = {
		"latitude": alldetails.latitude, "longitude": alldetails.longitude,
		"page": 1, "address": alldetails.address, zoom: null,
		"ne_lat": null,
		"ne_lng": null,
		"sw_lat": null,
		"sw_lng": null,
		"mapDrag": null,
		"startDate": date ? dayjs(date).format('YYYY-MM-DD') : null,
		"guest": adult + child,
	}
	const getAllQuerry = () => {
		const { city, state, address, latitude, longitude } = alldetails
		let query = intialObj
		if (!city && city !== state) {
			query = { ...query, "address": address, "latitude": latitude, "longitude": longitude, }
		}
		if (!state) {
			query = { ...query, "address": address, "latitude": latitude, "longitude": longitude, }
		}
		if (date !== null) {
			query = { ...query, "startDate": date ? dayjs(date).format('YYYY-MM-DD') : null }
		}
		if ((adult + child) > 1) {
			query = { ...query, "guest": (adult + child) }
		}
		if (context.searchPagination) {
			query = { ...query, "page": 1 }
		}
		if (city === state) {
			query = { ...query, "city": null }
		}
		if (state === "" && address === "") {
			query = { ...query, "latitude": null, "longitude": null, }
		}
		return query
	}
	const setAllContext = () => {
		context.setSearchedCity(alldetails.city ? alldetails.city : "")
		context.setSearchedState(alldetails.state ? alldetails.state : "")
		context.setSearchedValue(address ? address : "")
	}
	// console.log(alldetails, address, "gg")
	const handleOnSearch = (e) => {
		e.preventDefault();
		setAllContext()
		let query = getAllQuerry()
		const s = { ...query,...context.searchQuery,  }
		context.setSearchQuery(s)
		if (setZoomCount) {
			setZoomCount(0)
		}
		if (query) {
			const omitFalseyValues = clearFalseyValues(query);
			const url = new URLSearchParams(omitFalseyValues);
			const stringifiedURLParams = url.toString();
			let newPath = `/search`;
			if (stringifiedURLParams) {
				newPath = `${newPath}?${stringifiedURLParams}`;
			}
			if(isBrowser){
				window.location.href = newPath
			}
			// navigate(newPath);
			closeModal();
		}
	};
	const handleSearchPage = () => {
		if (context.clearSearch) {
			let Clear = intialObj
			clearContext()
			handleSearch(Clear)
			closeModal();
			context.setClearSearch(false)
		} else {
			setAllContext()
			let data = getAllQuerry()
			handleSearch(data)
			closeModal();
			context.setClearSearch(false)
		}
	}
	const handleDateFlexible = () => {
		setCalendarView(!calendarView)
		setFlexibleDate(!flexibleDate)
		setExpanded({ tab1: false, tab2: false, tab3: true })
		setDate(null)
		context.setEndDate(null)
		context.setDateFromHome(null);
		context.setStartDate(null);
	}
	let modifiers = {
		unavailablity: [
			{
				// before: new Date(new Date().getTime() + (48 * 60 * 60 * 1000))
				before: new Date()
			}
		],
		Avail: [
			{
				// after: new Date(new Date().getTime() + (48 * 60 * 60 * 1000))
				after: new Date()
			}
		],
		today: new Date(),
	}
	const modifiersStyles = {
		unavailablity: {
			color: "lightgrey ",
			cursor: "pointer",
			fontWeight: 600,
			border: "3px solid transparent",
			pointerEvents: "none "
		},
		Avail: {
			color: "black ",
			cursor: "pointer",
			fontWeight: 600,
			border: "3px solid transparent",
		},
		today: {
			// border: "3px solid #20B4E4"
			// color: "#1d05ec !important",
		},
	};
	return (
		<div ref={ref} className={styles.searchModal}>
			<div className={styles.closeButton}>
				<button type="button" className={styles.close} onClick={closeModal}>
					<span aria-hidden="true">×</span>
				</button>
				<div className={styles.title}>Find Your Trip</div>
			</div>
			<div className={styles.cards}>
				<div className={`${styles.cards__item} ${styles.cards__where}`} style={{ position: "relative" }}>
					<div className={styles.cards__item__summary}
						style={expanded.tab1 ? { display: 'none' } : { display: "flex" }}
						onClick={() => setExpanded({ tab1: true, tab2: false, tab3: false })}>
						<div className={styles.cards__item__label}>Where</div>
						<div className={styles.cards__item__value}>{address ? address : "I'm flexible"}</div>
					</div>
					<div className={styles.cards__item__expanded}
						style={expanded.tab1 ? { display: 'block' } : { display: "none" }}>
						<div className={styles.cards__item__expanded__title}>Where to?</div>
						<div className={styles.cards__item__expanded__input} style={{ padding: "0px 20px" }}>
							<span>
								<Icon
									icon={search}
									className="Searchicon"
									size={20}
								/>
							</span>
							{/* {location.pathname === "/" && <HomeSearch reset={reset} setReset={setReset} for="home-mobile" />}
							{location.pathname.includes("/search") && <HomeSearch reset={reset} setReset={setReset} from="SearchPage" />} */}
							<HomeSearchMobile reset={reset} setReset={setReset} from="home-mobile"
								setAddress={setAddress} address={address} alldetails={alldetails} setAllDetails={setAllDetails} />
						</div>
					</div>
				</div>
				<div className={`${styles.cards__item} ${styles.cards__when}`} >
					{!expanded.tab2 && <div className={styles.cards__item__summary} onClick={() => setExpanded({ tab1: false, tab2: true, tab3: false })}>
						<div className={styles.cards__item__label}>When</div>
						<div className={styles.cards__item__value}>{date ? dayjs(date).format('MMM D') : flexibleDate ? "I'm flexible" : "Add dates"}</div>
					</div>}
					{expanded.tab2 && <div className={styles.cards__item__expanded}>
						<div className={styles.cards__item__expanded__title}>When's your trip?</div>
						<div className={styles.cards__item__expanded__ButtonView}>
							<div className={styles.btnCont}>
								<button onClick={() => { setCalendarView(!calendarView); setFlexibleDate(!flexibleDate) }}
									className={calendarView ? styles.BtnActive : styles.BtnInActive}>Choose Date</button>
								<button onClick={() => handleDateFlexible()}
									className={flexibleDate ? styles.BtnActive : styles.BtnInActive}> I'm flexible</button>
							</div>
						</div>
						{calendarView && <>
							<div className={`${styles.cards__item__expanded__input} customSearch`} style={{ padding: 0, border: "none" }}>
								{/* <Calendar className={styles.react_calender} onChange={handleDate} value={date} minDate={new Date()}/> */}
								<DayPicker
									defaultMonth={date && date != "" ? dayjs(date).toDate() : new Date()}
									fromMonth={new Date()}
									modifiers={modifiers}
									modifiersStyles={modifiersStyles}
									selected={date != "" ? dayjs(date).toDate() : null}
									onDayClick={handleDate}
									numberOfMonths={1}
									pagedNavigation
								/>
							</div>
							<div className={styles.react_calender__clear} onClick={handleClearDate}>Clear</div>
						</>}
					</div>}
				</div>
				<div className={`${styles.cards__item} ${styles.cards__who}`}>
					{!expanded.tab3 && <div className={styles.cards__item__summary} onClick={() => setExpanded({ tab1: false, tab2: false, tab3: true })}>
						<div className={styles.cards__item__label}>Who</div>
						<div className={styles.cards__item__value}>{(adult + child) > 0 ? `${(adult + child) > 1 ? `${adult + child} guests` : "1 guest"}` : "Add guests"}</div>
					</div>}
					{expanded.tab3 && <div className={styles.cards__item__expanded}>
						<div className={styles.cards__item__expanded__title}>Who's coming?</div>
						<div className={styles.cards__item__expanded__input} style={{ padding: 0, border: "none", flexDirection: "column" }}>
							{renderGuests("Adults", "Ages 13 or above")}
							{renderGuests("Children", "Ages 2-12")}
						</div>
					</div>}
				</div>
			</div>
			<div className={styles.footer}>
				<div className={styles.footer__button1} onClick={handleClearAll}>Clear all</div>
				<div>
					<button className={`${styles.footer__button2} button`} onClick={(e) => {
						if (from && from === "searchpage") {
							handleSearchPage()
						} else {
							context.setClearSearch(false)
							handleOnSearch(e)
						}
					}
					}>
						<span>
							<Icon
								icon={search}
								className="Searchicon"
								size={18}
								style={{ color: "white", paddingRight: "10px" }}
							/>
						</span>
						Search
					</button>
				</div>
			</div>
		</div>
	)
}
export default SearchModal
