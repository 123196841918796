import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { get } from '../../widgets/utils/axiosHelper'
import axios from 'axios'
import Sun from '../../assets/ico_sun-01.svg'
import Wind from '../../assets/ico_windy-01.svg'
import Humid from '../../assets/ico_waterdrop-01.svg'
import moment from 'moment'
import './styles.scss'
import { WeatherStore,setCacheWeather,getCachedWeather } from '../../widgets/utils/weather';

const WeatherCalender = (props) => {
	const [weeklyData, setWeeklyData] = useState("")
	const [city, setCity] = useState("")

	useEffect(()=>{
		const url = `guide/widget_weather_location?id=${props.id}`;
		get(url)
		.then(async (res = {}) => {
			var cityData = res.data.city
			const weatherData = getCachedWeather()
				if (weatherData === null || (new Date().getHours() > new Date(weatherData.Expiry).getHours()) ||
					res.data.latitude != weatherData.lat && res.data.longitude != weatherData.lng) {
					await getWeatherAPI(res.data.latitude, res.data.longitude, cityData);
				} else {
					setWeeklyData(getCachedWeather().weather_days)
					setCity(getCachedWeather()?.city )
				}
		}).catch((e) => {
			console.log('API Error: reviews', e)
		});
	},[])
	const getWeatherAPI=(latitude,longitude, cityData)=>{
		axios.get(`https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${latitude},${longitude}?key=UUVHGZ6JD4H9A5GSQ3QMR54V5`)
			.then(response => {
				setCity(cityData)
				const Finaldata = WeatherStore(response.data, latitude, longitude, cityData)
				setWeeklyData(response.data.days.slice(0,8))
				setCacheWeather(Finaldata)
			})
			.catch(err => {
				if (err.response) {
					console.log(err.response.status)
				}
			})
	}

	return (
		<div>
			<Typography>Weather Calendar</Typography>
			<Typography component="div" >
			<div className={'weatherCalendarContainer'}>
				<div className={'flexContainer'}>
					{
						weeklyData &&
						<div>
							<div>
								<span>Location</span>
							</div>
							<div className={'cityName'}>
								{city}
							</div>
						</div>
					}
					{
						weeklyData && weeklyData.map((data,i) => {
							return(
								<div key={i}>
									<div>
										<span>{i === 0 ? moment().calendar().split(' ')[0] : moment(data.datetime, 'YYYY-MM-DD').format('LLLL').split(',')[0]}</span>
									</div>
									<div className={'dataContainer'}>
										<div className={'temperature'}>
											<div><img src={'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/ico_sun-01.svg'} width={30} height={30}></img></div>
												<div>
													<div className={'maxtemperature'} >{data.tempmax}° F</div>
													<div className={'mintemperature'} >{data.tempmin}° F</div>
												</div>
										</div>
											<div className={'humidity'}>
												<div><img src={'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/ico_waterdrop-01.svg'} width={25} height={15}></img></div>
												<div>{data.humidity}%</div>
												<div className={'precip'}>{data.precip}in</div>
											</div>
										<div className={'windflow'}><img src={'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/ico_windy-01.svg'} width={30} height={15}></img>{data.windspeed} mph</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
			</Typography>
		</div>
	)
}
export default WeatherCalender;

