import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    widgetModal: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        scale:"0.8"
      },
      widgetWindow: {
        display: 'block',
        position: 'absolute',
        bottom: '0px',
        right: '-50px',
        transform: 'translateX(100%)',
        transition: 'transform 0.3s ease-in-out',
        // backgroundColor: 'whitesmoke',
        // border: '1px solid #333',
        borderRadius: '8px',
        zIndex: 9,
        height: 'max-content',
        background:"black",
        borderRadius: '20px',
        
      },
        open: {
          right: '10px',
          transform: 'translateX(0)',
          bottom: '10px',
          zIndex: 99,
        },
      widgetHeader: {
        width: '350px',
      },
      // commonIcons: {
      //   cursor: 'pointer',
      //   float: 'right',
      //   background: '#e9e9e9',
      //   width: '22px',
      //   height: '22px',
      //   borderRadius: '50%',
      //   textAlign: 'center',
      // },
      closebtn: {
        fontSize: '26px',
        color: "white",
        position: "absolute",
        right: "30px",
        top: "15px",
        cursor: "pointer",
      },
      opac__booknow__button: {
        position: 'fixed',
        bottom: '35px',
        right: '20px',
        zIndex: 9,
        borderRadius: '8px',
        width: '140px',
        height: '50px',
        minHeight: '50px',
        cursor: 'pointer',
        padding: '14px 0px',
        border: 'none',
        outline: 'none',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
        boxShadow: '0px 16px 24px -8px rgba(0, 0, 0, 0.24)',
        color: 'rgba(255, 255, 255)',
      },
}))