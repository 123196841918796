import React, { useEffect } from 'react';
import { initMapkitJS } from './utils';


const AppleMapkit = ({ renderMap }) => {
	useEffect(() => {
		const isBrowser = typeof window !== `undefined`
		if (isBrowser) {
			try {
				const mapkitjsElement = document.createElement('script')
				mapkitjsElement.src = "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js"
				mapkitjsElement.async = true
				mapkitjsElement.onload = () => {
					initMapkitJS()
				}
				document.body.appendChild(mapkitjsElement)
			} catch (exception) {
				console.error("Failed to init apple-mapkit", exception);
			}
			/* we are using mapkit only for auto-complete, if we want to render maps in future we need to send this prop. */
			if (renderMap) {
				var map = new window.mapkit.Map("mapkit");
			}
		}
	}, [])
	return <div id="mapkit"></div>;
}

export default AppleMapkit;
