import React, { useState, useContext } from "react"
import "./customdatepicker.scss"
import moment from 'moment';
import { DayPicker } from 'react-day-picker';
import "react-datepicker/dist/react-datepicker.css"
import 'react-day-picker/dist/style.css';
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather";
import TripDetailsContext from "../../state/context/TripDetailsContext";
import { getAvailabilityScheduledForAllRateCards } from "./guide-details";

const CustomSingleDatePicker = ({ handleDayClick, isPrevious, setCalendarShow, setShow,setMeeetingPointLocal,setMeeetingPoint, to, setLocalTripTime, setLocalTripType, handleClear }) => {
	const tripDetailsContext = useContext(TripDetailsContext)
	const { setSelectedDay,
		selectedDay,
		selectedRateCard,
		setSelectedRateCard,
		setSelectedHour,
		schrecords,
		setModifiers,
		modifiers,
		setSource
	} = tripDetailsContext
	const isBrowser = typeof window !== `undefined`
	const isMobile = isBrowser && window.matchMedia("only screen and (max-width: 768px)").matches
	const [date, setDate] = useState({
		from:!selectedDay&& modifiers.availability.length > 0 ? modifiers.availability[0] : selectedDay,
		active: 'start',
	});
	const modifiersStyles = {
		unavailablity: {
			color: "lightgrey",
			cursor: "pointer",
			fontWeight: "bold",
			width: "10px !important",
			border: "3px solid transparent",
			pointerEvents: "none"
		},
		availability: {
			color: 'black',
			borderRadius: '50%',
			fontWeight: 600,
			cursor: "pointer",
			width: "10px !important",
			pointerEvents: "auto"
		},
		scheduleDisable: {
			color: "lightgrey",
			cursor: "pointer",
			fontWeight: "bold",
			width: "10px !important",
			border: "3px solid transparent",
			pointerEvents: "none"
		},

	};
	const handleDayClickInternal = (d) => {
		setDate({ ...date, from: d })
			// d && handleDayClick(moment(d).toDate(), modifiers)
		if (d === null) {
			setCalendarShow && setCalendarShow(false)
			setShow(false)
		}
	}
	const resetDate =()=>{
		const allSCH = schrecords?.schedule
		const { availability, scheduleDisable } = getAvailabilityScheduledForAllRateCards({ "availableSchedules": allSCH });
		setModifiers({
		  ...modifiers,
		  availability,
		  scheduleDisable,
		  // availableSchedule: availability
		  schedule: schrecords?.schedule
		});
	}
	const handleReset = () => {
		setDate({ ...date, from: null });
		setSelectedDay(null);
		setSource("rates")
		resetDate()
		setMeeetingPoint(null)
		setMeeetingPointLocal(null)
	}

	const handleSave = () => {
		date?.from && handleDayClick(moment(date.from).toDate(), modifiers)
		if (date.from === null) {
			setCalendarShow && setCalendarShow(false)
			if(!date||date?.from === ""){
				handleClear()
			}
		}
		if (setShow) {
			setShow(false)
			if(!date ||date?.from === ""){
				handleClear()
			}
		}
		resetDate()
	}
	const dat = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).setHours(0, 0, 0, 0)
	const tomonth = new Date(dat)
	const month = new Date().setHours(0, 0, 0, 0)
	const month1 = new Date(date.from)

	const prev = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(0, 0, 0, 0)
	const { active, from } = date
	return (
		<div>
			<Icon icon={x} className={"CloseIcon"} size={20} onClick={() =>{ 
				setShow(false)
				if(!date ||date?.from === ""){
					handleClear()
				}
				}} />
			<div className={"Header"}>
				<div className={"Content"}>
					<p className={"title"}>{selectedRateCard.title ? selectedRateCard.title : `All Trips Offered`} </p>
					<p className={"subtitle"}>{from ? moment(from).format('MMM Do,	YYYY') : 'Choose a date'}</p>
				</div>
				<div className={"Dates"}>
					<div
						className={active === 'start' ? "Active" : "Disable"}
						onClick={() => setDate({ ...date, active: 'start' })}
					>
						<p className={"title1"}>Your Trip Date</p>
						<p className={"choosenDate"}>
							{' '}
							{from ? moment(from).format('MMM Do,	YYYY') : 'Pick a date'}
						</p>
					</div>
				</div>
			</div>
			<div>
				<DayPicker
					className="custom"
					// month={date.from ? month1 : null}
					numberOfMonths={isMobile ? 1 : 2}
					fromMonth={isPrevious ? new Date(prev) : new Date(month)}
					toMonth={isPrevious ? new Date() : tomonth}
					modifiers={modifiers}
					modifiersStyles={modifiersStyles}
					// showOutsideDays
					// className={Calendar}
					// selectedDays={context.selectedTime === "2 Full Days" || context.selectedTime === "Boat 2 Full Days" ? [start, end] : [start]}
					//  selectedDays={moment(date.from).toDate()}
					selected={moment(date.from).toDate()}
					onDayClick={handleDayClickInternal}
				/>
				<div>
					<div className={"btnCont"}>
						<p className={"clear"} onClick={handleReset}>
							Clear dates
						</p>
						<button
							className={`gradientBtn bookNowBtn`}
							// disabled={(!from || from === "") && ` btn--blue--solid`}
							onClick={handleSave}
							style={{ padding: "0px 20px", fontSize: "14px", height: "30px" }}
						>
							Save
						</button>
					</div>
				</div>
				<style>{`
					.custom{
						margin: 0px auto;
						display: block;
					}
					.custom .rdp-day_selected:not(.rdp-day_start):not(.rdp-day_end):not(.rdp-day_outside) {
						color: white !important;
						background-color: #1a04ec !important;
						border-radius:0px !important;
						font-weight:500;
					}
					.custom .rdp-day--selected:not(.rdp-day_disabled):not(.rdp-day_outside) {
						position: relative;
						background-color: #B0B0B0 !important;
						color: black;
						border-radius:5px !important;
					font-weight:600;
					}

					.custom .rdp-month{
						outline: none;
						border-collapse: inherit !important;
						margin:15px 10px !important;
					}
					.custom	.rdp-day  {
						outline:none !important;
						font-size:12px;
						height: auto !important;
						width:25px !important;
					}

					.custom .rdp-wrapper{
						padding:0px !important;
					}
					.custom .rdp-caption {
						margin-bottom: 0px;
						font-size: 17px;
					}
					.custom .rdp-months{
						justify-content: space-between !important;
					}
					@media only screen and (max-width: 500px) {
						.custom .rdp-month {
							outline: none;
							border-collapse: inherit !important;
							margin: auto !important;
						}
						.custom .rdp-months {
							justify-content: center !important;
							padding-bottom:7px;
						}
						.custom .rdp-nav{
							top:0px;
							color:black;
						}
					}
					@media only screen and (max-width: 768px) {
						.custom .rdp-months {
							justify-content: center !important;
							padding-bottom:7px;
						}

					}
				`}</style>
			</div>
		</div>
	)
}
export default CustomSingleDatePicker
