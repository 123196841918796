import React, { useContext, useState, } from 'react'
import Helmet from "react-helmet"
import { DayPicker } from 'react-day-picker';
import "react-datepicker/dist/react-datepicker.css"
import 'react-day-picker/dist/style.css';
import { daypickerStyles } from './styles';

const CustomDayPicker = ({
  month,
  fromMonth,
  toMonth,
  modifiersStyles,
  modifiers,
  className,
  selectedDay,
  onDayClick,
  numberOfMonths = 1,
}) => {

  return (
    <>
      <Helmet>
        <style>{daypickerStyles}</style>
      </Helmet>
      <DayPicker
        month={month}
        fromMonth={fromMonth}
        toMonth={toMonth}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        className={className}
        selectedDays={selectedDay}
        onDayClick={onDayClick}
        numberOfMonths={numberOfMonths}
      />
    </>
  )
}

export default CustomDayPicker