import React,{useState,useEffect,useRef, useContext} from "react"
import useStyes from './styles';
import Icon from "react-icons-kit";
import { minus, plus } from 'react-icons-kit/fa';
import {DayPicker} from 'react-day-picker';
import dayjs from 'dayjs';
import 'react-day-picker/dist/style.css';
import HomeSearch from "../homeSearch";
import { getMyAddress, setSearchedData } from "../../common/storage";
import useLoadStyle from "../useLoadStyle";
import "../styles/daypicker.css"
import Fontstyle from "../Fonts";
import { clearFalseyValues } from "../../common/utils";
import { x } from "react-icons-kit/feather";
import InitLocation from "../../../seo-widgets/guidefinder/components/common/initLocation";
import MapContext from "../../state/context/MapContext";
import useClearLocation from "../../../seo-widgets/guidefinder/components/common/useClearLocation";
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

const Template1 =({xAppID,src, toggleModal})=>{
   useLoadStyle(xAppID);
   const context = useContext(MapContext)
   const isBrowser = typeof window !== `undefined`;
   const Styles = useStyes();
   const [selectedOption, setSelectedOption] = useState("")
   const [tripDate, setTripDate] = useState(null)
   const [location, setLocation]=useState()
   const [adult, setAdult] = useState(1);
   const [child, setChild] = useState(0);
   const [adultNew, setAdultNew] = useState(1)
   const [childNew, setChildNew] = useState(0);
   const groupSize = useRef(null)
   const dateField = useRef(null)
   const groupCount = {
       "Adults": { count: adult, setCount: setAdult, min: 0 },
       "Children": { count: child, setCount: setChild, min: 0 },
   }
   const isLocationCleared =useClearLocation()
   useEffect(() => {
       return()=>{
           handleClear()
       }
   }, [])
   useEffect(() => {
    if (context.searchedCity) {
        const result = getMyAddress();
        if (result && result.address) {
            const { address, lat, lng } = result;
            setLocation({
                address: address,
                latitude: lat,
                longitude: lng
            });
        }
    }
}, [context.searchedCity])
   const handleClear=()=>{
       setTripDate(null)
       setLocation(null)
       setAdult(1)
       setChild(0)
       setAdultNew(1)
       setChildNew(0)
   }
   let modifiers = {
       unavailablity: [
           {
               before: new Date()
           }
       ],
       Avail: [
           {
               after: new Date()
           }
       ],
       today: new Date(),
   }
   const modifiersStyles = {
       unavailablity: {
        color:" rgb(121, 121, 121)",
           cursor: "pointer",
           fontWeight: 600,
           border: "3px solid transparent",
           pointerEvents: "none ", 
           backgroundColor: "rgb(245, 245, 245)",
			border: "1px solid #eeeeee",
       },
       Avail: {
           color: "black ",
           cursor: "pointer",
           fontWeight: 600,
           border: "1px solid #eee",
       },
       today:{
        border: "1px solid #eee",
       }
   };
   const renderGuests = (title, subTitle) => {
       return (
           <div key={title} className={`${Styles.guests__container} ${Styles.guests}`}>
               <div className={Styles.guests__text}>
                   <div>{title}</div>
                   <div>{subTitle}</div>
               </div>
               <div className={Styles.guests__button}>
                   <button type="button" id="group-size-decrement"
                       className={Styles.guests__button__icon}
                       disabled={groupCount[title].count === groupCount[title].min ? true : false}
                       onClick={() => groupCount[title].count > groupCount[title].min && groupCount[title].setCount(groupCount[title].count - 1)}>
                       <Icon icon={minus} />
                   </button>
                   <span className={Styles.form__guest__count}>{groupCount[title].count}</span>
                   <button type="button" id="group-size-increment"
                       className={Styles.guests__button__icon}
                       onClick={() => groupCount[title].setCount(groupCount[title].count + 1)}>
                       <Icon icon={plus} />
                   </button>
               </div>
           </div>
       )
   }
const handleSearchButton=()=>{
   const formData ={
       ...location,
       startDate:tripDate && tripDate != ""?dayjs(tripDate, "MM-DD-YYYY").format("YYYY-MM-DD"):null, 
       guest:adultNew + childNew
   }
   if(formData?.address === ""|| !formData?.address){
    formData.address = context.searchedCity;
}
   let filterparam = { ...formData }
   const omitFalseyValues = clearFalseyValues(filterparam)
   const url = new URLSearchParams(omitFalseyValues);
   const stringifiedURLParams = url.toString();
   if (isBrowser&&stringifiedURLParams) {
       window.location.href = `/search?${stringifiedURLParams}&page=1`
   } 
   // setSearchedData(formData)
   // handleClear()
   if(src&&src ==="t2"){
       toggleModal()
   }
}
const handleClearDate = (e) => {
    e.stopPropagation()
   setSelectedOption('')
   setTripDate(null)
};
const handleSearch =(data)=>{
   setLocation(data)
}
const handleDate = (date1) => {
   setTripDate(dayjs(date1).format("MM-DD-YYYY"));
   setSelectedOption('')
};
const cdnPath = `https://dlsmyzcs6vrg4.cloudfront.net/web/static/images`
   return(
       <>
       <Fontstyle xAppID={xAppID}/>
       {
                isLocationCleared||(!context.searchQuery?.latitude && !context.searchQuery?.longitude && (!context.searchQuery?.address)) ?
                    <InitLocation setSearchQuery={context.setSearchQuery}
                        searchQuery={context.searchQuery}
                        setMapLoading={() => { }} /> : null
            }
       <div style={{width:"350px"}} className={"FontStyle"}>
               {selectedOption == "guests" ?
                   <div className={`${Styles.heroCalendar1} ${Styles.slideIn}`} ref={groupSize}>
                       <img src={`${cdnPath}/normal_back_u300.svg`}
                           width={40} height={40} alt="back"
                           onClick={() => {
                               if (isBrowser) {
                                   groupSize.current.classList.remove(`${Styles.slideIn}`);
                                   groupSize.current.classList.add(`${Styles.slideOut}`);
                                   setTimeout(() => {
                                       setSelectedOption("")
                                   }, 400)
                               }
                           }}
                           className={Styles.marginbottom} />
                       {renderGuests("Adults", "Ages 13 or above")}
                       {renderGuests("Children", "Ages 2-12")}
                       <button className={`button ${Styles.saveBtn} ${Styles.margintop}`} onClick={() => { setAdultNew(adult); setChildNew(child); setSelectedOption("") }}>Save</button>
                   </div> :
                   selectedOption == "date" ?
                       <div className={`${Styles.heroCalendar1} ${Styles.slideIn}`} ref={dateField}>
                           <img src={`${cdnPath}/normal_back_u300.svg`}
                               width={40} height={40} alt="back"
                               onClick={() => {
                                   if (isBrowser) {
                                       dateField.current.classList.remove(`${Styles.slideIn}`);
                                       dateField.current.classList.add(`${Styles.slideOut}`);
                                       setTimeout(() => {
                                           setSelectedOption("")
                                       }, 400)
                                   }
                               }} />
                           <div className={` customSearch`}>
                           <DayPicker
                               defaultMonth={tripDate && tripDate != "" ? dayjs(tripDate,"MM-DD-YYYY").toDate() : new Date()}
                               fromMonth={new Date()}
                               modifiers={modifiers}
                               modifiersStyles={modifiersStyles}
                               selected={tripDate != "" ? dayjs(tripDate,"MM-DD-YYYY").toDate() : null}
                               onDayClick={handleDate}
                               numberOfMonths={1}
                               pagedNavigation
                           />
                           </div>
                       </div> :
                       <div className={Styles.heroCalendar}>
                           <span className={Styles.title}>Book a Guide</span>
                           <div >
                               <p> Where would you like to go
                               </p>
                               <HomeSearch address={location?.address} handleSearchData={handleSearch} />
                               <p> Trip Date
                               </p>
                               <div className={Styles.newDiv} onClick={() => setSelectedOption("date")}>
                                   <img src={`${cdnPath}/normal_u232.png`} width={20} height={20} alt="count" />
                                   <span style={{
                                       color: tripDate ? "#444" : "#979797"
                                   }}>{tripDate ? `${dayjs(tripDate, "MM-DD-YYYY")?.format('MMMM D, YYYY')}` : `Add date`}</span>
                                   {tripDate ?
                                       <div className={`Hoverbg clear-icon_X`} onClick={(e) => handleClearDate(e)}>X</div>
                                       : null}
                               </div>
                               <p> Group Size
                               </p>
                               <div className={Styles.newDiv} onClick={() => setSelectedOption("guests")}>
                                   <img src={`${cdnPath}/normal_u240.png`} width={20} height={20} alt="guests" />
                                   <span style={{
                                       color: (adultNew + childNew) > 0 ? "#444" : "#979797"
                                   }}>{(adultNew + childNew) > 0 ? `${(adultNew + childNew) > 1 ? `${adultNew + childNew} Guests` : "1 Guest"}` : "1 Guest"}</span>
                                   {(adultNew + childNew) > 1 ?
                                       <div className={`Hoverbg clear-icon_X`}
                                           onClick={(e) => { setSelectedOption(''); setAdult(0); setChild(0); setAdultNew(0); setChildNew(0); e.stopPropagation(); }}
                                       >X</div>
                                       : null}
                               </div>
                               <button className={"button"} onClick={handleSearchButton}>Find Trips</button>
                           </div>
                       </div>
               }
           </div>
           </>
   )
}
export default Template1
