import React, { useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid";
import styles  from "../styles/categoryFilter.module.scss"
import { Row, Col, Form } from "react-bootstrap";
import ReactStars from '@guidesly/guidesly-react-stars-v2'

const CommonFiltersInput = ({ options, values, handleSelected, param, title, limit, listView, showTitle }) => {
	const [selected, setSelected] = useState([])
	useEffect(() => {
		setSelected(values)
	}, [values])
	const handleChecked = (item, i) => {
		let newState = [...options];
		newState[i].checked = !newState[i].checked;
		const onlySelected = newState.filter(item => item.checked);
		setSelected(onlySelected)
		const s = []
		for (let rec of onlySelected) {
			s.push(rec.value)
		}
		handleSelected(s, param)
	}
	const renderOptions = () => {
		return (
			<>
				{options.map((item, i) => {
					return (
						<Form.Group key={i} controlId={"checkitem" + uuidv4()} className="Formgroup1" style={{ padding: "0px 0px", }}>
							<Form.Check
								type="checkbox"
								label={param === "reviews" ? <div style={{ display: "flex", alignItems: "center" }}>
									<ReactStars
										count={5}
										size={16}
										value={Number(item.value)}
										edit={false}
										color2={"black"}
										char={"★"}
									/>
									<span style={{ marginLeft: 5 }}>{item.label}</span>
								</div> : item.label}
								onChange={(e) => {
									if (limit && e.target.checked && selected.length < limit) {
										handleChecked(item, i)
									} else if (limit && !e.target.checked && selected.length <= limit) {
										handleChecked(item, i)
									} else if (!limit) {
										handleChecked(item, i)
									}
								}}
								style={title === "Hunting Trip Types" ? { textTransform: "capitalize" } : {}}
								checked={item.checked}
								className={item.checked ? styles.FormCheck1css : styles.FormCheckcss}
							/>
						</Form.Group>
					);
				})}
			</>
		)
	}

	const filterLength = selected
	return (
		<>
			{listView ?
				<div className={styles.OuterCont} style={{ marginTop: "10px" }}>
					{limit && showTitle ? <p className="feedbackText1" style={{ padding: "0px 0px 5px" }}>{title} chosen: {filterLength.length}/{limit}</p> :
						!limit && showTitle ? <p className="feedbackText1" style={{ padding: "0px 0px 5px" }}>{title} </p> : null}

					<div className={styles.BoatMain} style={{ maxHeight: "250px", minHeight: "250px", borderBottom: "none" }}>
						{renderOptions()}
					</div>
				</div>
				:
				<Row style={{ margin: "10px 0px 0px" }}>
					<Col style={{ columnCount: "2", padding: "0px" }} >
						{renderOptions()}
					</Col>
				</Row>
			}
		</>
	)
}
export default CommonFiltersInput
