import React, { useState, useEffect } from "react"
import dayjs from "dayjs";
import Icon from "react-icons-kit";
import { minus } from "react-icons-kit/fa";
import { plus } from "react-icons-kit/fa";
import { search } from 'react-icons-kit/feather/search'
import  styles from "../styles/searchHeader.module.scss"
import Styles2 from "../styles/searchmodal.module.scss";

import { x } from "react-icons-kit/feather";
import AppleMapkit from "../../../../widgets/common/appleMapKit";
import { getPlaces, isBrowser } from "../../../../widgets/common/utils";
import SearchDatePicker from "../../../../widgets/searchtemplates/template3/components/SearchDatePicker";
import LocationSearch from "../../../../widgets/searchtemplates/template3/components/LocationSearch";
import queryString from 'query-string';

const HeaderSearchLayoutTwo = ({
	setSelection,
	selection,
	selectedDay,
	guests,
	headerSearch,
	setGuests,
	setSelectedDay,
	setOverlay,
	setHeaderSearch,
	destination,
	setDestination,
	handleSearch,
	view,
}) => {
	const location = isBrowser ? window.location : null
	const searchParams = queryString.parse(location?.search)
	const [adult, setAdult] = useState( searchParams?.guest ?Number(searchParams?.guest):1 );
	const [child, setChild] = useState(0);
	const [showMapKit, setShowMapKit] = useState(false)
	const [onEnter, setonEnter] = useState(false)
	const [suggestions, setSuggestons] = useState([])


	useEffect(() => {
		if ((adult + child) > 0) {
			setGuests(Number(adult) + Number(child))
		}
	}, [adult, child])
	
	useEffect(() => {
		if (onEnter) {
			handleSubmit()
		}
	}, [onEnter])
	useEffect(() => {
		if (searchParams.address && suggestions.length === 0) {
			setShowMapKit(true)
			setTimeout(() => {
				handleSuggest(searchParams.address)
			},500)
		}
	}, [])
	const groupCount = {
		"Adults": { count: adult, setCount: setAdult, min: 0 },
		"Children": { count: child, setCount: setChild, min: 0 },
	}
	const renderGuests = (title, subTitle) => {
		return (
			<div key={title} className={Styles2.guests__container}>
				<div className={Styles2.guests__text}>
					<div>{title}</div>
					<div>{subTitle}</div>
				</div>
				<div className={Styles2.guests__button}>
					<button type="button" id="group-size-decrement"
						className={Styles2.guests__button__icon}
						disabled={groupCount[title].count === groupCount[title].min ? true : false}
						onClick={() => groupCount[title].count > groupCount[title].min && groupCount[title].setCount(groupCount[title].count - 1)}>
						<Icon icon={minus} />
					</button>
					<span className={Styles2.form__guest__count}>{groupCount[title].count}</span>
					<button type="button" id="group-size-increment"
						className={Styles2.guests__button__icon}
						onClick={() => groupCount[title].setCount(groupCount[title].count + 1)}>
						<Icon icon={plus} />
					</button>
				</div>
			</div>
		)
	}
	const handleSubmit = () => {
		setHeaderSearch(false)
		handleSearch()
		setonEnter(false)
	}
	const handleSuggest = (value) => {
		if(value != destination?.address){
            setDestination(prev=>({ ...prev, "address": value }));    
        }
		if (value.trim()) {
			if (isBrowser) {
				if (value.trim().length >= 1 && value != "Map Area") {
					try {
						const lat = 44.4499802;
						const long = -103.7856;
						const coordinate = new window.mapkit.Coordinate(lat, long); // latitude, longitude
						const span = new window.mapkit.CoordinateSpan(.016, .016); // latitude delta, longitude delta
						const usaRegion = new window.mapkit.CoordinateRegion(coordinate, span);
						let search = new window.mapkit.Search({ region: usaRegion });
						search.autocomplete(value.trim(), (error, response) => {
							if (error) {
								return;
							}
							let data = null
							const places = getPlaces(response.results, value.trim());
							// if ("california".includes(value.toLowerCase())) {
							// 	data = {
							// 		areaCode: undefined,
							// 		city: "California",
							// 		citystate: "California",
							// 		display: "California",
							// 		latitude: 38.57699966430664,
							// 		longitude: -121.49490356445312,
							// 		postCode: undefined,
							// 		state: "California",
							// 	}
							// }
							// if ("port austin".includes(value.toLowerCase())) {
							// 	data = {
							// 		areaCode: undefined,
							// 		city: "Port Austin Township",
							// 		citystate: "Port Austin Township",
							// 		display: "Port Austin Township",
							// 		latitude: 44.0959644,
							// 		longitude: -83.0886771,
							// 		postCode: undefined,
							// 		state: "Port Austin Township",
							// 	}
							// }
							if (places.length === 0) {
							} else {
								if (data && value.length > 3) {
									places.splice(0, 0, data)
								}
								setSuggestons(places)
							}
						});
					} catch (exception) {
						setSuggestons([{
							citystate: "Record not found. Something went wrong"
						}])
						console.warn('Failed to get autocomplete', exception)
					}
				} else {
					setSuggestons([])
				}
			}
		} else {
			const result = suggestions.filter((data) => {
				let filteredValues = data.citystate.toLowerCase();
				return filteredValues.indexOf(
					value.toLowerCase()) !== -1
			})
			setSuggestons(result);
		}
	}
	const next = () => {
		setSelection("group")
	}
	return (
		<div
			style={{
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
			}}
			className={headerSearch
				? styles.header_search_layout_two_active
				: styles.header_search_layout_two_inactive
			}
		>
			<div className={styles.SubCont}>
				<div
					onClick={() => {
						setSelection("location");
						setShowMapKit(true)
					}}
					className={selection === "location" ? styles.LItem : styles.Litem1}
				>
					<label htmlFor="location" className={styles.Lheading}>
						Location
					</label>
					<span className={styles.flexInput}>
						<input
							type="text"
							// ref={inputElement}
							onKeyUp={(e) => {
								if (e.key === 'Enter' && destination?.address?.length >= 1) {
									e.target.blur()
									setDestination({ ...destination, "address": suggestions[0]?.citystate, "latitude": suggestions[0]?.latitude, "longitude": suggestions[0]?.longitude, "page": 1 })
									setonEnter(true)
								}
							}}
							name="destination"
							id="destination"
							autoComplete="off"
							value={destination?.address}
							onChange={(e) => handleSuggest(e.target.value)}
							placeholder="Where would you like to go"
							className={styles.TextInput}
						/>
						{destination?.address?.length > 1 && <Icon icon={x} size={13} onClick={() => handleSuggest("")} />}
					</span>
				</div>
				{view === "location" &&destination && destination?.address?.length > 0&&suggestions?.length>0&&
					<div className={ `${styles.dateContainer}  ${styles.lCont}`}>
						<LocationSearch destination={destination} suggestions={suggestions}
							setDestination={setDestination} setSelection={setSelection} />
					</div>
				}
			</div>

			<div className={styles.LDivider}></div>

			<div className={styles.SubCont}>
				<div
					onClick={() => {
						setSelection("date");
					}}
					className={selection === "date" ? styles.LItem : styles.Litem1}
				>
					<p className={styles.Lheading}>Date</p>
					<p className={selectedDay?styles.content:styles.contentDis}>
						{selectedDay ? dayjs(selectedDay, "YYYY-MM-DD").format("MMMM DD, YYYY") : "Add Dates"}
					</p>
				</div>
				{(view === "date") && (
					<div className={styles.dateContainer}>
						<SearchDatePicker
							selectedDay={selectedDay}
							setSelectedDay={setSelectedDay}
							next={next}
						/>
					</div>
				)}
			</div>

			<div className={styles.LDivider}></div>
			<div className={styles.SubCont}>
				<div onClick={() => { setSelection("group"); }}
					className={selection === "group" ? `${styles.LItem} ${styles.guestOn}` : `${styles.Litem1} ${styles.guestOn}`}
				>
					<div >
						<p className={styles.Lheading}>Group Size</p>
						<p className={styles.content}>
							{guests && Number(guests) > 1 ? `${guests} guests` : Number(guests) === 1 ? `${guests} guest` : "Add Guests"}
						</p>
					</div>
				</div>
				{(view === "group") && (
					<div className={styles.dateContainer} style={{ width: "100%" }}>
						{renderGuests("Adults", "Ages 13 or above")}
						{renderGuests("Children", "Ages 2-12")}
					</div>
				)}
			</div>
			<div className={styles.LDivider}></div>
			<button className={`${styles.SearchBtn} button`}
						onClick={() => handleSubmit()}>
						{"Search"}
						<Icon icon={search} style={{ marginLeft: "5px" }} />
					</button>
			{showMapKit && <AppleMapkit />}

		</div>
	)
}
export default HeaderSearchLayoutTwo
